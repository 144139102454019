import React, { useRef } from "react"
import { Grid } from "./Components/CSSGrid/Grid"
import { GridArea } from "./Components/CSSGrid/GridArea"
import { useRecoilState, useRecoilValue } from "recoil"
import { layoutGroupsAtom, selectedLayoutAtom, selectedLayoutGroupAtom } from "./Atoms/Layout"
import { AreaConfig, Layout } from "./Types/Layout"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useOnMount } from "../../../../Hooks/useOnMount"
import { AnnotationGroupLoader } from "./Components/Modals/AnnotationGroupLoader/AnnotationGroupLoader"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors"
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont"
import { getDataQuerySocket } from "../../../../Providers/Socket"
import { useUpdateLiveRecordingEndDate } from "./Hooks/useLiveRecordingTimes"
import { currentPatientFileInfoAtom } from "./Atoms/PatientFile"
import { LIVE_REVIEW_ALLOWED } from "../../../../Constants/UploadLink"
import { useLayoutService } from "../../../../Hooks/useLayoutService"
import { MobergColumn, MobergRow } from "../../../../Moberg"
import { useAsyncTask } from "../../../../Hooks/useAsyncTask"

export const Visualizations = () => {
	const { createModal } = useModalProvider()
	const { addLayoutToLayoutGroup, createLayoutGroup } = useLayoutService()
	const [selectedLayoutGroup, setSelectedLayoutGroup] = useRecoilState(selectedLayoutGroupAtom)
	const layoutGroups = useRecoilValue(layoutGroupsAtom)
	const [selectedLayout, setSelectedLayout] = useRecoilState<Layout | null>(selectedLayoutAtom)
	const { fileStartDate, dataObjectId } = useRecoilValue(currentPatientFileInfoAtom)
	const currentDate = useRef(new Date(fileStartDate.getTime() + 60 * 1000))
	const addDisplayTask = useAsyncTask(() => new Promise((resolve, reject) => {
		if (selectedLayoutGroup) {
			addLayoutToLayoutGroup(selectedLayoutGroup)
				.then(resolve)
				.catch(reject)
		} else{
			resolve(null)
		}
	}))

	const addDisplayGroupTask = useAsyncTask(() => createLayoutGroup("Personal"))

	useOnMount(() => {
		if (selectedLayout) {
			createModal(<AnnotationGroupLoader style={{ height: "80vh" }} />)
		}

		let intervalId: NodeJS.Timeout

		if (LIVE_REVIEW_ALLOWED) {
			const socket = getDataQuerySocket()

			intervalId = setInterval(() => {
				currentDate.current = new Date(currentDate.current.getTime() + 1000);
				socket.emit("new_end_time", currentDate.current.toISOString(), dataObjectId)
			}, 1000)
		}

		return () => clearInterval(intervalId)
	})

	useUpdateLiveRecordingEndDate()

	if (!selectedLayoutGroup && layoutGroups.length > 0) {
		setSelectedLayoutGroup(layoutGroups[0])
	}

	if (!selectedLayout && selectedLayoutGroup?.layouts && selectedLayoutGroup.layouts.length > 0) {
		setSelectedLayout(selectedLayoutGroup?.layouts[0])
	}

	const noDisplayText = !selectedLayoutGroup ? "No display group selected" : "No display selected"

	if (!selectedLayout) {
		return (
			<MobergColumn verticalAlign="center">
				<MobergRow horizontalAlign="center">
					<MobergColumn gap="16px" horizontalAlign="center">
						<h3 style={{ margin: 0 }}>{noDisplayText}</h3>

						{layoutGroups.length === 0 && (
							<MobergButton
								onClick={addDisplayGroupTask.run}
								disabled={addDisplayGroupTask.isWaiting}
								theme={MobergTheme.BLUE} 
								variant={MobergButtonVariant.FILLED} 
								fontSize={MobergFontSize.LARGE} 
								shape={MobergButtonShape.WIDE}
							>
								Add display group
							</MobergButton>
						)}

						{selectedLayoutGroup?.layouts.length === 0 && (
							<MobergButton 
								onClick={addDisplayTask.run}
								disabled={addDisplayTask.isWaiting}
								theme={MobergTheme.BLUE} 
								variant={MobergButtonVariant.FILLED} 
								fontSize={MobergFontSize.LARGE} 
								shape={MobergButtonShape.WIDE}>
								Add display
							</MobergButton> 
						)}
					</MobergColumn>
				</MobergRow>
			</MobergColumn>
		)
	}

	const { cssGridTemplate, areas } = selectedLayout

	return (
		<Grid template={cssGridTemplate} gap={"1rem"} padding={"1rem"}>
			{areas.map((config: AreaConfig) => (
				<GridArea key={`${selectedLayout.id}-${config.area}`} {...config} shadow={true} />
			))}
		</Grid>
	)
}
