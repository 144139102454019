import { atomFamily } from "recoil"
import { scaleTime } from "d3"
import { EEGMontageConfig, EEGMontageConfigJSON } from "../Types/EEGMontageConfig"
import { EEG_LOW_FILTERS, EEG_HIGH_FILTERS, EEG_NOTCH_FILTERS, EEG_SENSITIVITY } from "../Types/EEG"
import { LayoutWindowId } from "../Types/LayoutWindowId"

export const DEFAULT_EEG_JSON: EEGMontageConfigJSON = {
	viewDuration: 30 * 1000,
	playbackSpeed: 1,
	montage: "Longitudinal-bipolar",
	LFF: EEG_LOW_FILTERS["1Hz"],
	HFF: EEG_HIGH_FILTERS["30Hz"],
	notch: EEG_NOTCH_FILTERS["60Hz"],
	sensitivityMicroVolts: EEG_SENSITIVITY["10 uV"],
	isLinked: false,
}

export const eegMontageConfigAtom = atomFamily<EEGMontageConfig, LayoutWindowId>({
	key: "eegMontageConfig",
	default: {
		id: "default",
		dataObjectId: "",
		viewScale: scaleTime(),
		fileScale: scaleTime(),
		patientId: "?",
		annotations: [],
		inProgressAnnotation: {
			id: "?",
			startDate: null,
			endDate: null,
			color: "red",
			opacity: 0.2,
			text: "",
			modalities: [],
			keyPressed: null,
		},
		playbackSpeed: 1,
		dimensions: { height: 0, width: 0 },
		montage: "Longitudinal-bipolar",
		LFF: EEG_LOW_FILTERS["1Hz"],
		HFF: EEG_HIGH_FILTERS["30Hz"],
		notch: EEG_NOTCH_FILTERS["60Hz"],
		sensitivityMicroVolts: EEG_SENSITIVITY["10 uV"],
		isLinked: false,
		timelineController: null,
		timeZone: "America/New_York",
		liveModeEnabled: false,
	},
})
