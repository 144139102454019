import { PageType } from "../Types/PageType";
import { Page } from "./Page";
import { TimeSeriesData } from "./TimeSeriesData";

export class EEGPage extends Page<Map<string, TimeSeriesData>> {

    getType(): PageType {
        return PageType.EEG
    }

    getChannelData(eegModality: string, channel: string): TimeSeriesData | undefined {
        return this.data.get(eegModality)?.get(channel)
    }

}