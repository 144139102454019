import { D3ClipPathConfig } from "../../../D3/D3ClipPath";
import { D3HorizontalLinesConfig } from "../../../D3/D3HorizontalLines";
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline";
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder";
import { D3Histogram } from "./D3Histogram";
import { D3HistogramBarsConfig } from "./D3HistogramBars";
import { HistogramXAxisConfig } from "./D3HistogramXAxis";
import { HistogramYAxisConfig } from "./D3HistogramYAxis";
import { D3ModalityGraphLegendConfig } from "../../TimeSeriesGraphGroup/D3/D3ModalityGraphLegend";

export class D3HistogramConfigurationBuilder extends ConfigurationBuilder<D3Histogram> {

	getYAxisConfig = (): HistogramYAxisConfig => {
        return {
			onDoubleClick: () => this.visualization.autoScale(),
			onDrag: () => this.visualization.onYAxisDrag(),
            scale: this.visualization.yScale
        }
    }

    getXAxisConfig = (): HistogramXAxisConfig => {
        return {
            scale: this.visualization.xScale,
            boundingBox: this.visualization.boundingBox
        }
    }

    getTimelineConfig = (): D3TimelineConfig => {
		const timelineUpdateTimes = (start: Date | number, end: Date | number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			width: this.visualization.boundingBox.width,
			annotations: this.visualization.config.annotations,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
		}
	}

	getBarsConfig = (): D3HistogramBarsConfig => {
		return {
			modalityConfigs: this.visualization.config.modalityConfigs,
			bandScale: this.visualization.xScale,
			viewScale: this.visualization.config.viewScale,
			yScale: this.visualization.yScale,
			binMinimum: this.visualization.config.binMinimum,
			binMaximum: this.visualization.config.binMaximum,
			binSize: this.visualization.config.binSize,
			clipPathId: this.getClipPathId()
		}
	}

	getHorizontalLinesConfig = (): D3HorizontalLinesConfig => {
		return {
			yScale: this.visualization.yScale,
			width: this.visualization.boundingBox.width
		}
	}

	getClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getClipPathId(),
			boundingBox: this.visualization.boundingBox
		}
	}

	getLegendConfig = (): D3ModalityGraphLegendConfig => {
		return {
			traces: this.visualization.config.modalityConfigs.map(config => ({ name: config.name, color: config.color})),
			width: this.visualization.boundingBox.width
		}
	}

	private getClipPathId = () => `d3-clip-path-${this.visualization.config.id}`

}