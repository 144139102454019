import { SetterOrUpdater } from "recoil"
import { MobergDropdown, MobergDropdownSize } from "../../../Components/MobergDropdown/MobergDropdown"
import { EEGMontageConfig } from "../../../Pages/Data/Visualize/DataReview/Types/EEGMontageConfig"
import React from "react"
import { EEGMontages, EEG_HIGH_FILTERS, EEG_LOW_FILTERS, EEG_MONTAGES, EEG_NOTCH_FILTERS } from "../../../Pages/Data/Visualize/DataReview/Types/EEG"
import { MobergButton, MobergButtonShape } from "../../../Components/MobergButton/MobergButton"
import { useMobergMenu } from "../../../Hooks/useMobergMenu"
import { MdMoreHoriz } from "react-icons/md"
import { MobergIconSize } from "../../../Components/MobergIcon/MobergIcon"

type EEGCollapseMenuProps = {
    eegConfig: EEGMontageConfig
    setEEGConfig: SetterOrUpdater<EEGMontageConfig>
}

export const EEGCollapseMenu = ({ eegConfig, setEEGConfig }: EEGCollapseMenuProps) => {
    const { open, MobergMenu } = useMobergMenu({ placement: "bottom"})

    return (<>
        <MobergButton onClick={open} shape={MobergButtonShape.SQUARE}>
            <MdMoreHoriz size={MobergIconSize.SMALL} />
        </MobergButton>

        <MobergMenu>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <MobergDropdown 
                    label={"Montage"}
                    selectedValue={eegConfig.montage} 
                    size={MobergDropdownSize.SMALL}
                    onChange={value => setEEGConfig(previous => ({ ...previous, montage: value as keyof EEGMontages }))}
                    options={Object.keys(EEG_MONTAGES)
                        .filter(montage => ["Longitudinal-bipolar", "Bipolar-Transverse"].includes(montage))
                        .map(montage => ({label: montage, value: montage }))} />

                <MobergDropdown 
                    label={"LFF"}
                    selectedValue={eegConfig.LFF} 
                    size={MobergDropdownSize.SMALL}
                    onChange={value => setEEGConfig(previous => ({ ...previous, LFF: parseFloat(value as string) }))}
                    options={Object.entries(EEG_LOW_FILTERS).map(([label, value]) => ({ label, value }))} />
            
                <MobergDropdown 
                    label={"HFF"}
                    selectedValue={eegConfig.HFF} 
                    size={MobergDropdownSize.SMALL}
                    onChange={value => setEEGConfig(previous => ({ ...previous, HFF: parseFloat(value as string) }))}
                    options={Object.entries(EEG_HIGH_FILTERS).map(([label, value]) => ({ label, value }))} />
            
                <MobergDropdown 
                    label={"Notch"}
                    selectedValue={eegConfig.notch} 
                    size={MobergDropdownSize.SMALL}
                    onChange={value => setEEGConfig(previous => ({ ...previous, notch: parseFloat(value as string) }))}
                    options={Object.entries(EEG_NOTCH_FILTERS).map(([label, value]) => ({ label, value }))} />
            </div>
        </MobergMenu>
    </>)
} 
