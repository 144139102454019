import React, { useContext, useRef, useState } from "react"
import { CPPOptPlotJSON } from "../../../../Types/CPPOptPlot"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilState, useRecoilValue } from "recoil"
import { cppOptPlotConfigsAtom } from "../../../../Atoms/CPPOpt"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { D3CPPOptPlot } from "../D3/D3CPPOptPlot"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { CPPOptToolbar } from "./CPPOptToolbar"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"

type CPPOptPlotProps = {
    windowId: string,
    json: CPPOptPlotJSON
}

export const CPPOptPlot = (props: CPPOptPlotProps) => {

    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
                <CPPOptToolbar windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <CPPOptPlotVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}

const CPPOptPlotVisualization = (props: CPPOptPlotProps) => {
    const { createModal } = useModalProvider()
    const container = useRef<HTMLDivElement>(null)

    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
    const [config, setRootConfig] = useRecoilState(cppOptPlotConfigsAtom(visualizationId))
    const { fileStartDate, fileEndDate, dataObjectId, patientId } = useRecoilValue(currentPatientFileInfoAtom)
    const [d3Controller, setD3Controller] = useState<D3CPPOptPlot>()
    const dimensions = useContext(DimensionsContext)
    const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)

    const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
    const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

    const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, fileStartDate, fileEndDate)

    useOnMount(() => {
        if (!container.current) {
            return
        }
        
        const reactCallbacks = { createModal, setRootConfig, setLinkedWindowsTimelineController }

        const parsedJSON = {
            ...config,
            ...props.json,
            id: props.windowId,
            viewScale,
            fileScale,
            dataObjectId,
            patientId,
            dimensions: { height: 0, width: 0 }
        }

        const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.CPPOPT_PLOT, new TimeSeriesPageManager())
        const window = new D3CPPOptPlot(container.current, parsedJSON, pageManager, reactCallbacks)

        setD3Controller(window)
        setRootConfig(parsedJSON)

        return () => {
            window.unmount()
        }
    })

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [hotkeyActions.PREVIOUS_PAGE, hotkeyActions.NEXT_PAGE, hotkeyActions.PLAY_PAUSE]
    })

    useD3CheckReloadData({
        d3Controller,
        fileScale,
        clearDataIfChanges: {
            viewDuration
        },
    })

    if (d3Controller && dimensions.height && dimensions.width && Object.keys(config).length > 0) {
		d3Controller.updateConfig({ ...config, dimensions, timelineController, viewScale })
	}

    return (
        <div ref={container} style={{ height: "100%", position: "relative" }} />
    )
}