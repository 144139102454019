import React, { useState } from "react"
import { useModalProvider } from "../../Providers/ModalProvider"
import { MdOutlineError } from "react-icons/md"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../MobergButton/MobergButton"
import { MobergTheme } from "../MobergThemes/MobergColors"
import { MobergFontFamily, MobergFontSize } from "../MobergFont/MobergFont"

type ConfirmationButton = {
	text: string
	theme: MobergTheme
	onClick?: () => void
}

type ConfirmationModalProps = {
	title: string
	description: string
	confirmButton: ConfirmationButton
	alternativeButton?: ConfirmationButton
	afterClose?: Function
	cancelButton?: ConfirmationButton
}

function isPromise(obj: any): obj is Promise<unknown> {
	return !!obj && typeof obj.then === "function"
}

function ConfirmationModal({ title, description, confirmButton, alternativeButton, afterClose, cancelButton }: ConfirmationModalProps) {
	const { close } = useModalProvider()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	const handleAfterClose = (isCancel ?: any) => {
		if (afterClose) {
			afterClose(isCancel)
		}
	}

	const executeThenClose = (fn?: Function) => {
		if (!fn) {
			fn = () => null
		}

		const result = fn()

		// Wait for async functions to finish
		if (isPromise(result)) {
			setLoading(true)
			setError(null)

			result
				.then(close)
				.catch(error => {
					setLoading(false)
					setError(error)
				})
				.then(handleAfterClose)

			return
		}

		close().then(handleAfterClose)
	}

	return (
		<div style={{ textAlign: "center", padding: "20px", width: "425px" }}>
			<MdOutlineError size={81} style={{ color: "#AEB7C6", marginBottom: "5px" }} />

			<h1 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "24px", lineHeight: "110%", color: "#000000" }}> {title} </h1>

			{error && (
				<p style={{ textAlign: "center", margin: 0, padding: "5px 15px", color: "red" }}> Error: {error} </p>
			)}

			<p style={{ textAlign: "center", padding: "10px 15px" }}> {loading ? "Please wait..." : description} </p>

			<div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
				<MobergButton 
					disabled={loading} 
					shape={MobergButtonShape.FULL_WIDTH}
					fontSize={MobergFontSize.LARGE} 
					theme={confirmButton.theme}
					variant={MobergButtonVariant.FILLED} 
					onClick={() => executeThenClose(confirmButton.onClick)}
					style={{height: "55px", fontFamily: MobergFontFamily.HEADER}}>
					{confirmButton.text}
				</MobergButton>

				{alternativeButton && (
					<MobergButton 
						disabled={loading} 
						shape={MobergButtonShape.FULL_WIDTH} 
						fontSize={MobergFontSize.LARGE}
						theme={alternativeButton.theme}
						variant={MobergButtonVariant.FILLED} 
						onClick={() => executeThenClose(alternativeButton.onClick)}
						style={{height: "55px", fontFamily: MobergFontFamily.HEADER}}>
					{alternativeButton.text}
				</MobergButton>
				)}

				<MobergButton 
					disabled={loading} 
					shape={MobergButtonShape.FULL_WIDTH}
					fontSize={MobergFontSize.LARGE}
					theme={MobergTheme.BLUE}
					variant={MobergButtonVariant.OUTLINE} 
					onClick={() => close().then(() => handleAfterClose(true))}
					style={{height: "55px", fontFamily: MobergFontFamily.HEADER}}>
					{cancelButton ? cancelButton?.text : 'Cancel'}
				</MobergButton>

			</div>
		</div>
	)
}

export default ConfirmationModal
