import { UploadFilesModal, HiddenButton } from "../../../../../Constants/StyledComponents"
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader"
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider"
import { UploadStepHeader } from "../../../../../Constants/StyledComponents"
import { ModalFooter } from "../../../../../Constants/StyledComponents"
import { ENCRYPT } from "../../../../../Constants/UploadLink"
import MUITable from "../../../../../Components/MUITable/MUITable"
import { LINKS } from "../../../../../Constants/BackendLinks"
import { useAuthProvider } from "../../../../../Providers/AuthProvider"
import { useEffect, useState } from "react"
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";

const siteColumns = [
	{ field: "identifier", flex: 0.4, headerName: "Site ID", minWidth: 100, visible: true },
	{ field: "name", flex: 1, headerName: "Site name", visible: true },
	{ field: "description", flex: 1, headerName: "Description", visible: true },
	{ field: "location", flex: 1, headerName: "Location", visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", minWidth: 100, visible: true },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", minWidth: 120, visible: true },
]

const project_columns = [
	{ field: "identifier", flex: 0.4, headerName: "Project ID", minWidth: 90, visible: true },
	{ field: "name", flex: 0.4, headerName: "Name", minWidth: 70, visible: true },
	{ field: "bucket_name", flex: 0.4, headerName: "Repository", minWidth: 115, visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", valueGetter: params => params.row.user_count, minWidth: 110, visible: true },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", valueGetter: params => params.row.patient_count, minWidth: 110, visible: true },
]

const UploadSiteDetail = ({ setToNextWindow, setToPrevWindow }) => {
	const uploadProcessProvider = useUploadProcessProvider()
	const authProvider = useAuthProvider()
	const handleBack = () => {
		setToPrevWindow(true)
	}

	const handleNext = () => {
		setToNextWindow(true)
	}

	const queryProps = {
		queryKey: "project_user_sites",
		endpoint: LINKS.DATA.UPLOAD.GET_PROJECT_USER_SITES,
		body: { user_id: authProvider.currentUser.id },
	}
	const [selectedRows, setSelectedRows] = useState(uploadProcessProvider?.selectedSitePrimaryKey === '' ? [] : [uploadProcessProvider?.selectedSitePrimaryKey])
	const preselectedProps = { selectedRows, setSelectedRows }

	const queryProjectProps = {
		queryKey: "user_projects",
		endpoint: LINKS.DATA.UPLOAD.GET_USER_PROJECTS,
		body: { user_id: authProvider.currentUser.id },
	}
	const [selectedProjectRows, setSelectedProjectRows] = useState(uploadProcessProvider?.selectedProjectPrimaryKey === '' ? [] : [uploadProcessProvider?.selectedProjectPrimaryKey])
	const preselectedProjectProps = { selectedRows: selectedProjectRows, setSelectedRows: setSelectedProjectRows }

	function handleCheckboxSelectionChange(site, isClicked) {
		uploadProcessProvider.resetExistingTableData()
		if (site["identifier"] !== uploadProcessProvider.selectedSiteID) {
			if (!uploadProcessProvider.checkFoldersIsEmpty()) {
				if (window.confirm("Modifying existing site ID will remove your previously selected upload files. Do you want to continue?")) {
					uploadProcessProvider.resetTableData()
					uploadProcessProvider.resetUploadFolders()
					uploadProcessProvider.resetDirectoryNames()
				} else return
			}
		}
		
		uploadProcessProvider.setSelectedSiteID(isClicked ? site["identifier"] : "")
		uploadProcessProvider.setSelectedSitePrimaryKey(isClicked ? site["id"] : "")
		uploadProcessProvider.setSelectedSiteName(isClicked ? site["name"] : "")
	}

	function handleProjectCheckboxSelectionChange(project, isClicked) {
		uploadProcessProvider.resetExistingTableData()
		if (project["identifier"] !== uploadProcessProvider.selectedProjectID) {
			if (!uploadProcessProvider.checkFoldersIsEmpty()) {
				if (window.confirm("Modifying existing project ID will remove your previously selected upload files. Do you want to continue?")) {
					uploadProcessProvider.resetTableData()
					uploadProcessProvider.resetUploadFolders()
					uploadProcessProvider.resetDirectoryNames()
				} else return
			}
		}
		uploadProcessProvider.setSelectedProjectID(isClicked ? project["identifier"] : "")
		uploadProcessProvider.setSelectedProjectPrimaryKey(isClicked ? project["id"] : "")
		uploadProcessProvider.setSelectedProjectName(isClicked ? project["name"] : "")
	}

	return (
		<>
			<UploadFilesModal id="uploadFilesButtonModal">
				<ModalHeader headerText="Select a Site and Project" resetCache={uploadProcessProvider.resetCache} />
				<div style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "55px", height: "600px", flex: 1 }}>
					<div style={{ marginBottom: "24px" }}>
						<UploadStepHeader>1. Select an existing site and project</UploadStepHeader>
						<div style={{ maxHeight: '585px', overflow: 'auto', display: "flex", flexDirection: "column", gap: "16px" }}>
							<MUITable columns={siteColumns} disableMultiSelect={handleCheckboxSelectionChange} disableSelectAll={true} preselectedProps={preselectedProps} {...queryProps} isAutoselect={true} style={{ padding: '10px' }} />
							<MUITable columns={project_columns} disableMultiSelect={handleProjectCheckboxSelectionChange} disableSelectAll={true} preselectedProps={preselectedProjectProps} {...queryProjectProps} isAutoselect={true} style={{ padding: '10px' }} />
						</div>
					</div>
				</div>

				<hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
				<ModalFooter>
					{!ENCRYPT ? (
						<MobergButton
							theme={MobergTheme.BLUE}
							variant={MobergButtonVariant.OUTLINE}
							shape={MobergButtonShape.WIDE}
							onClick={handleBack}
						>
							Back
						</MobergButton>
					) : (
						<HiddenButton />
					)}

					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.FILLED}
						shape={MobergButtonShape.WIDE}
						onClick={handleNext}
						disabled={uploadProcessProvider.selectedSiteID === '' || uploadProcessProvider.selectedProjectID === ''}
					>
						Next
					</MobergButton>
				</ModalFooter>
			</UploadFilesModal >
		</>
	)
}

export default UploadSiteDetail