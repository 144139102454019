import { VISUALIZATIONS } from "../../Constants/Formatting"
import { scaleLinear } from 'd3-scale'
import { axisLeft } from "d3-axis";

export default class LineGraphYAxis {
    constructor(svg, y_height, min_y, max_y, graph_height, modality) {
        // this.y_height = y_height
        // this.min_y = min_y
        // this.max_y = max_y
        // this.graph_height = graph_height
        // this.modality = modality
        svg.selectAll(".yaxis." + modality).remove()
        // let scale = scaleLinear()
        //     .domain([max_y, min_y])
        //     .range([y_height, y_height + graph_height])

        svg.append("g")
            .attr("class", `yaxis ${modality}`)
            .style('color', "#5A6679")
            .attr("transform", `translate(${VISUALIZATIONS.GRAPH_PADDING.LEFT - 5}, ${VISUALIZATIONS.X_AXIS_HEIGHT + 20})`)
            .call(
                axisLeft()
                .ticks(5)
                .scale(
                    scaleLinear()
                    .domain([max_y, min_y])
                    .range([y_height, y_height + graph_height])
                )
            )
    }
}

