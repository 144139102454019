import { getDataQuerySocket } from "../../../../../Providers/Socket"
import { ModalityDataSource } from "../Types/ModalityDataSource"
import { PageType } from "../Types/PageType"
import { PersystSocketResponse } from "../Types/SocketResponse"
import { Page } from "./Page"

export class ImagePage extends Page<ArrayBuffer> {
	readonly height: number

	private _loading = false

	get loaded(): boolean {
		return this.data.get("image") !== undefined
	}

	get loading(): boolean {
		return this._loading
	}

	constructor(startTime: number, endTime: number, index: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffset: number, socketId: string) {
		super(startTime, endTime, index, width, modalityDataSources, patientId, timeZoneOffset, socketId)
		this.height = height
	}

	public getType(): PageType {
		return PageType.IMAGE
	}

	public load = () => {
		return new Promise<Page<ArrayBuffer>>((resolve, reject) => {
			if (this.loaded || this.loading || this.width === 0) {
				resolve(this)
				return
			}

			this._loading = true

			const socket = getDataQuerySocket(this.socketId)

			const listener = (socketResponse: PersystSocketResponse) => {
				if (socketResponse.page_id === this.id) {
					this.data.set("image", socketResponse.image)
					socket.off(this.socketEventName(), listener)
					this._loading = false
					resolve(this)
				}
			}

			socket.on(this.socketEventName(), listener)

			// Creating a batch for all of the modalities
			const batch = new Map<number, string[]>()

			this.modalityDataSources.forEach(dataSource => {
				const current = batch.get(dataSource.dataObjectId) ?? []
				batch.set(dataSource.dataObjectId, [...current, dataSource.modality])
			})

			this.requestData(batch)

			setTimeout(() => reject("socket timed out"), 60_000)
		})
	}
}
