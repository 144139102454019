import VisualizationManager, { VIZ_MODES } from './VisualizationManager';
import LineGraphYAxis from './LineGraphYAxis'
import * as d3 from 'd3'

import { LABEL_SETTINGS } from "./LabelSettings";
import { VISUALIZATIONS } from '../../Constants/Formatting';
import { windowXtoTimestamp, throttle } from './Utils/Repository';
import { Profiler } from "./Utils/Profiling"
import Annotations from './Variables/Annotations';

export const default_rect_style = {
    "color": "#ff0000",
    "opacity": 0.2
}


/** Time in seconds
 * @typedef {(number)} Seconds */

/** Time in milliseconds
 * @typedef {(number)} Millis */

/** Unix timestamp; a number representing time in milliseconds since 1970/1/1
 * @typedef {(Millis)} Timestamp */

/**
 * @name LineGraph
 * @class
 * @category Visualization
 * @subcategory d3
 * 
 * 
 * @description aaaa
 */
export default class LineGraph {
    

    /**
     * @param {string} label 
     */
    constructor(label) {
        importGraphSettings.call(this, label)
        this.createAll()
        this.registerHandlers()
        this.render(true, true, true)

        this.configDiv = d3.select("#VisualizationAreaGraphOptions")
            .append("div")
            .style("height", `${this.svg_height()}px`)

        // this.configDiv
        //     .append("button")
        //     .html("reset")
        
        this.configDiv
            .append("button")
            .on("click", () => {
                VisualizationManager.setSelectedModalities(prev => prev.includes('ABP_Dias') ? [...prev] : [...prev, 'ABP_Dias'])
                VisualizationManager.test = !VisualizationManager.test
            })
            .html("abp_dias")
        this.configDiv
            .append("button")
            .on("click", () => {
                VisualizationManager.test2 = !VisualizationManager.test2
            })
            .html("fill")
        this.configDiv
            .append("button")
            .on("click", () => {
                VisualizationManager.test3 = !VisualizationManager.test3
            })
            .html("test3")

            

            // heatmap code:
            // useEffect(() => {

            //     d3.selectAll(`.yaxis.${id}`).remove()
            //     d3.selectAll(`.xaxis.${id}`).remove()
            //     d3.selectAll(`.title.${id}`).remove()
            //     d3.selectAll(`.subtitle.${id}`).remove()
            //     d3.selectAll(`.legend.${id}`).remove()

            //     const yaxis_svg = d3.select("#yaxis").append("svg")
            //         .attr("id", "yaxis " + id)
            //         .attr("class", "yaxis " + id)
            //         .attr("width", VISUALIZATIONS.GRAPH_PADDING.LEFT)
            //         .attr("display", "block")
            //         .style("overflow", "visible")
            
            //     const xaxis_svg = d3.select("#xaxis").append("svg")
            //         .attr("id", "xaxis " + id)
            //         .attr("class", "xaxis " + id)
            //         .attr("width", "100%")
            //         .attr("display", "block")
            //         .style("overflow", "visible")
                
            //     const yaxis = new Axis(yaxis_svg, height, width, 0, 100, height, id, "PbtO2 (mm Hg)", 'yaxis')
            //     const xaxis = new Axis(xaxis_svg, height, width, 0, 100, width, id, "CPP (mm Hg)", 'xaxis')

            //     const legend_svg = d3.select("#legend").append("svg")
            //         .attr("id", "legend " + id)
            //         .attr("class", "legend " + id)
            //         .attr("width", "100%")
            //         .attr("display", "block")
            //         .style("overflow", "visible")

            //     const legend = new Legend(legend_svg, height, 50, 0, 1, id)

            //     if (title) {
            //         const title_svg = d3.select("#title").append("svg")
            //             .attr("id", "title " + id)
            //             .attr("class", "title " + id)
            //             .attr("height", '30px')
            //             .attr("width", "100%")
            //             .attr("display", "block")
            //             .style("overflow", "visible")

            //         title_svg.append("text")
            //             .attr("class", `${id} title`)
            //             .style("font-size", "24px")
            //             .attr("text-anchor", "middle")
            //             .style('font-family', 'sans-serif')
            //             .attr("font-style", "normal")
            //             .attr("x", VISUALIZATIONS.GRAPH_PADDING.LEFT + (width / 2))
            //             .attr("y", 10)
            //             .text(title);
            //     }

            //     if (subtitle) {
            //         const title_svg = d3.select("#subtitle").append("svg")
            //             .attr("id", "subtitle " + id)
            //             .attr("class", "subtitle " + id)
            //             .attr("height", '20px')
            //             .attr("width", "100%")
            //             .attr("display", "block")
            //             .style("overflow", "visible")

            //         title_svg.append("text")
            //             .attr("class", `${id} subtitle`)
            //             .style("font-size", "18px")
            //             .attr("text-anchor", "middle")
            //             .style('font-family', 'sans-serif')
            //             .attr("font-style", "normal")
            //             .attr("x", VISUALIZATIONS.GRAPH_PADDING.LEFT + (width / 2))
            //             .attr("y", 10)
            //             .text(subtitle);
            //     }

            //     /* tooltip code start */
            //     var demoWrapper = document.querySelector('#container');
            //     var tooltip = document.querySelector('#tooltip');
            //     function updateTooltip(x, y, value) {
            //         // + 15 for distance to cursor
            //         var transl = 'translate(' + (x + 15) + 'px, ' + (y + 15 - height - 200) + 'px)';
            //         tooltip.style.webkitTransform = transl;
            //         tooltip.innerHTML = value;
            //     };

            //     demoWrapper.onmousemove = function(ev) {
            //         var x = ev.layerX;
            //         var y = ev.layerY;
            //         // getValueAt gives us the value for a point p(x/y)
            //         var value = heatmapInstance.getValueAt({
            //             x: x,
            //             y: y
            //         });
            //         tooltip.style.display = 'block';
            //         updateTooltip(x, y, value);
            //     };
                
            //     // hide tooltip on mouseout
            //     demoWrapper.onmouseout = function() {
            //         tooltip.style.display = 'none';
            //     };
            //     /* tooltip code end */

            //     // customized heatmap configuration
            //     var heatmapInstance = h337.create({
            //         // required container
            //         container: document.querySelector('#container'),
            //         // backgroundColor to cover transparent areas
            //         backgroundColor: 'rgba(0,0,255)',
            //         // custom gradient colors
            //         gradient: {
            //             '0': d3.interpolateSpectral(1.0),
            //             '0.1': d3.interpolateSpectral(0.9),
            //             '0.2': d3.interpolateSpectral(0.8),
            //             '0.3': d3.interpolateSpectral(0.7),
            //             '0.4': d3.interpolateSpectral(0.6),
            //             '0.5': d3.interpolateSpectral(0.5),
            //             '0.6': d3.interpolateSpectral(0.4),
            //             '0.7': d3.interpolateSpectral(0.3),
            //             '0.8': d3.interpolateSpectral(0.2),
            //             '0.9': d3.interpolateSpectral(0.1),
            //             '1': d3.interpolateSpectral(0),
            //         },
            //         // the maximum opacity (the value with the highest intensity will have it)
            //         maxOpacity: .9,
            //         // minimum opacity. any value > 0 will produce
            //         // no transparent gradient transition
            //         minOpacity: .3
            //     });

            //     heatmapInstance.setData(data);
            // })

            // return (<div style={{padding: 20}}>

            //     <div id="title" />
            //     <div id="subtitle" />
            //     <div style={{display: 'flex'}}>
            //         <div id="yaxis" />
            //         <div id="container" style={{height: height, width: width}} />
            //         <div id="legend" />
            //     </div>
            //     <div id="xaxis" />
            //     <div id="tooltip" />
            
            // </div>)
    }

    createAll() {
        createSVGs.call(this, this.label_raw, this.graph_height)
        createAxes.call(this)
        createCanvas.call(this)
        createLayers.call(this) 
        createSelectedRect.call(this)
        createTimestampTooltip.call(this)
        createLegendText.call(this)
        this.lineFunction = d3.line()
            .defined((d) => d !== null)
            .x(d => this.x(d.timestamp))
            .y(d => this.y(d.value))
            .curve(d3.curveLinear)
            .context(this.context)
    }

    registerHandlers() {
        this.svg_data
            .call(d3.drag().on("drag", LineGraph.handleDrag.bind(this)))
            .on("click", this.handleClick.bind(this))
            .on("mousemove", throttle(this.handleMouseMove.bind(this), 16.67))
            .on("mouseleave", this.handleMouseLeave.bind(this))
    }



/* RENDERING */
/* -------------------------------------------------------------------------*/
    /**
     * Renders the LineGraph's line graph itself
     * Use when LineGraph data has changed or the graphing time has changed
     */
    static renderPathProfiler = new Profiler(1000, 'renderPath', 100)
    renderPath() {
        LineGraph.renderPathProfiler.start()

        this.x.domain([VisualizationManager.graph_start_time, VisualizationManager.graph_end_time])
        this.context.clearRect(0, 0, this.svg_width(), this.svg_height())

        this.renderPage(VisualizationManager.currentPage)
        this.renderPage(VisualizationManager.nextPage)
        
        LineGraph.renderPathProfiler.end()
    }

    renderPage(page) {
        if (!page?.loaded) return
        if (!(this.label_raw in page.data)) return
        
        if (VisualizationManager.test3) {
            //todo: heatmap
        }

        this.context.strokeStyle = this.color
        this.context.beginPath();
        this.context.lineWidth = 1
        if (VisualizationManager.test) {
            this.lineFunction(page.data['ABP_Dias'])
            this.context.stroke()
        }
        
        const data = [...page.data[this.label_raw]]
        if (VisualizationManager.test2) {
            this.context.fillStyle = 'red'
            const pieces = []
            let prev = 0
            for (let i = 0; i < data.length; i++) {
                if (data[i] === null) {
                    pieces.push(data.slice(prev, i-1))
                    prev = i+1
                }
            }
            
            for (const piece of pieces) {
                piece[0] = {...piece[0], value:0}
                piece[piece.length-1] = {...piece[piece.length-1], value:0}
                this.lineFunction(piece)
                this.context.fill()
            }

            
        } else {
            this.lineFunction(data)
            this.context.stroke();
        }
          


    }


    /**
     * Renders the LineGraph's y-axis (the axis on the left of the graph)
     */
    renderYAxis() {
        this.y_axis = new LineGraphYAxis(this.svg_yaxis, VISUALIZATIONS.GRAPH_PADDING.TOP, this.min_y, this.max_y, this.graph_height, this.label_raw)
        this.y.domain([this.max_y, this.min_y])
    }


    /**
     * Catch-all LineGraph render function, with optional arguments
     * @param {boolean} path whether to draw/redraw the line graph itself
     * @param {boolean} y_axis whether to draw/redraw the y-axis
     * @param {boolean} timestamp whether to update the selected timestamp (green line)'s value
     */
    render(path = true, y_axis = true, timestamp = true) {
        if (path) this.renderPath()
        if (y_axis) this.renderYAxis()
        if (timestamp) this.updateSelectedTimestampValue()
    }


    /**
     * Wrapper that modifies functions to induce a LineGraph rerender
     * @param {function():void} func function to wrap
     * @returns {function():void} wrapped function
     */
    wrapRender(func) {
        return (...args) => {
            console.log(this)
            func.apply(this, args)
            this.render(true, true, true)
        }
    }
    /* ---------------------------------------------------------------------*/


/* GRAPH VIEW MODIFIERS */
/* -------------------------------------------------------------------------*/
    increaseMaxYValue = this.wrapRender(() => { this.max_y += this.y_delta })
    decreaseMaxYValue = this.wrapRender(() => { this.max_y -= this.y_delta })
    increaseMinYValue = this.wrapRender(() => { this.min_y += this.y_delta })
    decreaseMinYValue = this.wrapRender(() => { this.min_y -= this.y_delta })
    translateYAxisUp = this.wrapRender(() => { this.max_y += this.y_scaling; this.min_y += this.y_scaling })
    translateYAxisDown = this.wrapRender(() => { this.max_y -= this.y_scaling; this.min_y -= this.y_scaling })
    setYValueMax = this.wrapRender((value) => { this.max_y = value })
    setYValueMin = this.wrapRender((value) => { this.min_y = value })
    decreaseGraphsHeight = this.wrapRender(() => { this.height -= 20 })
    increaseGraphsHeight = this.wrapRender(() => { this.height += 20 })
    moveGraphsUp = this.wrapRender(() => { 
        let index = VisualizationManager.graphsOrder.indexOf(this.label_raw)
        if (index === 0) return
        VisualizationManager.graphsOrder = Array.prototype.concat(
            VisualizationManager.graphsOrder.slice(0, index - 1),
            VisualizationManager.graphsOrder[index],
            VisualizationManager.graphsOrder[index - 1],
            VisualizationManager.graphsOrder.slice(index + 1)
        )
        console.log(VisualizationManager.graphsOrder)
     })
    moveGraphsDown = this.wrapRender(() => { 
        let index = VisualizationManager.graphsOrder.indexOf(this.label_raw)
        if (index === VisualizationManager.graphsOrder.length - 1) return
        VisualizationManager.graphsOrder = Array.prototype.concat(
            VisualizationManager.graphsOrder.slice(0, index),
            VisualizationManager.graphsOrder[index + 1],
            VisualizationManager.graphsOrder[index],
            VisualizationManager.graphsOrder.slice(index + 2)
        )
        console.log(VisualizationManager.graphsOrder)
     })
    /* ---------------------------------------------------------------------*/



    updateSelectedTimestampValue() {
        const eps = Math.max ((1/720) * VisualizationManager.graph_time(), 10000)
        const page = VisualizationManager.pageAt(VisualizationManager.selected_timestamp)
        const found = page?.getNearestPoint(VisualizationManager.selected_timestamp, eps, this.label_raw)

        if (found === undefined) {
            this.value_text
                .style("visibility", "hidden")
        } else {
            const fill = found.value > this.y.domain()[1] && found.value < this.y.domain()[0] ? "green" : "red"
            this.value_text
                .text(Math.round(found.value))
                .style("visibility", "visible")
                .attr("fill", fill)
        }
    }


/* DIMENSIONS GETTERS/SETTERS */
/* -------------------------------------------------------------------------*/
    get height() { return this.graph_height }
    get total_height() { return this.graph_height + VISUALIZATIONS.GRAPH_PADDING.TOP + VISUALIZATIONS.GRAPH_PADDING.BOTTOM }
    set height(value) {
        this.graph_height = value
        this.svg_data.attr("height", this.total_height)
        this.fo.attr("height", this.total_height)
        this.canvas.attr("height", this.total_height)
        this.svg_legend.attr("height", this.total_height)
        this.svg_yaxis.attr("height", this.total_height)
        this.selectedRect.attr("height", this.total_height - 1)
        this.y.range([VISUALIZATIONS.GRAPH_PADDING.TOP, VISUALIZATIONS.GRAPH_PADDING.TOP + value])
    }
    svg_width = () => this.svg_data.node().getBoundingClientRect().width
    svg_height = () => this.svg_data.node().getBoundingClientRect().height
    /* ---------------------------------------------------------------------*/


/* INPUT */
/* -------------------------------------------------------------------------*/
    startBrush() {
        const brush_linegraph = d3.brushX()
            .filter(e => !e.button)
            .extent([
                [0, 0],
                [this.svg_width(), this.svg_height()]
            ])
            .on("start", e => {
                VisualizationManager.overlay.updateHoverLine(e)
            })
            .on("brush", e => {
                VisualizationManager.overlay.updateHoverLine(e)
                if (VisualizationManager.mode === 'configure_mode') {
                    if (e.ctrlKey) {
                        VisualizationManager.overlay.topBottomLinesVisible(true)
                        VisualizationManager.overlay.updateTopBottomLines(...VisualizationManager.overlay.graphBounds(this.label_raw))
                        VisualizationManager.overlay.clipHoverLine(...VisualizationManager.overlay.graphBounds(this.label_raw))
                    } else {
                        VisualizationManager.overlay.topBottomLinesVisible(false)
                        VisualizationManager.overlay.updateHoverLine(e)
                        VisualizationManager.overlay.clipHoverLine(-99999, 99999)
                    }
                }
            })
            .on("end", e => {
                VisualizationManager.overlay.updateHoverLine(e)
                if (!e.selection) {
                    return
                }
                const start_time = windowXtoTimestamp(Math.min(...e.selection))
                const end_time = windowXtoTimestamp(Math.max(...e.selection))

                if (VisualizationManager.mode === "configure_mode") {
                    let window_time = (end_time - start_time) / 1000

                    if (window_time >= 60) {
                        const window_time_changed = VisualizationManager.setGraphTimes(start_time, end_time)
                        if (window_time_changed) {
                            VisualizationManager.resetPages()
                        }
                        VisualizationManager.timeline.updateSliderPosition()
                    }
                }

                if (VisualizationManager.mode === 'annotate_mode') {
                    Annotations.createAnnotation({start_time: start_time, end_time: end_time, modalities: e.sourceEvent.ctrlKey ? [this.label_raw] : []})
                }
                d3.selectAll(".linegraph-svg").call(d3.drag().on("drag", LineGraph.handleDrag.bind(this)))
                VisualizationManager.endBrush()
            })
        let color = "rgba(0, 0, 0, 0.2)"

        if (VisualizationManager.mode === 'annotate_mode') {
            color = "rgba(255, 0, 0, 0.2)"
        }
        this.svg_data
            .call(brush_linegraph)
            .select('.selection')
            .attr("fill", color)
    }

    select() {
        if (VisualizationManager.selectGraph(this.label_raw)) {
            this.selectedRect.attr("stroke-width", 2)
            this.selectedRect.attr("stroke", "#0097EE")
            this.selectedRect.attr("fill", "#207DEA")
            this.selectedRect.attr("fill-opacity", "0.08")
            this.selectedRect.attr("rx", 5)
            this.selectedRect.attr("ry", 5)
        } else {
            this.selectedRect.attr("stroke-width", 0)
            this.selectedRect.attr("stroke", "black")
            this.selectedRect.attr("fill", "none")
        }
    }
    /* ---------------------------------------------------------------------*/


/* MOUSE EVENT HANDLERS */
/* -------------------------------------------------------------------------*/
    /**
     * LineGraph drag handler
     * - Moves graph window
     * - Updates overlay lines
     * - Updates cursor tooltip
     */
    static handleDrag(e) {

        const x = d3.pointer(e)[0] - 50
        if (e.shiftKey) return
        if (!e.sourceEvent.buttons) {
            e.on("drag", null);
            return
        }

        const d_ms = e.dx / VisualizationManager.width * VisualizationManager.window_time * 1000
        VisualizationManager.moveGraphTimes(-d_ms)
        VisualizationManager.overlay.updateSelectedTimestampLine()
        VisualizationManager.timeline.updateSliderPosition()

        if (VisualizationManager.mode === "annotate_mode") {
            VisualizationManager.overlay.updateHoverLine(e)
            this.hideTooltip()
        }

        if (VisualizationManager.mode === "configure_mode") {
            VisualizationManager.overlay.updateHoverLine(e)
            this.updateTooltip(x)
        }
    }

    handleClick(e) {
        if (!e) return

        if (VisualizationManager.mode === VIZ_MODES.CONFIGURE && !e.shiftKey) {
            if (e.ctrlKey) {
                this.select()
                return
            }

            VisualizationManager.selected_timestamp = windowXtoTimestamp(d3.pointer(e)[0])
            VisualizationManager.overlay.updateSelectedTimestampLine()
        }

        if (VisualizationManager.mode === VIZ_MODES.ANNOTATE) {
            if (VisualizationManager.input_mode === 'cursor') {
                
            }
        }
    }

    handleMouseMove(e) {
        let x = d3.pointer(e)[0]

        VisualizationManager.focus_label = this.label_raw

        if (VisualizationManager.mode === "annotate_mode") {

            if (e.ctrlKey) {
                VisualizationManager.overlay.topBottomLinesVisible(true)
                VisualizationManager.overlay.updateTopBottomLines(...VisualizationManager.overlay.graphBounds(this.label_raw))
                VisualizationManager.overlay.clipHoverLine(...VisualizationManager.overlay.graphBounds(this.label_raw))
            } else {
                VisualizationManager.overlay.clipHoverLine(-99999, 99999)
                VisualizationManager.overlay.topBottomLinesVisible(false)
                VisualizationManager.overlay.updateHoverLine(e)
            }
            VisualizationManager.overlay.updateHoverLine(e)
            this.hideTooltip()
        }
        if (VisualizationManager.mode === "configure_mode") {
            VisualizationManager.overlay.updateHoverLine(e)
            this.displayTooltip()
            this.updateTooltip(x)
        }
    }

    handleMouseLeave(e) {
        if (VisualizationManager.mode === "annotate_mode") {
        } else if (VisualizationManager.mode === 'configure_mode') {
            this.hideTooltip()
        }
    }
    /* ---------------------------------------------------------------------*/
}


function importGraphSettings(label) {
    const settings = Object.keys(LABEL_SETTINGS).includes(label) ? LABEL_SETTINGS[label] : LABEL_SETTINGS['DEFAULT']

    this.y_delta = settings['delta']
    this.y_scaling = settings['translation']
    this.min_y = settings['min']
    this.max_y = settings['max']
    this.outofbound_min = settings['min']
    this.outofbound_max = settings['max']
    this.label = settings['label']
    this.label_raw = label
    this.graph_height = settings['graph_height']
    this.color = settings['color']
}


function createSVGs(label, graph_height) {
    this.svg_data = VisualizationManager.dataVisDiv.append("svg")
        .attr("class", "linegraph-svg")
        .attr("id", "LineGraph_" + label)
        .attr("width", "100%")
        .attr("height", graph_height + VISUALIZATIONS.GRAPH_PADDING.TOP + VISUALIZATIONS.GRAPH_PADDING.BOTTOM)
        .attr("display", "block")
        .style("overflow", "visible")
        .style("pointer-events", 'auto')

    this.svg_yaxis = VisualizationManager.yAxisVisDiv.append("svg")
        .attr("class", "visualizations " + label)
        .attr("id", "visualizations" + label)
        .attr("width", "100%")
        .attr("height", graph_height + VISUALIZATIONS.GRAPH_PADDING.TOP + VISUALIZATIONS.GRAPH_PADDING.BOTTOM)
        .attr("display", "block")
        .style("overflow", "visible")

    this.svg_legend = VisualizationManager.legendVisDiv.append("svg")
        .attr("class", "visualizations " + label)
        .attr("id", "visualizations" + label)
        .attr("width", "100%")
        .attr("height", graph_height + VISUALIZATIONS.GRAPH_PADDING.TOP + VISUALIZATIONS.GRAPH_PADDING.BOTTOM)
        .attr("display", "block")
        .style("overflow", "visible")

    this.removeSVGs = () => {
        if (this.svg_data) this.svg_data.remove()
        if (this.svg_yaxis) this.svg_yaxis.remove()
        if (this.svg_legend) this.svg_legend.remove()
    }
}


function createCanvas() {
    this.fo = this.svg_data.append('foreignObject')
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", this.svg_width())
        .attr("height", this.svg_height())
    this.canvas = this.fo.append('xhtml:canvas')
        .attr('width', this.svg_width())
        .attr('height', this.svg_height())
    this.context = this.canvas.node().getContext('2d')
}


function createTimestampTooltip() {
    this.tooltipFO = this.svg_data.append('foreignObject')
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 200)
        .attr("height", 100)
    this.tooltip = this.tooltipFO.append('xhtml:div')
        .style('background-color', "#FFFFFF")
        .style('color', 'black')
        .style('border', "0.5px solid #d9d9d9")
        .style('padding', "3px")
        .style('margin', "3px")
        .style('text-align', "right")
        .style('display', 'none')

    this.displayTooltip = () => {
        this.tooltip.style('display', 'block')
    }
    this.hideTooltip = () => {
        this.tooltip.style('display', 'none')
    }
    this.updateTooltip = (x) => {
        if (x <= VisualizationManager.width && x >= 0 && VisualizationManager.currentPage !== undefined) {
            const eps = (5 / 360) * VisualizationManager.graph_time()
            const found = VisualizationManager.currentPage.getNearestPoint(windowXtoTimestamp(x), eps, this.label_raw) ?? 
            VisualizationManager.nextPage?.getNearestPoint(windowXtoTimestamp(x), eps, this.label_raw)
            if (found !== undefined) {
                this.tooltip.html((new Date(found.timestamp)).toLocaleString() + ": " + found.value)
                this.tooltipFO
                    .attr('x', this.x(found.timestamp) - 200)
                    .attr('y', this.y(found.value))
            } else {
                this.hideTooltip()
            }
        }
    }
}




function createAxes() {
    this.y = d3.scaleLinear()
        .range([VISUALIZATIONS.GRAPH_PADDING.TOP, VISUALIZATIONS.GRAPH_PADDING.TOP + this.graph_height])
        .domain([this.max_y, this.min_y])

    this.x = d3.scaleTime()
        .range([0, VisualizationManager.width])
        .domain([new Date(VisualizationManager.file_start), new Date(VisualizationManager.file_end)])
}



function createSelectedRect() {
    this.selectedRect = this.selectedRectLayer.append("rect")
        .attr("class", "rect box")
        .attr("x", 1)
        .attr("y", 1)
        .attr("width", this.svg_width() - 1 + "px")
        .attr("height", this.svg_height() - 1 + "px")
        .attr("stroke", "black")
        .attr("fill", "none")
        .attr("stroke-width", 0)
}


function createLayers() {
    this.linesLayer = this.svg_data.append('g')
    this.selectedRectLayer = this.svg_data.append('g')
}

function createLegendText() {
    this.label_text = this.svg_legend.append("text")
        .attr("class", this.label_raw + " label_text")
        .text(this.label)
        .attr("x", 0)
        .attr("y", 50)
        .style("font-weight", "700")
        .style("font-size", "16px")
        .style("fill", "#293241")
        .style("font-family", "Montserrat")
    this.value_text = this.svg_legend.append("text")
        .attr("x", 0)
        .attr("y", 90)
        .attr("dy", "0em")
        .append("tspan")
        .style("font-weight", "bold")
        .style("font-size", "30px")
        .style("font-family", "Montserrat")
        .attr("fill", "black")
        .style("visibility", "hidden")
}