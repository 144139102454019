import React from "react"
import { BaseVisualizationConfigureModal } from "./BaseVisualizationConfigureModal"
import { histogramConfigAtom } from "../../Atoms/Histogram"
import { useEditWindow } from "./useEditWindow"
import { HistogramJSON } from "../../Types/Histogram"
import { WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { useOnMount } from "../../../../../../Hooks/useOnMount"
import { modalityUpdates, useModalitiesProvider } from "../../../../../../Providers/ModalitiesProvider"
import { MobergDropdown } from "../../../../../../Components/MobergDropdown/MobergDropdown"
import { MobergButton, MobergButtonShape, MobergColumn, MobergIconSize, MobergRow, MobergTheme } from "../../../../../../Moberg"
import { TraceConfigJSON } from "../../Types/Trace"
import { currentPatientFileInfoAtom } from "../../Atoms/PatientFile"
import { useRecoilValue } from "recoil"
import { MdAdd, MdClose } from "react-icons/md"
import styled from "styled-components"
import { DataSource } from "../../Types/DataSource"

type ConfigureHistogramModalProps = {
	windowId: string
	layoutId: string
}

export const ConfigureHistogramModal = (props: ConfigureHistogramModalProps) => {
	const modalitiesProvider = useModalitiesProvider()
	const atom = histogramConfigAtom({ windowId: props.windowId, layoutId: props.layoutId })
	const { dataObjectId } = useRecoilValue(currentPatientFileInfoAtom)
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<HistogramJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
	const modalityOptions = modalitiesProvider.modalities.map(modality => (
		{ 
			label: modality, 
			value: {
				name: modality,
				dataKey: modality,
				dataSource: DataSource.CURRENT_PATIENT
			}
		}
	))

	useOnMount(() => {
		modalitiesProvider.update(modalityUpdates.MODALITIES)
	})

	function handleColorChange(index: number, color: string) {
		const currentModalities = structuredClone(editedConfig?.modalityConfigs)
		currentModalities[index].color = color
		updateProperty("modalityConfigs", currentModalities)
	}

	function handleModalityChange(index: number, newConfig: TraceConfigJSON) {
		const currentModalityConfigs = structuredClone(editedConfig?.modalityConfigs)
		Object.assign(currentModalityConfigs[index], newConfig)
		updateProperty("modalityConfigs", currentModalityConfigs)
	}

	const addModality = () => {
		const newConfig: TraceConfigJSON = {
			name: "ABP_na",
			dataKey: "ABP_na",
			color: "#000",
			units: "mmHg",
			isCompositePart: false,
			dataSource: DataSource.CURRENT_PATIENT
		}

		updateProperty("modalityConfigs", [...editedConfig.modalityConfigs, newConfig])
	}

	const deleteModality = (index: number) => {
		const copy = structuredClone(editedConfig.modalityConfigs)
		copy.splice(index, 1)
		updateProperty("modalityConfigs", copy)
	}

	return (
		<BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure Histogram"} saveChanges={saveChanges}>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: "20px" }}>
					<MobergColumn gap="16px">

					<MobergRow horizontalAlign="space-between">
						Initial Page Size

						<MobergDropdown
							selectedValue={editedConfig?.viewDuration}
							onChange={value => updateProperty("viewDuration", parseInt(value as string))}
							options={WINDOW_TIME_PRESETS.filter(preset => preset.label !== "Page Size").map(preset => ({ label: preset.label, value: preset.time }))}
						/>
					</MobergRow>

					<MobergRow gap="32px" horizontalAlign="space-between">
						Lowest Bin Value
						<NumberInput
							type="number"
							value={editedConfig?.binMinimum}
							style={{ width: "100px" }}
							onChange={event => updateProperty("binMinimum", parseInt(event.target.value))}
						/>
					</MobergRow>

					<MobergRow gap="32px" horizontalAlign="space-between">
						Highest Bin Value
						<NumberInput
							type="number"
							value={editedConfig?.binMaximum}
							style={{ width: "100px" }}
							onChange={event => updateProperty("binMaximum", parseInt(event.target.value))}
						/>
					</MobergRow>

					<MobergRow gap="32px" horizontalAlign="space-between">
						Bin Size
						<NumberInput
							type="number"
							value={editedConfig?.binSize}
							style={{ width: "100px" }}
							onChange={event => updateProperty("binSize", parseInt(event.target.value))} />
					</MobergRow>

					<MobergRow gap="16px" verticalAlign="start">
						<span style={{ paddingTop: "8px"}}>
							Modalities
						</span>

						<MobergColumn gap="16px">
							{editedConfig?.modalityConfigs?.map((modalityConfig, index) => {
								return (
									<MobergRow gap="8px" horizontalAlign="right">
										<MobergDropdown
											selectedValue={modalityConfig}
											onChange={newConfig => handleModalityChange(index, newConfig)}
											options={modalityOptions}
											equals={(a: TraceConfigJSON, b: TraceConfigJSON) => (
												a.name === b.name &&
												a.dataKey === b.dataKey &&
												a.dataSource === b.dataSource
											)}			
										/>

										<input type="color" 
											value={editedConfig?.modalityConfigs ? editedConfig?.modalityConfigs[index].color : modalityConfig.color}
											style={{ width: '42px', height: '42px', marginLeft: '10px' }} 
											onChange={e => handleColorChange(index, e.target.value)} />

										<MobergButton 
											shape={MobergButtonShape.SQUARE} 
											onClick={() => deleteModality(index)}>
											<MdClose size={MobergIconSize.SMALL} />
										</MobergButton>
									</MobergRow>
								)
							})}
						</MobergColumn>
					</MobergRow>

					<MobergRow horizontalAlign="right">
						<MobergButton onClick={addModality} theme={MobergTheme.BLUE}>
							<MdAdd size={MobergIconSize.REGULAR} />
							Add Modality
						</MobergButton>
					</MobergRow>

					</MobergColumn>
					
				</div>
			</div>
		</BaseVisualizationConfigureModal>
	)
}

const NumberInput = styled.input`
	padding: 8px 16px;
	padding-right: 10px;
	border: 1px solid lightgray;
	border-radius: 6px;
`
