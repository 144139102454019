import { getDataQuerySocket } from "../../../../../Providers/Socket";
import { ImagePage } from "./ImagePage";

export class PersystEEGImagePage extends ImagePage {

    socketEventName = () => "render_persyst_eeg"

    requestData(batch: Map<number, string[]>): void {
		const socket = getDataQuerySocket(this.socketId)

		batch.forEach((modalities, dataObjectId) => {
			socket.emit(
				this.socketEventName(), 
				this.patientId, 
				dataObjectId, 
				this.id,
				modalities[0],
				10,
				60,
				30,
				1,
				true,
				this.startTime,
				this.endTime,
				this.width,
				this.height
			)
		})
      
    }
}