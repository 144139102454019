import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import MUITable from "../../../../Components/MUITable/MUITable"
import { LINKS } from "../../../../Constants/BackendLinks"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTableContext } from "../../../../Providers/TableProvider"
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergTheme } from "../../../../Moberg"
import { usePopover } from "../../../../Components/MUITable/usePopover"
import { Popper } from '@material-ui/core'
import styled from 'styled-components'
import { MobergBoxShadow } from '../../../../Components/MobergThemes/MobergStyles'
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont"
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox } from "@mui/material"
import { useState } from "react"
import { useOnMount } from "../../../../Hooks/useOnMount"

const domainColumns = [
    { field: "domain", flex: 1, headerName: "Domain", visible: true },
]

function CreateDomainPanel({ setPopoverRef, isOpen, anchorEl, close }) {
    const endpointProvider = useEndpointProvider()
    const { setToastProps } = useTableContext()
    const queryClient = useQueryClient()

    const schema = yup
        .object({
            domain: yup.string().required("Domain is required."),
        })
        .required()

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { domain: "" },
    })

    const addDomain = async body => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.ADD_DOMAIN, body)
    const addDomainMutation = useMutation({
        mutationFn: body => addDomain(body),
        onError: error => {
            setToastProps({ isOpen: true, severity: "error", message: error })
        },
        onSuccess: () => {
            setToastProps({ isOpen: true, severity: "success", message: "Domain added successfully." })
        },
        onSettled: () => {
            queryClient.invalidateQueries("domains")

        },
    })

    const onSubmit = async data => { addDomainMutation.mutate(data); reset({ domain: "" }); close() }

    return (
        <Popper key={"DataGridViewMorePanel"} placement="bottom-start" open={isOpen} anchorEl={anchorEl} style={{ zIndex: 10000 }}>
            <ViewMoreMenuContainer ref={setPopoverRef}>
                <BasicTextField
                    inputId="create-domain-name-id"
                    label="Domain"
                    placeholder="DomainName.com"
                    autoFocus={true}
                    control={control}
                    name="domain"
                    numberColumn={12}
                />
                <MobergButton
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}
                    fontSize={MobergFontSize.SMALL}
                    shape={MobergButtonShape.WIDE}
                    onClick={handleSubmit(onSubmit)}>
                    Submit
                </MobergButton>
            </ViewMoreMenuContainer>
        </Popper>
    )
}

function ViewDomainsModal() {
    const endpointProvider = useEndpointProvider()
    const { isOpen: isCreateDomainPanelOpen, anchorEl: createDomainPanelAnchor, open: openCreateDomainPanel, close: closeCreateDomainPanel, setPopoverRef: setCreateDomainPopoverRef } = usePopover()

    const removeDomains = async domain_ids => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.REMOVE_DOMAINS, { domain_ids })

    const tableToolbarProps = {
        createButton: { title: "Add domain", isShow: true, onClick: openCreateDomainPanel },
        deleteButton: {
            title: "Delete",
            isShow: true,
            onSuccessMessage: "Domains removed successfully",
            invalidateQueryKey: "domains",
            onClick: domain_ids => removeDomains(domain_ids)
        },
        searchInput: { isShow: true }
    }

    const queryProps = {
        queryKey: "domains",
        endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_DOMAINS,
    }

    const [isCheck, setIsCheck] = useState(false)
    function toggleDomainRestriction() {
        endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.SET_RESTRICT_EMAIL_DOMAINS, { restrict_email_domains: !isCheck }).then(() => setIsCheck(!isCheck))
    }

    const [loaded, setLoaded] = useState(false)

    useOnMount(() => {
        async function getDomainRestriction() {
            let res = await endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_DOMAIN_RESTRICTION, {})

            setIsCheck(res?.restrict_email_domains)
            setLoaded(true)
        }

        getDomainRestriction()
    })

    return (
        <>
            <ModalHeader headerText='View Domains' />
            <ModalScrollbar>
                <h2>Allowed Domains</h2>
                {loaded && (
                    <>
                        <FormControlLabel
                            key={"allowed-domains-checkbox"}
                            control={
                                <Checkbox
                                    id={"allowed-domains-checkbox"}
                                    checked={isCheck}
                                    onChange={toggleDomainRestriction}
                                />}
                            label={"Restrict email entry to allowed domains only"}
                            sx={{ width: 'fit-content' }}
                        />
                        {isCheck && <MUITable columns={domainColumns} tableToolbarProps={tableToolbarProps} {...queryProps} />}
                    </>
                )}
            </ModalScrollbar>
            <CreateDomainPanel setPopoverRef={setCreateDomainPopoverRef} isOpen={isCreateDomainPanelOpen} anchorEl={createDomainPanelAnchor} close={closeCreateDomainPanel} />
        </>
    )
}

export default ViewDomainsModal

const ViewMoreMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 200px;
    padding: 16px;
    background: white;
    border-radius: 6px;
    opacity: 0;
    transform: scale(0.95);
    transform-origin: top left;
    box-shadow: ${MobergBoxShadow.REGULAR};
    gap: 16px;
`