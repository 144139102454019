import { Selection, EnterElement } from "d3";
import { createIconButtonPath } from "./D3TimelineButtons";
import { ChangeEvent } from "react";
import { ReactCallbacks } from "../../../Types/ReactCallbacks";
import { D3OneToOneRenderable } from "../D3OneToOneRenderable";

export type D3TimelineSpeedDropdownConfig = {
    width: number
    height: number
    speed: number
}

export class D3TimelineSpeedDropdown extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3TimelineSpeedDropdownConfig> {
    private dropdownIndicatorWidth = 10

    constructor(root: SVGGElement, config: D3TimelineSpeedDropdownConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-timeline-speed-dropdown", reactCallbacks)
        this.render()
    }

    enter(newElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> {
        const group = newElements
            .append("g")
            .attr("class", this.className)

        group.append("text")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .attr("font-family", "source-sans-pro")
            .attr("transform", `translate(${(this.config.width - this.dropdownIndicatorWidth) / 2}, ${this.config.height / 2})`)
            .style("user-select", "none") // disables selecting the text by dragging
            .text(() => this.config.speed)

        const background = group.append("rect")
            .attr("rx", 5)
            .attr("cursor", "pointer")
            .attr("fill", "transparent")
            .attr("fill-opacity", 0.2)
            .attr("width", this.config.width)
            .attr("height", this.config.height)
            .attr("stroke", "#B6B6B6")
            .attr("stroke-width", 0.5)

        const dropdownIndicator = group
            .append("g")
            .attr("transform", `translate(${this.config.width - this.dropdownIndicatorWidth}, 0)`)

        createIconButtonPath(dropdownIndicator, `M0,0 0,${this.config.height}`, "#B6B6B6", 0.5)
        createIconButtonPath(dropdownIndicator, "M3,14 5,16 7,14", "#B6B6B6", 1)

        const selectBox = group.append("foreignObject")
            .attr("width", this.config.width)
            .attr("height", this.config.height)
            .append("xhtml:select")
            .style("user-select", "none") // disables selecting the text because we are replacing it
            .style("cursor", "pointer")
            .style("width", "100%")
            .style("height", "100%")
            .style("border", "none")
            .style("border-radius", "5px")
            .style("outline", "none")
            .style("background", "none")
            .style("color", "transparent")
            
        selectBox
            .on("focus", () => selectBox.style("border", "1px solid black"))
            .on("change", this.speedChanged)
            .on("blur", () => selectBox.style("border", "none"))
            
        selectBox.selectAll("option")
                .data([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
                .enter()
                    .append("option")
                    .style("color", "black")
                    .attr("value", speed => speed)
                    .text(value => `${value}x`)

        background
            .on("mousedown", () => background.attr("fill", "#CCC"))
            .on("mouseleave", () => background.attr("fill", "transparent"))
            .on("mouseup", () => background.attr("fill", "transparent"))

        return group
    }

    update(updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> {
        return updatedElements.select("text").text(() => `${this.config.speed}x`)
    }

    private speedChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        const speed = parseInt(event.target.value)
        this.reactCallbacks.setRootConfig((previous: { playbackSpeed: number }) => ({...previous, playbackSpeed: speed}))
        this.render()
    }
}
