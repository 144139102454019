import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import MUITable from "../../../../Components/MUITable/MUITable"
import { useState } from "react"
import { LINKS } from "../../../../Constants/BackendLinks"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const login_attempts_columns = [
    { field: "email", flex: 1, headerName: "Email", visible: true },
    { field: "ip_address", flex: 0.5, headerName: "IP address", visible: true },
    { field: "login_at", flex: 1, headerName: "Date time", visible: true },
    { 
        field: "status", 
        flex: 0.5, 
        headerName: "Successful", 
        visible: true,
        renderCell: params => {
			return params.value ? <CheckCircleIcon color="success" /> : null
		},
		headerAlign: "center",
		align: "center", 
    },
]

export default function LoginAttemptsModal() {
    const [selectedRows, setSelectedRows] = useState([])
	const queryProps = {
		queryKey: "login_attempts",
		endpoint: LINKS.ADMIN.STUDIES.GET_LOGIN_ATTEMPTS,
	}
	const preselectedProps = { selectedRows, setSelectedRows }

    return (
        <>
            <ModalHeader headerText="View Login Attempts" />
            <div style={{ minWidth: "800px" }}>
                <MUITable columns={login_attempts_columns} preselectedProps={preselectedProps} {...queryProps} isCheckboxSelection={false} />
            </div>
        </>
    )
}