import { Selection, EnterElement, select, ScaleTime, axisBottom, D3DragEvent, drag, DragBehavior } from "d3"
import { D3TimelineSlider } from "./D3TimelineSlider"
import { D3TimelineTooltip, D3TimelineTooltipConfig } from "./D3TimelineTooltip"
import { D3timelineAnnotationsWrapper } from "./D3TimelineAnnotations"
import { Annotation } from "../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { TimeSeriesPageManager } from "../../../Data/TimeSeriesPageManager"
import { ReactCallbacks } from "../../../Types/ReactCallbacks"
import { D3DragOverlay } from "../D3DragOverlay"
import { D3OneToOneRenderable } from "../D3OneToOneRenderable"
import { D3NotLoadedRegionsWrapper } from "../D3NotLoadedRegionsWrapper"

export type D3TimelineNavigatorConfig = {
	viewScale: ScaleTime<any, any, any>
    fileScale: ScaleTime<any, any, any>
	annotations: Annotation[]
	buttonsEnabled: boolean
	scrubbingEnabled: boolean
	timeZone: string
	onDrag? (dragEvent: D3DragEvent<any, any, any>): void
	onDragEnd? (dragEvent: D3DragEvent<any, any, any>): void
}

export class D3TimelineNavigator extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3TimelineNavigatorConfig> {
	private d3AxisClassName: string = "d3-axis-bottom"
	private timelineAnnotationsClipPathId: string = "d3-timeline-annotations-clip"
	private height: number = 30
    private timelineDragBounds = {lower: 0, upper: 0}
	private dragBehavior: DragBehavior<any, any, any>
	private pageManager: TimeSeriesPageManager<any>

	// Children
	private timelineDragOverlay?: D3DragOverlay
	private timelineSlider?: D3TimelineSlider
    public timelineTooltip?: D3TimelineTooltip
	private timelineNotLoadedRegions?: D3NotLoadedRegionsWrapper
	private timelineAnnotations?: D3timelineAnnotationsWrapper

	constructor(root: SVGGElement, config: D3TimelineNavigatorConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-timeline-navigator", reactCallbacks)
		this.pageManager = pageManager

		this.dragBehavior = drag()
			.on("start", this.onDragStart)
			.on("drag", this.onDrag)
			.on("end", this.onDragEnd)

		this.render()
	}

	viewTimesChanged() {
		this.timelineSlider?.render()
		this.timelineTooltip?.render()
	}

	updateNotLoadedRegions = () => {
		this.timelineNotLoadedRegions?.render()
	}

	protected updateDerivedState(): void {
		this.updateChildren()
	}

	enter = (newElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> => {
		const timelineGroup = newElements
			.append("g")
			.attr("class", this.className)

		// axis bottom
		timelineGroup
			.append("g")
			.attr("class", this.d3AxisClassName)
			.attr("transform", `translate(0, ${this.height})`)
			.call(axisBottom(this.config.fileScale).ticks(this.config.fileScale.range()[1] / 100))
			.style("user-select", "none") // disables highlighting the ticks
			.select("path")
			.attr("stroke-opacity", 0) // removes the black line that is usually drawn with a d3 axis

		// timeline container
		timelineGroup
			.append("rect")
			.attr("x", 0)
			.attr("y", 0)
			.attr("width", this.config.fileScale.range()[1])
			.attr("height", 30)
			.attr("rx", 2)
			.attr("fill", "none")
			.attr("stroke-width", 0.5)
			.attr("stroke", "#B6B6B6")

		timelineGroup
			.append("clipPath")
			.attr("id", this.timelineAnnotationsClipPathId)
				.append("rect")
				.attr("width", this.config.fileScale.range()[1])
				.attr("height", this.height)
				.attr("rx", 2)

		timelineGroup.each(this.createChildren)

		return timelineGroup
	}

	update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const timelineGroup = updatedElements

		const d3AxisBottom = timelineGroup
			.select("." + this.d3AxisClassName) as Selection<SVGGElement, any, any, any>

		d3AxisBottom
			.call(axisBottom(this.config.fileScale).ticks(this.config.fileScale.range()[1] / 100))
			.style("user-select", "none") // disables highlighting the ticks

		timelineGroup
			.select("rect")
			.attr("width", this.config.fileScale.range()[1])

		this.renderChildren()

		return timelineGroup
	}

	protected createChildren = (config: D3TimelineNavigatorConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const background = select(nodes[index]).append("g").attr("class", "background").node()
		const foreground = select(nodes[index]).append("g").attr("class", "foreground").node()

		if (!foreground || !background) {
			return
		}

		const notLoadedRegionsConfig = {
			height: this.height,
			scale: this.config.fileScale,
		}
		this.timelineNotLoadedRegions = new D3NotLoadedRegionsWrapper(background, notLoadedRegionsConfig, this.pageManager, this.reactCallbacks)
		
		this.timelineDragOverlay = new D3DragOverlay(foreground, this.getDragOverlayConfig(), this.reactCallbacks)

        this.timelineTooltip = new D3TimelineTooltip(background, this.getTimelineTooltipConfig(), this.reactCallbacks)

		const sliderConfig = {
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			dragBehavior: this.dragBehavior,
			canInteract: this.config.scrubbingEnabled
		}

		this.timelineSlider = new D3TimelineSlider(foreground, sliderConfig, this.reactCallbacks)

		const annotationsConfig = {
			fileScale: this.config.fileScale,
			height: this.height, 
			annotations: this.config.annotations,
			clipPathId: this.timelineAnnotationsClipPathId
		}
		this.timelineAnnotations = new D3timelineAnnotationsWrapper(foreground, annotationsConfig)
	}

	protected updateChildren = () => {
		const notLoadedRegionsConfig = {
			height: this.height,
			scale: this.config.fileScale,
		}
		this.timelineNotLoadedRegions?.updateConfig(notLoadedRegionsConfig)

		const sliderConfig = {
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			dragBehavior: this.dragBehavior,
			canInteract: this.config.scrubbingEnabled
		}

		this.timelineSlider?.updateConfig(sliderConfig)
		this.timelineDragOverlay?.updateConfig(this.getDragOverlayConfig())
        this.timelineTooltip?.updateConfig(this.getTimelineTooltipConfig())
		this.timelineAnnotations?.updateAnnotations(this.config.annotations)
	}

	protected renderChildren = () => {
		this.timelineNotLoadedRegions?.render()
		this.timelineSlider?.render()
		this.timelineTooltip?.render()
		this.timelineAnnotations?.render()
	}

	onDragStart = (dragEvent: D3DragEvent<any, any, any>) => {
		if (!this.config.scrubbingEnabled) {
			return
		}
        const pointerOffset = dragEvent.x

        const [startX, endX] = this.config.viewScale.range()
        const [startDate, endDate] = this.config.viewScale.domain()
        const viewStartX = this.config.fileScale(startDate)
        const viewEndX = this.config.fileScale(endDate)

        const startOffset = viewStartX - startX
        const endOffset = endX - viewEndX

        this.timelineDragBounds = {
            lower: pointerOffset - startOffset,
            upper: pointerOffset + endOffset
        }

		const sliderConfig = {
			color: this.timelineSlider?.draggingFillColor,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			dragBehavior: this.dragBehavior,
			canInteract: this.config.scrubbingEnabled
		}

        this.timelineSlider?.updateConfig(sliderConfig)
    }

    onDrag = (dragEvent: D3DragEvent<any, any, any>) => {
		if (!this.config.scrubbingEnabled) {
			return
		}

        const dx = dragEvent.dx
        const [startDate, endDate] = this.config.viewScale.domain()
        const selectionX1 = this.config.fileScale(startDate)
        const selectionX2 = this.config.fileScale(endDate)

        const [minimumTime, maximumTime] = this.config.fileScale.domain()
        const [minimumX, maximumX] = this.config.fileScale.range()
        const windowSizePixels = selectionX2 - selectionX1

        let newSelectionX1 = selectionX1 + dx
        let newSelectionX2 = selectionX2 + dx

        let newStartTime = this.config.fileScale.invert(newSelectionX1)
        let newEndTime = this.config.fileScale.invert(newSelectionX2)

        // Preserve the initial offset to the slider on left overdrag.
        if ((dragEvent.x < this.timelineDragBounds.lower && selectionX1 === minimumX)) {
            return
        }

        // Preserve the initial offset to the slider on right overdrag.
        if (dragEvent.x > this.timelineDragBounds.upper && selectionX2 === maximumX) {
            return
        }

        // Lock the slider to the beginning on overdrag.
        if (newStartTime < minimumTime) {
            newSelectionX1 = minimumX
            newSelectionX2 = newSelectionX1 + windowSizePixels
        } 

        // Lock the slider to the end on overdrag.
        if (newEndTime > maximumTime) {
            newSelectionX2 = maximumX
            newSelectionX1 = newSelectionX2 - windowSizePixels
        }

        newStartTime = this.config.fileScale.invert(newSelectionX1)
        newEndTime = this.config.fileScale.invert(newSelectionX2)

        this.config.viewScale.domain([newStartTime, newEndTime])

		if (this.config.onDrag) {
			this.config.onDrag(dragEvent)
		}

        requestAnimationFrame(() => {
            this.viewTimesChanged()
        })
    }

    onDragEnd = (dragEvent: D3DragEvent<any, any, any>) => {
		if (!this.config.scrubbingEnabled) {
			return
		}

		const sliderConfig = {
			color: this.timelineSlider?.idleFillColor,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			dragBehavior: this.dragBehavior,
			canInteract: this.config.scrubbingEnabled
		}

        this.timelineSlider?.updateConfig(sliderConfig)

		if (this.config.onDragEnd) {
			this.config.onDragEnd(dragEvent)
		}
    }

	private getDragOverlayConfig = () => ({
		cursor: this.config.scrubbingEnabled ? "crosshair" : "default", 
		dragBehavior: this.dragBehavior,
		boundingBox: { x: 0, y: 0, width: this.config.fileScale.range()[1], height: this.height }
	})

	private getTimelineTooltipConfig = (): D3TimelineTooltipConfig => ({
		viewScale: this.config.viewScale,
		fileScale: this.config.fileScale,
		timeZone: this.config.timeZone
	})
}
