import * as d3 from 'd3'
import { LABEL_SETTINGS } from '../LabelSettings'


import LineGraph from "../LineGraph"
import DataQuery from "../Networking/DataQuery"
import VisualizationManager, { CreateVizState, VizEffect } from "../VisualizationManager"


class Modalities {
	static init() {
	
	}

}
export default Modalities



export const useCurrentModalities = CreateVizState('currentModalities', [])

export const useSelectedModalities = CreateVizState('selectedModalities', [])

const identity = x=>x

function createContainers (divs) {
	divs
		.style('width', '100%')
		.style('height', d => `${LABEL_SETTINGS[d].graph_height}px`)
		.style('display', 'flex')
		.style('margin-bottom', '10px')

	const settings = divs.append('div')
		.attr('class', 'container-settings')
		.style('padding', '10px')
		.style('width', '200px')
		.style('border', '1px solid black')
		.style('border-radius', '5px')
	
	settings.append('button')
		.text('I\'m a button')
	
	settings.append('select')
		.selectAll('option')
		.data(['Line', 'Filled', 'Heatmap'])
		.join('option')
		.text(identity)

	settings.append('button')
		.text('up')
		.on('click', (e, d) => {
			const modalities = VisualizationManager.selectedModalities
			const indx = modalities.indexOf(d)
			if (indx <= 0) return

			const next_modalities = [...modalities]
			next_modalities[indx-1] = d
			next_modalities[indx] = modalities[indx-1]

			VisualizationManager.setSelectedModalities(next_modalities)
		})
	
	settings.append('button')
		.text('down')
		.on('click', (e, d) => {
			const modalities = VisualizationManager.selectedModalities
			const indx = modalities.indexOf(d)
			if (indx >= modalities.length-1) return

			const next_modalities = [...modalities]
			next_modalities[indx+1] = d
			next_modalities[indx] = modalities[indx+1]

			VisualizationManager.setSelectedModalities(next_modalities)
		})

	

	const datas = divs.append('div')
		.attr('class', 'container-data')
		.style('flex', '0 1 100%')
		.style('display', 'flex')

	const yAxes = datas.append('svg')
		.attr('class', 'data-yaxis')
		.style('width', '40px')
		.style('height', '100%')
		.each(function (d, i, group) {
			d3.select(this)
				.append('g') 
				.attr('transform', 'translate(35,0)')
				.call(
					d3.axisLeft(
						d3.scaleLinear()
						.range([10, LABEL_SETTINGS[d].graph_height-10])
						.domain([LABEL_SETTINGS[d].min, LABEL_SETTINGS[d].max])
					)
					.ticks(6)
				)
		})

	const zoom = d3.zoom()
		.on('zoom', zoomed)
	
	function zoomed({ transform }) {
		VisualizationManager.currentTransform = transform
		d3.selectAll('.visualization-xaxis').call(VisualizationManager.xAxis.xAxis.ticks(4)
		.tickFormat(d3.timeFormat("%d %b %X")).scale(transform.rescaleX(VisualizationManager._x)))
		d3.selectAll('.graph-g-line').attr('transform', transform)
	}

	
	divs.append('div')
		.attr('class', 'container-legend')
		.style('width', '200px')
		.text(identity)

	const graphs = datas.append('svg')
		.attr('class', 'data-graph')
		.style('flex', '0 1 100%')
		.style('height', '100%')
		.call(zoom)
		.append('g')
        .attr('class', 'graph-g-line')
        .append('path')
		.attr('class', 'linegraph-line')
		.attr('fill', 'none')
		.attr('stroke-width', '1')
		.attr('stroke', '#000000')
}


VizEffect(modalities => {
	if (modalities.length === 0) {
		VisualizationManager.hideAllDivs()
		return
	} 
	//VisualizationManager.showAllDivs()
	VisualizationManager.pages.forEach(page => {
		page.modalities = modalities
		page.load()
	})
	d3.select('#Visualization')
		.selectAll('div.visualization-container')
		.data(modalities, identity)
		.join(
			enter => enter.append('div')
				.attr('class', 'visualization-container')
				.call(createContainers),
			update => update
				.style('color', 'black'),
			exit => exit
				.remove()
		)

}, 'selectedModalities')
