import { createContext, useContext, useState } from "react";

var WORKSPACE_NAME = process.env.REACT_APP_PARENT_STUDY_ID

const WorkspacesContext = createContext()

export const WorkspacesProvider = ({ children }) => {
    const [selectedWorkspace, setSelectedWorkspace] = useState(WORKSPACE_NAME)

    return (
        <WorkspacesContext.Provider value={{
            selectedWorkspace,
            setSelectedWorkspace
        }}>
            {children}
        </WorkspacesContext.Provider>
    )
}

export const useWorkspacesProvider = () => useContext(WorkspacesContext)
