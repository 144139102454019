import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
/*

A component that displays a progress bar and remaining time for an upload operation.
@param {number} completed - The percentage of completion of the upload operation.
@param {number} time - The estimated remaining time in minutes for the upload operation.
*/

// Example usage:
// <UploadProgressBar completed={50} time={5} />

// The above example will display a progress bar with 50% completion and a remaining time estimate of 5 minutes.

const UploadProgressBar = ({ completed, time }) => {
    const [timeDisplay, setTimeDisplay] = useState('');

    useEffect(() => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        if (hours > 1) {
            setTimeDisplay(`${hours} hours and ${minutes} minutes left`);
        }
        else if (hours === 1) {
            setTimeDisplay(`${hours} hour and ${minutes} minutes left`);
        }
        else if (hours === 0) {
            setTimeDisplay(`${minutes} minutes left`);
            if(minutes === 1){
                setTimeDisplay(`${minutes} minute left`);
            }
            else if (minutes === 0){
                setTimeDisplay(`Less than a minute left`);
            }
        }
    }, [time]);

    return (
        <div>
            <ProgressBarContainer>
                <CompletedProgress style={{ width: `${completed}%`, borderRadius: completed === 100 ? "4px" : "4px 0px 0px 4px"}}> 
                    <p>{completed}%</p>
                </CompletedProgress>
            </ProgressBarContainer>

            {(completed < 100) && (time > 0) &&  <RemainingTimeDescription>
                Estimated time remaining: {timeDisplay}
            </RemainingTimeDescription>}

        </div>
    );
};

const ProgressBarContainer = styled.div`
    border-radius: 4px;
    background: #E0E0E0;
    height: 24px;
`;

const CompletedProgress = styled.div`
    border-radius: 4px 0px 0px 4px;
    height: 24px;
    background: linear-gradient(90deg, #4632DA -0.13%, #207DEA 100.02%);
    position: relative;
    p {
        color: #FFFFFF !important;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 16px !important;
        position: absolute;
        right: 10px;
        line-height: 20px;
        margin-top: 2px;
    }
`;

const RemainingTimeDescription = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #293241;
`;

export default UploadProgressBar;
