import { EnterElement, ScaleBand, ScaleLinear, ScaleTime, Selection, scaleBand } from "d3"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { HistogramReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3HistogramBarGroup, D3HistogramBarGroupConfig } from "./D3HistogramBarGroup"
import { range, sortBy } from "lodash"
import { TimeSeriesData, getTimeSeriesDataAccessor } from "../../../../Data/TimeSeriesData"
import { sortedIndexBy } from "lodash"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { TraceConfigJSON } from "../../../../Types/Trace"

export type D3HistogramBarsConfig = {
	bandScale: ScaleBand<string>
	viewScale: ScaleTime<any, any, any>
	yScale: ScaleLinear<any, any>
	modalityConfigs: TraceConfigJSON[]
	binMinimum: number
	binMaximum: number
	binSize: number,
	clipPathId: string
}

export class D3HistogramBars extends D3OneToManyRenderable<SVGGElement, D3HistogramBarsConfig, D3HistogramBarGroupConfig, HistogramReactCallbacks> {
	private d3BarGroups = new Map<string, D3HistogramBarGroup>()
	private binnedDataByModalityAndPage = new Map<string, number[][]>()
	private pageManager: TimeSeriesPageManager<any>

	private subGroupScale = scaleBand()

	constructor(root: SVGGElement, config: D3HistogramBarsConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: HistogramReactCallbacks) {
		super(root, config, "d3-histogram-bars", reactCallbacks)
		this.pageManager = pageManager
		this.mount()
	}

	public renderPage = (page?: ModalityPage) => {
		// Note: we can't use the render cache to store bins because the bins will change
		// as soon as we move the screen.

		if (!page) {
			return
		}

		const pageIndex = this.getPageIndex(page)
		const [start, end] = this.config.viewScale.domain()

		this.config.modalityConfigs.forEach(config => {
			const traceData = page.data.get(config.name)

			if (!traceData) {
				const defaultCounts = new Array(this.config.bandScale.domain().length).fill(0)
				this.getPagedHistogramsByModality(config.name)[pageIndex] = defaultCounts
				return
			}

			const startIndex = this.findDateIndex(traceData, start.getTime())
			const endIndex = this.findDateIndex(traceData, end.getTime())

			const relevantData = traceData.slice(startIndex, endIndex)
			const counts = this.createHistogram(relevantData, this.getBinEdges(), getTimeSeriesDataAccessor(config))
			const pagedHistograms = this.getPagedHistogramsByModality(config.name)

			pagedHistograms[pageIndex] = counts
		})
	}

	public render = () => {
		this.pageManager.getPagesInView().forEach(page => this.renderPage(page))
		super.render()
	}

	public getMaximumBinCount = () => {
		const maxPerModality = this.config.modalityConfigs.map(config => (
			Math.max(...this.getSummedHistogramData(config.name))
		))

		return Math.max(...maxPerModality)
	}

	// PROTECTED

	protected updateDerivedState() {
		this.subGroupScale.domain(this.config.modalityConfigs.map(config => config.name)).range([0, this.config.bandScale.bandwidth()])

		this.getConfigs().forEach(config => {
			this.d3BarGroups.get(config.binKey)?.updateConfig(config)
		})
	}

	protected datumIdentifier(datum: D3HistogramBarGroupConfig): string | number {
		return datum.binKey
	}

	protected getConfigs(): D3HistogramBarGroupConfig[] {
		const boundingBoxHeight = this.config.yScale.range()[0]
		const bandWidth = this.subGroupScale.bandwidth()

		const configs = this.config.bandScale.domain().map((binKey, binIndex) => {
			const config = {
				boundingBoxHeight,
				binKey,
				x: this.config.bandScale(binKey) ?? 0,
				clipPathId: this.config.clipPathId,
				bars: this.config.modalityConfigs.map(config => ({
					dataKey: config.dataKey,
					color: config.color,
					maxHeight: this.config.yScale.range()[0],
					height: boundingBoxHeight - this.config.yScale(this.getSummedHistogramData(config.dataKey)[binIndex] ?? 0),
					width: bandWidth,
					x: this.subGroupScale(config.name) ?? 0,
				})),
			}

			this.d3BarGroups.get(binKey)?.updateConfig(config)

			return config
		})

		return configs
	}

	protected enter(newElements: Selection<EnterElement, D3HistogramBarGroupConfig, SVGGElement, any>): Selection<SVGGElement, D3HistogramBarGroupConfig, SVGGElement, any> {
		const containers = newElements
			.append("g")
			.attr("class", this.className)
			.attr("clip-path", `url(#${this.config.clipPathId})`)
			.attr("transform", config => `translate(${this.config.bandScale(config.binKey)}, 0)`)

		containers.each((config, index, nodes) => this.createChildren(config, index, nodes))

		return containers
	}

	protected update(updatedElements: Selection<SVGGElement, D3HistogramBarGroupConfig, SVGGElement, any>): Selection<SVGGElement, D3HistogramBarGroupConfig, SVGGElement, any> {
		const groups = updatedElements.attr("transform", config => `translate(${this.config.bandScale(config.binKey)})`)

		this.d3BarGroups.forEach(barGroup => barGroup.render())

		return groups
	}

	protected createChildren = (config: D3HistogramBarGroupConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.d3BarGroups.set(config.binKey, new D3HistogramBarGroup(root, config, this.reactCallbacks))
	}

	protected exit(exitedElements: Selection<SVGGElement, D3HistogramBarGroupConfig, SVGGElement, any>): void {
		exitedElements.each(config => this.d3BarGroups.delete(config.binKey))
		exitedElements.remove()
	}

	private createHistogram = <T>(data: T[], binEdges: number[], valueExtractor: (item: T) => number): number[] => {
		// Initialize counts with zeros
		const counts: number[] = new Array(binEdges.length - 1).fill(0)

		// Sort data based on the value to be binned
		const sortedData = sortBy(data, valueExtractor)

		let j = 0
		for (let i = 0; i < sortedData.length; i++) {
			const value = valueExtractor(sortedData[i])
			while (binEdges[j + 1] <= value) {
				j++
			}
			if (binEdges[j] <= value && value < binEdges[j + 1]) {
				counts[j]++
			}
		}

		return counts
	}

	private findDateIndex(data: TimeSeriesData, targetTime: number): number {
		return sortedIndexBy(data, [targetTime, 0], point => point[0])
	}

	private getBinEdges = () => {
		return range(this.config.binMinimum - this.config.binSize / 2, this.config.binMaximum + (3 * this.config.binSize) / 2, this.config.binSize)
	}

	private getPageIndex = (page: ModalityPage) => {
		const pagesInView = this.pageManager.getPagesInView()
		return (page.id === pagesInView[0].id) ? 0 : 1
	}

	private getSummedHistogramData = (modality: string) => {
		const pagedHistograms = this.getPagedHistogramsByModality(modality)
		return pagedHistograms[0].map((count, index) => pagedHistograms ? count + pagedHistograms[1][index] : 0)
	}

	// Gets the map for a modality which contains the histogram for each page of data by index
	private getPagedHistogramsByModality = (modality: string): number[][] => {
		let pagedHistograms = this.binnedDataByModalityAndPage.get(modality)
		const numBins = this.config.bandScale.domain().length

		// When we create a new Array, we are constructing a pointer to a new place in memory.
		// We only have to update the reference of the outer array containing the histogram data if it changes.
		let needToUpdateReference = false

		if (!pagedHistograms) {
			pagedHistograms = new Array(2)
			this.binnedDataByModalityAndPage.set(modality, pagedHistograms)
			needToUpdateReference = true
		}

		// These mutations directly modify the array that is in the map, no set required.
		if (!pagedHistograms[0]) {
			pagedHistograms[0] = new Array(numBins).fill(0)
		}

		if (!pagedHistograms[1]) {
			pagedHistograms[1] = new Array(numBins).fill(0)
		}

		// If we created a brand new array, we need to update the reference in the Map
		if (needToUpdateReference) {
			this.binnedDataByModalityAndPage.set(modality, pagedHistograms)
		}

		return pagedHistograms
	}
}
