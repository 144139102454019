import { ModalityDataSource } from "../Types/ModalityDataSource"
import { ImagePage } from "./ImagePage"
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "./TimeSeriesPageManager"

export type ImagePageManagerConfig = TimeSeriesPageManagerConfig & {
	height: number
}

export class ImageTimeSeriesPageManager<ConfigType extends ImagePageManagerConfig=ImagePageManagerConfig> extends TimeSeriesPageManager<ImagePage, ConfigType> {

    // TODO: Rework this way of generating pages for all page managers.
    // if there are too many, this blocks the UI.
    // page objects should be allocated in a pool (we only ever use 30 at a time) so they can be reset and reused when they are needed
    // instead of creating them all at once.
    protected generatePages = (startTime: number, endTime: number, pageDuration: number, pageWidth: number, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, startAtIndex=0) => {
		const pages = []
		const pageHeight = Math.round(this.config.height)
		pageWidth = Math.round(pageWidth)

		if (!this.config) {
			return []
		}

		let start = startTime
		let end = startTime + pageDuration
		let idx = startAtIndex

		while (start < endTime) {
			const width = end < endTime ? pageWidth : Math.floor(((endTime - start) * pageWidth) / pageDuration)
			const new_page = this.getNewPage(start, end < endTime ? end : endTime, idx++, width, pageHeight, this.config.patientId, modalityDataSources, timeZoneOffsetMs, this.config.windowId)
			
			pages.push(new_page)

			start += pageDuration
			end += pageDuration
		}

		return pages
	}

	protected getNewPage(startTime: number, endTime: number, index: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, socketId: string): ImagePage { 
		return new ImagePage(startTime, endTime, index, width, height, patientId, modalityDataSources, timeZoneOffsetMs, socketId) 
	}

	// Images take up much less memory than actual time series data, so we can cache much more of it.
	protected getNumberOfCachedPages(): number {
		return 200
	}
}