// The ColorPicker component is a customizable color picker built using React and styled-components. It provides the user with options to select a color and adjust its opacity. This component utilizes the CirclePicker and CompactPicker components from the react-color library for color selection.


// Install the required dependencies (styled-components, react-icons, react-color) before using the ColorPicker component.
// Link to the react-color library documentation: https://casesandberg.github.io/react-color

import styled from "styled-components";
import { useEffect, useState } from "react";
import * as MdIcons from "react-icons/md";
import { AlphaPicker, CirclePicker, CompactPicker } from "react-color";
import { Column, Columns, Row } from "../../Managers/VisualizationManager/Utils/Styling";

function hexToRGB(hex) {
    // Remove the hash character (#) if it exists
    hex = hex?.replace(/^#/, '');

    // Parse the hex string into separate RGB values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the RGB values as an object
    return { r, g, b };
}

export function ColorPicker(props) {
    const [colorRGBA, setColorRGBA] = useState({...hexToRGB(props.color), a: props.opacity / 100})
    const [colorPicker, setColorPicker] = useState(false)

    useEffect(() => {
        setColorRGBA({...hexToRGB(props.color), a: props.opacity / 100})
    }, [props.color, props.opacity])

    const handleClick = () => {
        setColorPicker(!colorPicker);
    }

    const handleClose = () => {
        setColorPicker(false);
    }

    const handleColorChange = colorInfo => {
        setColorRGBA(previous => ({...colorInfo.rgb, a: previous.a }))
        props.handleColorChange(colorInfo.hex)
    }

    const handleOpacityChange = colorInfo => {
        console.log("handle opacity change!")
        setColorRGBA(colorInfo.rgb)
        props.handleOpacityChange(colorInfo.rgb.a)
    }

    return (
        <>
            <Row id="test"
                style={{
                    marginTop: "20px",
                }}
            >
                <Columns gap="10px">
                    <Column basis="50%">
                        <Columns gap="20px" alignItems="center">
                            <div style={{display: props.showPopoverOnly ? "none" : "inline-flex", alignItems: "center"}}>
                                <MdIcons.MdOutlineFormatColorFill size={18} />
                                <h1 style={{ margin: 0, marginRight: "8px" }}> Color: </h1>
                                {/* The CirclePicker component from the react-color library is used to display a set of predefined colors and enable color selection by clicking on a circle. */}
                                <CirclePicker
                                    circleSize={22}
                                    // Default colors, can be changed
                                    colors={[
                                        "#f54242",
                                        "#6DCD21",
                                        "#2CAEF6",
                                        "#FFD466",
                                        "#EB144C",
                                        "#795548",
                                        "#6462FC",
                                    ]}
                                    color={props.color}
                                    onChangeComplete={handleColorChange}
                                />
                            </div>
                            <div>
                                {/* Expanded color picker option */}
                                <SecondaryColorPicker onClick={handleClick} style={{display: props.showPopoverOnly ? "none" : "flex"}}>
                                    +
                                </SecondaryColorPicker>
                                {(colorPicker || props.showPopoverOnly) ? (
                                    <PopOver>
                                        <SecondaryColorPickerContainer onClick={handleClose}> </SecondaryColorPickerContainer>
                                        {/* Compact picker opens when expanded, other color pickers can be added as well. */}
                                        <CompactPicker
                                            color={colorRGBA}
                                            onChange={handleColorChange}
                                        />
                                    </PopOver>
                                ) : <div style={{height: "0px", width: "0px"}}></div>}
                            </div>
                        </Columns>
                    </Column>
                </Columns>
            </Row>
            <Row style={{display: props.enableOpacity ? "flex" : "none"}}>
                <Column basis="50%">
                    <Columns gap="20px" alignItems="center">
                    
                        <MdIcons.MdOpacity size={18} />
                        <h1 style={{ margin: 0 }}> Opacity: </h1>
                        {/* The AlphaPicker component from the react-color library is used to adjust the opacity of the selected color. */}
                        <AlphaPicker
                            color={colorRGBA}
                            onChange={handleOpacityChange}
                            style={{height: "20px"}}
                        />
                        <div style={{ width: "35px" }}>
                            {/* Shows the opacity in % */}
                            {Math.round(colorRGBA.a * 100)}%
                        </div>
                    </Columns>
                </Column>
            </Row>
        </>
    );
}

// This styled component defines the style of the add color button. You can modify its appearance by changing the CSS properties such as width, height, border-radius, box-shadow, color, font-size, etc.
const SecondaryColorPicker = styled.div`
	width: 22px;
	height: 22px;
	border-radius: 50%;
	box-shadow: 0 0 0 1px rgba(224, 224, 224, 0.5);
	color: gray;
	display: flex;
	justify-content: center;
	cursor: pointer;
	font-size: 22px;
	align-items: center;
	padding-bottom: 5px;
	font-weight: 500;
`;

// This styled component defines the style of the additional color picker container when it is expanded. 
const SecondaryColorPickerContainer = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
    height: fit-content;
    width: fit-content;
`;

// This styled component defines the style of the color picker popover. It controls the positioning of the popover relative to the add color button.
const PopOver = styled.div`
	position: absolute;
	z-index: 2;
    right: 0;

	div {
		position: "fixed";
		top: "0px";
		right: "0px";
		bottom: "0px";
		left: "0px";
	}
`;
