import React, {  createContext, useState,  useContext } from 'react';

// Create a context object
const UploadProcessContext = createContext({ submittedForm: { formContent: {} } });

// Create a provider component
export const UploadProcessProvider = ({ children }) => {

    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)

    const updateTermsAndConditionsChecked = () => {
        setTermsAndConditionsChecked(!termsAndConditionsChecked)
    }

    const [currentWindow, setCurrentWindow] = useState()

    const [selectedSiteID, setSelectedSiteID] = useState('')
    const [selectedSitePrimaryKey, setSelectedSitePrimaryKey] = useState('')
    const [selectedSiteName, setSelectedSiteName] = useState('')

    const [selectedProjectID, setSelectedProjectID] = useState('')
    const [selectedProjectPrimaryKey, setSelectedProjectPrimaryKey] = useState('')
    const [selectedProjectName, setSelectedProjectName] = useState('')

    const [patientID, setPatientID] = useState('')
    const [patientPrimaryKey, setPatientPrimaryKey] = useState('')

    const [existingPatientsCheckedProvider, setExistingPatientsCheckedProvider] = useState(true)
    const [newPatientCheckedProvider, setNewPatientCheckedProvider] = useState(false)

    const [removePHIAgreementChecked, setRemovePHIAgreementChecked] = useState(false)

    const [cnsFolders, setCNSFolders] = useState([]);
    const [medicationsFiles, setMedicationsFiles] = useState([]);
    const [natusFolders, setNatusFolders] = useState([]);

    const [cnsDirectoryNames, setCNSDirectoryNames] = useState([])
    const [natusDirectoryNames, setNatusDirectoryNames] = useState([]) 
    const [medicationsFileNames, setMedicationsFileNames] = useState([])   

    const [cnsTableData, setCNSTableData] = useState([]);
    const [medicationsTableData, setMedicationsTableData] = useState([]);
    const [natusTableData, setNatusTableData] = useState([]);

    const [uploadingType, setUploadingType] = useState('');

    const [changeUploadData, setChangeUploadData] = useState(false);

    const [existingCNSDataTableData, setExistingCNSDataTableData] = useState()
    const [existingNatusDataTableData, setExistingNatusDataTableData] = useState()
    const [existingMedicationsDataTableData, setExistingMedicationsDataTableData] = useState()

    const [submittedForm, setSubmittedForm] = useState({})

    const resetCheckbox = () => {
        setTermsAndConditionsChecked(false);
        setExistingPatientsCheckedProvider(true);
        setNewPatientCheckedProvider(false);
        setRemovePHIAgreementChecked(false);
    }

    const checkFoldersIsEmpty = () => {
        if (cnsTableData.length === 0 && medicationsTableData.length === 0 && natusTableData.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const resetUploadFolders = () => {
        setCNSFolders([]);
        setMedicationsFiles([]);
        setNatusFolders([]);
    }

    const resetDirectoryNames = () => {
        setCNSDirectoryNames([]);
        setNatusDirectoryNames([]);
        setMedicationsFileNames([]);
    }

    const resetTableData = () => {
        setCNSTableData([]);
        setMedicationsTableData([]);
        setNatusTableData([]);
        setExistingCNSDataTableData();
        setExistingNatusDataTableData();
        setExistingMedicationsDataTableData();
    }

    const resetExistingTableData = () => {
        setExistingCNSDataTableData();
        setExistingNatusDataTableData();
        setExistingMedicationsDataTableData();
    }

    const resetString = () => {
        setSelectedSiteID('');
        setSelectedSiteName('');
        setPatientID('');
        setSelectedSitePrimaryKey({});
        setSelectedProjectID('');
        setSelectedProjectName('');
        setSelectedProjectPrimaryKey({});
        setPatientPrimaryKey({});
    }

    const resetForm = () => {
        setSubmittedForm({});
    }

    const resetCache = () => {
        resetCheckbox()
        resetUploadFolders()
        resetTableData()
        resetString();
        resetDirectoryNames();
        setCurrentWindow();
        resetForm();
    }

    return (
        <UploadProcessContext.Provider value={{ cnsDirectoryNames, setCNSDirectoryNames, natusDirectoryNames, setNatusDirectoryNames, medicationsFileNames, setMedicationsFileNames, termsAndConditionsChecked, updateTermsAndConditionsChecked, selectedSiteID, setSelectedSiteID, selectedSiteName, setSelectedSiteName ,patientID, setPatientID, existingPatientsCheckedProvider, setExistingPatientsCheckedProvider, newPatientCheckedProvider, setNewPatientCheckedProvider, removePHIAgreementChecked, setRemovePHIAgreementChecked, cnsTableData, setCNSTableData, medicationsTableData, setMedicationsTableData,
        natusTableData, setNatusTableData, setCNSFolders, setMedicationsFiles, setNatusFolders, uploadingType, setUploadingType, setChangeUploadData, changeUploadData, cnsFolders, medicationsFiles, natusFolders, resetCache, resetTableData, resetUploadFolders, checkFoldersIsEmpty, selectedSitePrimaryKey, setSelectedSitePrimaryKey, currentWindow, setCurrentWindow, existingCNSDataTableData, setExistingCNSDataTableData, existingNatusDataTableData, setExistingNatusDataTableData, existingMedicationsDataTableData, setExistingMedicationsDataTableData, resetDirectoryNames, resetExistingTableData, submittedForm, setSubmittedForm, selectedProjectID, setSelectedProjectID, selectedProjectName, setSelectedProjectName, selectedProjectPrimaryKey, setSelectedProjectPrimaryKey, patientPrimaryKey, setPatientPrimaryKey}}>
        {children}
        </UploadProcessContext.Provider>
    );
};

export const useUploadProcessProvider = () => {
    return useContext(UploadProcessContext)
}