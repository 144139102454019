import { atomFamily } from "recoil"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { scaleTime } from "d3"
import { HistogramConfig } from "../Components/Visualizations/Histogram/D3/D3Histogram"

export const histogramConfigAtom = atomFamily<HistogramConfig, LayoutWindowId>({
    key: "HistogramConfig",
    default: {
        id: "?",
        countMax: 100,
        binMinimum: 10,
        binMaximum: 25,
        binSize: 5,
        modalityConfigs: [],
        annotations: [],
        patientId: "?",
        dataObjectId: "?",
        viewScale: scaleTime(),
        fileScale: scaleTime(),
        isLinked: false,
        dimensions: {height: 0, width: 0},
        playbackSpeed: 1,
        timelineController: null,
        timeZone: "America/New_York",
        liveModeEnabled: false,
    }
})
