import { TraceConfigJSON } from "../Types/Trace"

export type TimeSeriesData = TimeSeriesDatum[]
export type TimeSeriesDatum = [number, number] | [number, number[]]

export const getTimeSeriesDataAccessor = (trace: TraceConfigJSON): ((datum: TimeSeriesDatum) => number) => {
    const dataAccessor = (datum: TimeSeriesDatum) => {
        if (trace.isCompositePart && Array.isArray(datum[1])) { 
            // If the modality is part of a composite data type, like EEG or CPPOpt.
            return datum[1][trace.compositeIndex]
        }
    
        return datum[1] as number
    }

    return dataAccessor
}
