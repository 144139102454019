export type EEGMontages = {
	"Longitudinal-bipolar": string
	"Bipolar-Transverse": string
	"SD-Detection": string
	Recorded: string
	"Create Montage": string
}

export const EEG_MONTAGES: EEGMontages = {
	"Longitudinal-bipolar": "Fp1-F7,F7-T7,T7-P7,P7-O1,Fp2-F8,F8-T8,T8-P8,P8-O2,Fp1-F3,F3-C3,C3-P3,P3-O1,Fp2-F4,F4-C4,C4-P4,P4-O2,Fz-Cz,Cz-Pz",
	"Bipolar-Transverse": "F7-Fp1,Fp1-Fp2,Fp2-F8,F7-F3,F3-Fz,Fz-F4,F4-F8,T7-C3,C3-Cz,Cz-C4,C4-T8,Fp7-P3,P3-Pz,Pz-P4,P4-P8,Fp7-O1,O1-O2,O2-P8",
	"SD-Detection": "ECoG1-Ref,ECoG2-Ref,ECoG3-Ref,ECoG4-Ref,ECoG5-Ref,ECoG6-Ref",
	Recorded: "*-Ref",
	"Create Montage": "Create Montage",
}

export const EEG_LOW_FILTERS = { "0.1Hz": 0.1, "0.5Hz": 0.5, "1Hz": 1.0, "2Hz": 2.0, "3Hz": 3.0, Off: 0 }

export const EEG_HIGH_FILTERS = { Off: 0, "100Hz": 100.0, "90Hz": 90.0, "80Hz": 80.0, "70Hz": 70.0, "60Hz": 60.0, "50Hz": 50.0, "40Hz": 40.0, "30Hz": 30.0 }

export const EEG_NOTCH_FILTERS = { "60Hz": 60.0, "50Hz": 50.0, Off: 0 }

export const EEG_SENSITIVITY = {
	"1000 uV": 1000 * 1e-6,
	"700 uV": 700  * 1e-6,
	"500 uV": 500 * 1e-6,
	"300 uV": 300 * 1e-6,
	"200 uV": 200 * 1e-6,
	"150 uV": 150 * 1e-6,
	"100 uV": 100 * 1e-6,
	"70 uV": 70 * 1e-6,
	"50 uV": 50 * 1e-6,
	"30 uV": 30 * 1e-6,
	"20 uV": 20 * 1e-6,
	"15 uV": 15 * 1e-6,
	"10 uV": 10 * 1e-6,
	"7 uV": 7 * 1e-6,
	"5 uV": 5 * 1e-6,
	"3 uV": 3 * 1e-6,
	"2 uV": 2 * 1e-6,
	"1 uV": 1 * 1e-6,
}
export class EEGDisplayConfig {
	public montageName: keyof EEGMontages
	public LFF: number
	public HFF: number
	public notch: number
	public sensitivityMicroVolts: number
	public recordedChannels: string[]

	constructor(montage: keyof EEGMontages = "Longitudinal-bipolar", LFF = 1.0, HFF = 30.0, notch = 60.0, sensitivityMicroVolts = 1.0) {
        this.montageName = montage
		this.LFF = LFF
		this.HFF = HFF
		this.notch = notch
		this.sensitivityMicroVolts = sensitivityMicroVolts
		this.recordedChannels = []
	}

	get channelLabels(): string[] {
		if (this.montageName === "Recorded") {
            return this.recordedChannels
        }
		
        return this.montage.split(",")
	}

	get channels(): string[] {
		return [...new Set(this.channelLabels
			.flatMap((channelPair: string) => channelPair.split("-"))
			.map((channel: string) => `EEG_${channel}`))]
	}

	get channelPairs(): string[][] {
		return this.channelLabels.map(channelPair => channelPair.split("-"))
	}

	set channelLabels(labels: string[]) {
		this.recordedChannels = labels
	}

	get montage(): string {
		return this.montageName in EEG_MONTAGES ? EEG_MONTAGES[this.montageName] : ""
	}

	get eegModality() {
		return "Montage=" + this.montage + ":" + this.qualifiers
	}

	// get eegPairCount() {
	// 	if (this.montageName === "Recorded") return this.channelLabels.split(",").length > 0 ? this.channelLabels.split(",").length : 1
	// 	else return this.montage.split(",").length > 0 ? this.montage.split(",").length : 1
	// }
    
	get qualifiers() {
		return "Notch=" + this.notch + "+HFF=" + this.HFF + "+LFF=" + this.LFF
	}
}
