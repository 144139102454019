import { CSSProperties, RefObject } from "react"
import { MobergAnimationCurve, MobergAnimationTiming } from "../Components/MobergAnimation/MobergAnimation"
import { MobergBoxShadow } from "../Components/MobergThemes/MobergStyles"

export const openAnimationOffset = "15px"
export const closeAnimationOffset = "60px"
export const openTransition = `opacity ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}, margin-top ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN}`
export const closeTransition = `opacity ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_OUT}, margin-top ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_OUT}`

export type ModalOptions = {
	animate?: boolean
	dataReviewHotkeysEnabled?: boolean
	zIndex?: number
}

export interface ModalConfig {
	component: JSX.Element
	options: ModalOptions
	modalRef: RefObject<HTMLDivElement>
	overlayRef: RefObject<HTMLDivElement>
}

// Style For Gray Overlay
export const grayOverlayStyles: CSSProperties = Object.freeze({
	background: "#000",
	opacity: 0,
	position: "fixed",
	top: 0,
	left: 0,
	width: "100%",
	height: "100%",
})

// Style For Modal
export const modalStyles = Object.freeze({
	display: "flex",
	background: "#FFF",
	overflowY: "auto",
	overscrollBehavior: "contain",
	borderRadius: "6px",
	opacity: 0,
	flexDirection: "column",
	boxShadow: MobergBoxShadow.REGULAR,
	position: "absolute",
	maxHeight: "100vh",
	marginTop: openAnimationOffset,
	width: "fit-content",
})
