import { debounce } from "lodash";
import { getDataQuerySocket } from "../../../../../Providers/Socket";
import { PersystEEGLabelsSocketResponse } from "../Types/SocketResponse";
import { ImagePage } from "./ImagePage";
import { PersystEEGImagePage } from "./PersystEEGImagePage";
import { PersystPageManager } from "./PersystPageManager";
import { ModalityDataSource } from "../Types/ModalityDataSource";

export class PersystEEGImagePageManager extends PersystPageManager {
    protected getNewPage(startTime: number, endTime: number, index: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, socketId: string): ImagePage {
        return new PersystEEGImagePage(startTime, endTime, index, width, height, patientId, modalityDataSources, timeZoneOffsetMs, socketId)
    }

	// Debounce allows us to call this function a lot and not worry about sending a butt ton of requests.
	protected requestTitlesAndLabels = debounce((height: number, width: number, montage: string) => {
		this.setRequestDimensions({ height, width, panel: montage })
		const eventName = "render_persyst_eeg_labels"
		const socket = getDataQuerySocket(this.config.windowId)

		const listener = (event: PersystEEGLabelsSocketResponse) => {
			if (event.height !== height || event.width !== width) {
				socket.off(eventName, listener)
				return
			}

			const data = {
				titles: new ArrayBuffer(0),
				labels: event.image,
			}

			this.setTitlesAndLabelsCache({
				panel: montage,
				dimensions: { height, width },
				data: data,
			})

			socket.off(eventName, listener)
			this.setRequestDimensions(null)
			this.onTitlesAndLabelsLoaded()
		}

		socket.on(eventName, listener)
		socket.emit(eventName, this.config.patientId, this.config.dataObjectId, 1, montage, width, height)
	}, 200)
}
