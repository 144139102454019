import {atom, selector} from "recoil" 

const percentUploaded = atom({
    key: 'percentUploaded',
    default: 0
})

const timeRemaining = atom({
    key: 'timeRemaining',
    default: 0
})

const fileHashed = atom({
    key: 'fileHashed',
    default: 0
})

const fileUploaded = atom({
    key: 'fileUploaded',
    default: 0
})

const totalHashFiles = atom({
    key: 'totalHashFiles',
    default: 0
})

const totalUploadFiles = atom({
    key: 'totalUploadFiles',
    default: 0
})

const uploadFailed = atom({
    key: 'uploadFailed',
    default: false
})

export {
    percentUploaded,
    timeRemaining,
    fileHashed,
    fileUploaded,
    totalHashFiles,
    totalUploadFiles,
    uploadFailed
  };