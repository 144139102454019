let worker: Worker

export const getPageDataWorker = () => {
	if (!worker) {
		worker = new Worker("./PageDataWorkerInstance", { name: "page data worker", type: "module" })
	}

	return worker
}

export type PageDataWorkerResponse<DataType> = {
    data: {
		pageId: number
		dataObjectId: number
        dataMap: Map<string, DataType>
    }
}

export type PageDataWorkerRequest = {
	pageId: number
	type: string
	dataObjectId: number
	timeZoneOffsetMs: number
	pixels: any
}
