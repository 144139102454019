import { useRef } from "react"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { ScaleTime } from "d3"

type UseD3CheckReloadDataProps = {
	d3Controller: D3TimeBasedVisualization<any, any, any, any> | undefined
	clearDataIfChanges: Object
	clearRenderCacheIfChanges?: Object
	fileScale: ScaleTime<any, any, any>
}

const handleReloadData = (prevConfig: React.MutableRefObject<string | number>, newConfig: string | number, reloadMethod: () => void) => {
	if (newConfig !== prevConfig.current) {
		reloadMethod()
		prevConfig.current = newConfig
	}
}

export const useD3CheckReloadData = ({ d3Controller, clearDataIfChanges, clearRenderCacheIfChanges, fileScale }: UseD3CheckReloadDataProps) => {
	const previousFullDataReloadConfig = useRef("")
	const previousClearCacheReloadConfig = useRef("")
	const fileEndDate = fileScale.domain()[1]
	const previousTimestamp = useRef(fileEndDate.getTime())

	const fullLoadConfig = JSON.stringify(clearDataIfChanges)
	const clearCacheConfig = JSON.stringify(clearRenderCacheIfChanges)

	handleReloadData(previousFullDataReloadConfig, fullLoadConfig, () => d3Controller?.clearDataAndReload())
	handleReloadData(previousClearCacheReloadConfig, clearCacheConfig, () => d3Controller?.clearCacheAndRedraw())
	handleReloadData(previousTimestamp, fileEndDate.getTime(), () => d3Controller?.catchUpPages(fileEndDate))
}
