import { createContext, useEffect, useState, useCallback } from "react";
import { LINKS, isDemo, isDevelopment } from "../../Constants/BackendLinks";
import { useRequest } from "../../Hooks/useRequest";
import VisualizationArea from "./VisualizationArea";
import VisualizationManager from "./VisualizationManager";
import loadingSvg from "./smartneuro.svg";
import { useEndpointProvider } from '../../Providers/EndpointProvider';
import * as MdIcons from "react-icons/md";
import DataQuery from "./Networking/DataQuery";
import Modalities from "./Variables/Modalities";
import { useAuthProvider } from "../../Providers/AuthProvider";
import { useWorkspacesProvider } from "../../Providers/WorkspacesProvider";
import { hotkeysAtom, loadHotkeysFromConfigs } from "../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { currentPatientFileInfoAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/PatientFile";
import { activeAnnotationSessionAtom, annotationGroupsAtom, annotationsAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useOnMount } from "../../Hooks/useOnMount";
import { layoutGroupsAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { pageManagerRegistry } from "../../Pages/Data/Visualize/DataReview/Data/PageManagerRegistry";
import { closeDataQuerySocketConnections } from "../../Providers/Socket";
import { viewScaleRegistry } from "../../Pages/Data/Visualize/DataReview/Data/ViewScaleRegistry";
import { useModalProvider } from '../../Providers/ModalProvider';
import UnloadFilesModal from '../../Pages/Data/Visualize/Subpages/UnloadFilesModal';
import { utcToZonedTime } from "date-fns-tz"
import { MobergButton, MobergButtonShape } from "../../Components/MobergButton/MobergButton";
import { MobergIconSize } from "../../Components/MobergIcon/MobergIcon";
import { useAnnotationService } from "../../Hooks/useAnnotationService";
import { fileScaleRegistry } from "../../Pages/Data/Visualize/DataReview/Data/FileScaleRegistry";

export const VisualizationContext = createContext(null)

const prefetchId = Math.floor(Math.random() * 10000)
var prefetched_size = 0


export function Visualization (props) {
	const resetAnnotations = useResetRecoilState(annotationsAtom)
	const workspacesProvider = useWorkspacesProvider()
	const authProvider = useAuthProvider()
	const endpointProvider = useEndpointProvider()
	const setPatientFileInfo = useSetRecoilState(currentPatientFileInfoAtom)
	const setAnnotationGroups = useSetRecoilState(annotationGroupsAtom)
	const setActiveAnnotationSession = useSetRecoilState(activeAnnotationSessionAtom)
	const [layoutsLoaded, setLayoutLoaded] = useState(false)
	const setLayoutGroups = useSetRecoilState(layoutGroupsAtom)
	const { createAnnotationGroup } = useAnnotationService()

	const { patientID, siteName, patient_id, selectedFile, onClose, dataObjectID, demoLivePatient } = props

    const [ready, setReady] = useState(false)

	const [prefetched, setPrefetched] = useState(false)
	const [percentPrefetched, setPercentPrefetched] = useState("Setting up files…")
	const [togglePrefetch, setTogglePrefetch]  = useState(false)
	const [modalCreated, setModalCreated]  = useState(false)
	const [enoughSpaceToPreload, setEnoughSpaceToPreload]  = useState(false)

	const { close, createModal } = useModalProvider()

	function cancelPrefetch(){
		stopPrefetch()
		handleCloseVisualization()
		close()
	}

	function handleCloseVisualization() {
		// Memory Cleanup
		pageManagerRegistry.clear()
		viewScaleRegistry.clear()
		fileScaleRegistry.clear()

		// Socket Cleanup
		closeDataQuerySocketConnections()

		// Additional effects from VisualizePatient
		onClose()
	}

	function skipPrefetch(){
		stopPrefetch()
		setPrefetched(true)		
	}
	

	function stopPrefetch(){
		if(VisualizationManager.prefetchSocket){			
			VisualizationManager.prefetchSocket.emit("prefetch_data", 
				props.patientID,
				dataObjectID, 
				prefetchId,
				prefetched_size,
				true)		
			VisualizationManager.removeSocketListener(prefetchId)		
		}
		prefetched_size = 0
	}

  

	// TODO: replace with endpointProvider
	const [inventory, inventoryLoaded, getPrefetchInventory] = useRequest(
		//let body = {}
		//return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY, body)

		`${LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
            cache: "no-cache",
            headers: {
                'content-type': "application/json",
                'Authorization': authProvider.token,
            },
            body: JSON.stringify({
                study_id: workspacesProvider.selectedWorkspace
            })
		})
	)

	const [prefetchQueue, prefetchQueueLoaded, getPrefetchQueue] = useRequest(
		//let body = {}
		//return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY, body)

		`${LINKS.ADMIN.PREFETCH.GET_PREFETCH_QUEUE.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
            cache: "no-cache",
            headers: {
                'content-type': "application/json",
                'Authorization': authProvider.token,
            },
            body: JSON.stringify({
                study_id: workspacesProvider.selectedWorkspace
            })
		})
	)


	const [fileExists, fileExistsLoaded, getFileExists] = useRequest(
		`${LINKS.DATA.PROFILING.CHECK_HDF5_FILE.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
            cache: "no-cache",
            headers: {
                'content-type': "application/json",
                'Authorization': authProvider.token,
            },
            body: JSON.stringify({
                patient_id: patient_id,
                study_id: workspacesProvider.selectedWorkspace
            })
		})
	)

    const [modalities, modalitiesLoaded, getModalitiesUnion] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MODALITIES_UNION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json', 
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				patient_uids: [patient_uid],
			})
		})
	)

	const [patientModalities, patientModalitiesLoaded, getModalitiesIntersection] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MODALITIES_INTERSECTION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json', 
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				patient_uids: [patient_uid],
			})
		})
	)

    const [medications, medicationsLoaded, getMedicationsIntersection] = useRequest(
		`${LINKS.DATA.TRIALS.GET_MEDICATION_INTERSECTION.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_uid => ({
			method: "POST",
			cache: "no-cache",
			headers: {
				'content-type': 'application/json', 
				'Authorization': authProvider.token
			},
			body: JSON.stringify({
				study_ids: [workspacesProvider.selectedWorkspace],
				patient_uids: [patient_uid],
			})
		})
	)

    const [hotkeysLoaded, setHotkeysLoaded] = useState(false)
	const [hotkeys, setHotkeys] = useRecoilState(hotkeysAtom)

	useEffect(() => {
		if(VisualizationManager.socket && enoughSpaceToPreload){
            VisualizationManager.socket.connect()
			VisualizationManager.setSocketListener(prefetchId, 'prefetch_data', 
				socketResponse => {			
					const prefetch_finished = socketResponse.finished
					const percentage = socketResponse.percentage 
					prefetched_size = socketResponse.prefetched_size 
					var waiting = socketResponse.waiting
					if(prefetchId === socketResponse.prefetch_id) {	
						if(prefetch_finished){
							setPrefetched(true)		
							prefetched_size = 0
						}
						else
							var prefetch_string  = (waiting < 0)
								? "Preloading data " + percentage.toFixed(1) +"%"
								:" Waiting to preload data... " + waiting + " patients ahead in queue"
							setPercentPrefetched(prefetch_string)	 
							if(!prefetch_finished)
							setTogglePrefetch(!togglePrefetch)
						VisualizationManager.removeSocketListener(prefetchId)								
					}
				})	

			new Promise(r => setTimeout(r, 100))
			VisualizationManager.prefetchSocket.emit("prefetch_data", 
				props.patientID,
				dataObjectID, 
				prefetchId,
				prefetched_size,
				false
			)
		}
	}, [ props.patientID, togglePrefetch])

	useOnMount(() => {
		const body = {
			data_object_id: dataObjectID
		}

		endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS, body)
			.then(annotationGroups => {
				setAnnotationGroups(annotationGroups)

				if (annotationGroups.length > 0) {
					setActiveAnnotationSession(annotationGroups[0])
				} else {
					createAnnotationGroup("Default Session").then(() => {					
						endpointProvider.post(LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS, body)
						.then(annotationGroups => {
							setAnnotationGroups(annotationGroups)			
							if (annotationGroups.length > 0) {
								setActiveAnnotationSession(annotationGroups[0])
							}
						})					
					})
				}
			})
			.catch(error => alert(error))
	})

	useEffect(() => {
		endpointProvider.post(LINKS.ACCOUNT.GET_KEYBOARD_SHORTCUTS, {})
			.then(hotkeyConfigs => {
				setHotkeys(loadHotkeysFromConfigs(hotkeyConfigs))
				setHotkeysLoaded(true)
			})
			.catch(e => console.error(e))
		}, [endpointProvider, setHotkeys])

	const updateHotkeyConfig = useCallback((newHotKeyConfigs, callback) => {
		const res = newHotKeyConfigs.map((config)=>{
			const newConfig = {id: config.id, action: config.action, triggers: config.triggers}
			if (config.data){ newConfig.data = config.data }
			return newConfig
			})
		endpointProvider.post(LINKS.ACCOUNT.UPDATE_KEYBOARD_SHORTCUTS,{"configs": res}).then(
			(s) => {callback(s)}).catch(e=>{console.error(e)})
	},[endpointProvider])

	const resetHotkeyConfig = useCallback((callback) => {
		endpointProvider.post(LINKS.ACCOUNT.RESET_KEYBOARD_SHORTCUTS,{}).then(
			(s)=> {callback(s)}).catch(e=>{console.error(e)})
	},[endpointProvider])

	const [startEndTimestamps, startEndTimestampsLoaded, getStartEndTimestamps] = useRequest(
		`${LINKS.DATA.PROFILING.GET_PATIENT_FILE_START_END_TIMESTAMPS.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		patient_id => ({
			method: "POST",
            cache: "no-cache",
            headers: {
                'content-type': "application/json",
                'Authorization': authProvider.token,
            },
            body: JSON.stringify({
                patient_ids: [patient_id],
                study_ids: [workspacesProvider.selectedWorkspace]
            })
		})
	)


	const [USER_INFO, userInfoLoaded, getUserInfo] = useRequest(
		`${LINKS.ACCOUNT.GET_CURRENT_USER.LINK}?parent_study_id=${workspacesProvider.selectedWorkspace}`,
		'JSON',
		() => ({
			method: "POST",
            cache: "no-cache",
            headers: {
                'content-type': "application/json",
                'Authorization': authProvider.token,
            },
            body: JSON.stringify({
                study_id: ''
            })
		})
	)

	const handleDownloadHDF5File = props.handleDownloadHDF5File

    useOnMount(() => {
		getFileExists(props.patientID).catch(err => console.error(err))
		getModalitiesUnion(props.patientID).catch(err => console.error(err))
        getModalitiesIntersection(props.patientID).catch(err => console.error(err))
        getMedicationsIntersection(props.patientID).catch(err => console.error(err))
        getStartEndTimestamps(props.patientID).catch(err => console.error(err))
		getUserInfo().catch(err => console.error(err))
    })

	const validStartEndTimestampsResponse = (timestamps, file) => {
		return (file in timestamps) && ('monitoring_start_time' in timestamps[file]) && ('monitoring_end_time' in timestamps[file])
	}

	useOnMount(() => getPrefetchInventory() )
	useOnMount(() => getPrefetchQueue() )


	useOnMount(() => {

		// This should be a GET....
		endpointProvider.post(LINKS.DATA.PROFILING.GET_LAYOUTS)
			.then(layoutGroups => {
				setLayoutGroups(layoutGroups)
				setLayoutLoaded(true)
			})
			.catch(error => alert("Error with getting layouts: " + error))
	})

	useOnMount(() => resetAnnotations())

	useEffect(() => {
		const isReady = 
			modalitiesLoaded &&
			patientModalitiesLoaded &&
			medicationsLoaded &&
			userInfoLoaded &&
            hotkeysLoaded &&
            dataObjectID &&
            patientID &&
			layoutsLoaded &&
			workspacesProvider.selectedWorkspace && 
			workspacesProvider.selectedWorkspace.length > 0 &&
			startEndTimestampsLoaded && validStartEndTimestampsResponse (startEndTimestamps, selectedFile.obj_name) &&
			selectedFile.obj_name && selectedFile.obj_name.length > 0 &&
			enoughSpaceToPreload

		if(inventoryLoaded && prefetchQueueLoaded && !modalCreated){
			let queue_size = 0
            if(prefetchQueue.directories.length > 0)
                queue_size = prefetchQueue.directories.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)

            let availableSize = (inventory.max_total_size) - ((inventory.current_size) + queue_size)
			inventory.directories = inventory.directories.concat(prefetchQueue.directories)
			if (( selectedFile?.filesize > availableSize) && (selectedFile?.preload_status.status === "0%")) { 
				createModal(<UnloadFilesModal
					escClose={false}
					clickOutsideClose={false}
					fileToPreload={selectedFile}
					inventory={inventory}
					startPrefetch = { () => setEnoughSpaceToPreload(true) 	}
				/>)
			}
			else {
				setEnoughSpaceToPreload(true)
			}
			setModalCreated(true)
		}

		if (isReady && fileExistsLoaded && fileExists.exists && enoughSpaceToPreload && !prefetched) {
			VisualizationManager.start(selectedFile.obj_name, startEndTimestamps[selectedFile.obj_name], modalities, patientModalities)
			DataQuery.init(authProvider.token, workspacesProvider.selectedWorkspace, workspacesProvider.selectedWorkspace, patientID, dataObjectID)
			Modalities.init()
			VisualizationManager.user_id = USER_INFO.email
			VisualizationManager.user_name = USER_INFO.first_name + ' ' + USER_INFO.last_name

			setTogglePrefetch(true)

			let { monitoring_start_time, monitoring_end_time, timezone } = startEndTimestamps[selectedFile.obj_name]

			if (!timezone) {
				timezone = "America/New_York"
			}

			setPatientFileInfo({
				fileStartDate: utcToZonedTime(new Date(monitoring_start_time), timezone), 
				fileEndDate: utcToZonedTime(new Date(demoLivePatient ? monitoring_start_time + 60 * 1000 : monitoring_end_time), timezone),
				patientId: patientID,
				dataObjectId: dataObjectID,
				patientModalities: patientModalities,
				timeZone: timezone
			})
		}
		setReady(isReady && prefetched)
	}, [patientID, dataObjectID, workspacesProvider.selectedWorkspace, modalitiesLoaded, startEndTimestampsLoaded, startEndTimestamps, userInfoLoaded, USER_INFO, hotkeysLoaded, medicationsLoaded, workspacesProvider.selectedWorkspace, modalities, fileExists, fileExistsLoaded, authProvider, prefetched, layoutsLoaded, setPatientFileInfo, patientModalitiesLoaded, patientModalities,inventoryLoaded, prefetchQueueLoaded, enoughSpaceToPreload])

    return ((ready && fileExistsLoaded && fileExists.exists)? 
	<VisualizationContext.Provider value={{patientID, selectedFile, siteName, patient_id, modalities, patientModalities, medications, hotkeys, setHotkeys, hotkeysLoaded, layoutsLoaded, 
		resetHotkeyConfig, updateHotkeyConfig, startEndTimestamps: startEndTimestamps[selectedFile.obj_name], closeVisualization: handleCloseVisualization, handleDownloadHDF5File, 
		dataObjectID
	}}>
		<VisualizationArea />
	</VisualizationContext.Provider>
	:

		<div style={{ display: "flex", width: "100vw", height: "100vh", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF" }}>
			<div style={{ flex: 0, display: "flex", width: "100%", alignItems: "center", justifyContent: "right", padding: "20px"}}>
				<MobergButton onClick={cancelPrefetch} shape={MobergButtonShape.SQUARE}>
					<MdIcons.MdOutlineClose size={MobergIconSize.LARGE} />
				</MobergButton>
			</div>
			
			<div style={{ flex: 1 }}>
				<div style={{marginBottom: "100px"}}>
					<h1 style={{ marginBottom: "20px" }}>{selectedFile.obj_name}</h1>
					<h5>{siteName} </h5>
					<h5>Patient: {patient_id} </h5>
				</div>

				<div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", background: "#FFFFFF" }}>
						{fileExistsLoaded ?
							fileExists.exists ?
								<>

									<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF", height: "100%" }}>
										<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
										<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Loading...</h2>
									</div>
									<div style={{ flex: 1 }}>
										<p>{inventoryLoaded ? <Check /> : <Close />} Preload Inventory</p>
										<p>{modalitiesLoaded && patientModalitiesLoaded ? <Check /> : <Close />} Modalities</p>
										<p>{medicationsLoaded ? <Check /> : <Close />} Medications</p>
										<p>{userInfoLoaded ? <Check /> : <Close />} User Info</p>
										<p>{hotkeysLoaded ? <Check /> : <Close />} Hotkeys</p>
										<p>{layoutsLoaded ? <Check /> : <Close />} Layouts</p>
										<p>{startEndTimestampsLoaded ? <Check /> : <Close />} File Start and End Timestamps</p>
										<p>{workspacesProvider.selectedWorkspace ? <Check /> : <Close />} Workspace</p>
										<p>{selectedFile.obj_name ? <Check /> : <Close />} Patient File</p>
										<p>{prefetched ? <Check /> : <Close />} {percentPrefetched} </p>
										<p style={{
											color: "#1890ff", cursor: "pointer", size: '22',
											visibility: (modalitiesLoaded && patientModalitiesLoaded && medicationsLoaded && userInfoLoaded &&
												hotkeysLoaded && layoutsLoaded && startEndTimestampsLoaded && workspacesProvider && ( isDevelopment || isDemo)  ) ? 'visible' : 'hidden'
										}}
											onClick={skipPrefetch}>Skip Prefetch Data</p>

									</div>
								</>
								:
								<div style={{ textAlign: "center" }}>
									<p>File still converting</p>
									<p style={{ color: "#1890ff", cursor: "pointer" }} onClick={handleCloseVisualization}>Go Back</p>
								</div>
							:
							<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#FFFFFF", height: "100%" }}>
								<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
								<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Checking file...</h2>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

const Check = () => <MdIcons.MdCheck color={"green"} size={18}/>
const Close = () => <MdIcons.MdClose color={"red"}  size={18}/>