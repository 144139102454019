import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import MUITable from "../../../../Components/MUITable/MUITable"
import { useState } from "react"
import { LINKS } from "../../../../Constants/BackendLinks"

const user_actions_columns = [
    { field: "email", flex: 1, headerName: "Email", visible: true },
    { field: "permission", flex: 0.5, headerName: "Permission", visible: true },
    { field: "performed_at", flex: 1, headerName: "Performed_at", visible: true },
]

export default function UserActionsModal() {
    const [selectedRows, setSelectedRows] = useState([])
	const queryProps = {
		queryKey: "user_actions",
		endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ALL_ACTIONS,
	}
	const preselectedProps = { selectedRows, setSelectedRows }

    return (
        <>
            <ModalHeader headerText="View User Actions" />
            <div style={{ minWidth: "800px" }}>
                <MUITable columns={user_actions_columns} preselectedProps={preselectedProps} {...queryProps} isCheckboxSelection={false} />
            </div>
        </>
    )
}