import { PageDataWorkerRequest, PageDataWorkerResponse, getPageDataWorker } from "../../../../../Providers/PageDataWorker"
import { getDataQuerySocket } from "../../../../../Providers/Socket"
import { ModalityDataSource } from "../Types/ModalityDataSource"
import { PageType } from "../Types/PageType"
import { SocketResponse } from "../Types/SocketResponse"

var pageId = 0

export abstract class Page<DataType> {
	readonly socketId: string
	readonly id: number
	readonly index: number
	readonly startTime: number
	public endTime: number
	public width: number
	readonly patientId: string
	readonly timeZoneOffsetMs: number

	public modalityDataSources: ModalityDataSource[]
	public data = new Map<string, DataType>()
	public renderCache = new Map<string, ImageBitmap>()

	// Data Object ID : ["ABP", "ICP", ...]
	// When working with multiple data sources, the same "modality" can be loaded from multiple sources.
	protected dataObjectsLoaded = new Map<number, Map<string, boolean>>()
	protected dataObjectsLoading = new Map<number, Map<string, boolean>>()

	constructor(startTime: number, endTime: number, index: number, width: number, modalityDataSources: ModalityDataSource[], patientId: string, timeZoneOffsetMs: number, socketId: string, ) {
		this.id = pageId++
		this.index = index
		this.startTime = startTime
		this.endTime = endTime
		this.width = width
		this.modalityDataSources = modalityDataSources
		this.patientId = patientId
		this.socketId = socketId
		this.timeZoneOffsetMs = timeZoneOffsetMs

		modalityDataSources.forEach(dataSource => {
			// Initialize the inner map for each data object Id
			if (!this.dataObjectsLoaded.has(dataSource.dataObjectId)) {
				this.dataObjectsLoaded.set(dataSource.dataObjectId, new Map())
			}

			if (!this.dataObjectsLoading.has(dataSource.dataObjectId)) {
				this.dataObjectsLoading.set(dataSource.dataObjectId, new Map())
			}

			// Each data source can be partially loaded, individual modalities.
			// If a new modality gets added to a display, we only need to request the data that is not loaded.
			const loaded = this.dataObjectsLoaded.get(dataSource.dataObjectId) ?? new Map()
			const loading = this.dataObjectsLoading.get(dataSource.dataObjectId) ?? new Map()

			this.dataObjectsLoaded.set(dataSource.dataObjectId, loaded.set(dataSource.modality, false))
			this.dataObjectsLoading.set(dataSource.dataObjectId, loading.set(dataSource.modality, false))
		})
	}

	abstract getType(): PageType

	get loaded() {
		return this.modalityDataSources.every(dataSource => this.dataObjectsLoaded.get(dataSource.dataObjectId)?.get(dataSource.modality) === true)
	}

	get loading() {
		return this.modalityDataSources.some(dataSource => this.dataObjectsLoading.get(dataSource.dataObjectId)?.get(dataSource.modality) === true)
	}

	socketEventName = () => "render_modalities"

	requestData(batchedDataSources: Map<number, string[]>) {
		const socket = getDataQuerySocket(this.socketId)

		batchedDataSources.forEach((modalities, dataObjectId) => {
			socket.emit(this.socketEventName(), this.patientId, dataObjectId, this.id, modalities, this.startTime + this.timeZoneOffsetMs, this.endTime + this.timeZoneOffsetMs, this.width)
		})
	}

	receiveSocketResponse(response: SocketResponse, resolve: (value: Page<DataType> | PromiseLike<Page<DataType>>) => void, reject: (reason?: any) => void) {
		const receivedModalities = Object.keys(response.pixels)

		receivedModalities.forEach(modality => {
			const loading = this.dataObjectsLoading.get(response.data_object_id) ?? new Map()
			const loaded = this.dataObjectsLoaded.get(response.data_object_id) ?? new Map()

			this.dataObjectsLoading.set(response.data_object_id, loading.set(modality, false))
			this.dataObjectsLoaded.set(response.data_object_id, loaded.set(modality, true))
		})

		const worker = getPageDataWorker()

		const listener = (event: PageDataWorkerResponse<DataType>) => {
			if (event.data.pageId === this.id && event.data.dataObjectId === response.data_object_id) {
				event.data.dataMap.forEach((data, modalityKey) => {
					this.data.set(modalityKey, data)
				})

				worker.removeEventListener("message", listener)

				let allDataObjectsLoaded = true

				this.dataObjectsLoaded.forEach(modalityMap => {
					modalityMap.forEach((isLoaded, modality) => {
						if (!isLoaded || this.data.get(modality) === undefined) {
							allDataObjectsLoaded = false
						}
					})
				})

				if (allDataObjectsLoaded) {
					resolve(this)
				}
			}
		}

		worker.addEventListener("message", listener)

		const request: PageDataWorkerRequest = { pageId: this.id, dataObjectId: response.data_object_id, type: this.getType(), pixels: response.pixels, timeZoneOffsetMs: this.timeZoneOffsetMs }

		worker.postMessage(request)

		setTimeout(() => {
			// Prevents the Promise from hanging indefinitely. Sometimes messages get dropped, but it's not a problem.
			reject("worker timed out")
		}, 3000)
	}

	public unload = () => {
		this.unloadWithoutClearingCache()
		this.clearRenderCache()
	}

	public unloadWithoutClearingCache = () => {
		this.data.clear()
		this.dataObjectsLoaded.clear()
		this.dataObjectsLoading.clear()
	}

	public clearRenderCache = () => {
		this.renderCache.forEach(bitmap => bitmap.close())
		this.renderCache.clear()
	}

	public load = () => {
		return new Promise<Page<DataType>>((resolve, reject) => {
			if (this.loaded || this.width === 0) {
				resolve(this)
				return
			}

			// Data Object ID: ["ABP", "ICP", ...]
			// Making one socket call with all of the modalities is more efficient than sending each one separately.
			// e.g. reading 7 modalities only requires setting everything up one time instead of 7 times.
			const batchedModalitiesByDataObjectId = new Map<number, string[]>()

			const dataSourcesToLoad = this.modalityDataSources
				.filter(dataSource => {
					const key = this.getModalityDataSourceKey(dataSource)
					return !this.dataObjectsLoaded.get(dataSource.dataObjectId)?.get(key) && !this.dataObjectsLoading.get(dataSource.dataObjectId)?.get(key)
				})

			dataSourcesToLoad.forEach(dataSource => {
				// Add to the list of modalities
				const existingList = batchedModalitiesByDataObjectId.get(dataSource.dataObjectId) ?? []
				batchedModalitiesByDataObjectId.set(dataSource.dataObjectId, [...existingList, dataSource.modality])

				// Update the loading state
				const existingLoadingModalities = this.dataObjectsLoading.get(dataSource.dataObjectId) ?? new Map()
				this.dataObjectsLoading.set(dataSource.dataObjectId, existingLoadingModalities.set(dataSource.modality, true))
			})

			const socket = getDataQuerySocket(this.socketId)

			batchedModalitiesByDataObjectId.forEach((modalities, dataObjectId) => {
				const listener = (socketResponse: any) => {
					if (socketResponse.page_id === this.id && socketResponse.data_object_id === dataObjectId) {
						this.receiveSocketResponse(socketResponse, resolve, reject)
						socket.off("render_modalities", listener)
					}
				}
	
				socket.on(this.socketEventName(), listener)
			})

			this.requestData(batchedModalitiesByDataObjectId)
		})
	}

	private getModalityDataSourceKey = (dataSource: ModalityDataSource) => `${dataSource.modality}-${dataSource.dataObjectId}`
}
