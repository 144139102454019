import { getDataQuerySocket } from "../../../../../Providers/Socket"
import { ModalityDataSource } from "../Types/ModalityDataSource"
import { ImagePage } from "./ImagePage"
import { PersystTrendsImagePageManager } from "./PersystTrendsImagePageManager"

export class PersystTrendsImagePage extends ImagePage {
	private pageManager: PersystTrendsImagePageManager

	constructor(startTime: number, endTime: number, index: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, socketId: string, pageManager: PersystTrendsImagePageManager) {
		super(startTime, endTime, index, width, height, patientId, modalityDataSources, timeZoneOffsetMs, socketId) 
		this.pageManager = pageManager
	}

    socketEventName = () => "render_persyst_trend"

    requestData(batch: Map<number, string[]>): void {
		const socket = getDataQuerySocket(this.socketId)
        
		batch.forEach((modalities, dataObjectId) => {
			socket.emit(
				this.socketEventName(), 
				this.patientId, 
				dataObjectId, 
				this.id,
				modalities[0],
				this.startTime,
				this.endTime,
				this.width,
				this.height,
				this.pageManager.isArtifactReductionEnabled()
			)
		})
    }
}