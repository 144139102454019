import { TimeBasedVisualizationConfig } from "./TimeBasedVisualizationConfig";

export interface CPPOptPlotJSON {
    viewDuration: number
}

export interface CPPOptPlotConfig extends TimeBasedVisualizationConfig {

}

export const DEFAULT_CPPOPT_PLOT_JSON: CPPOptPlotJSON = {
    viewDuration: 8 * 60 * 60 * 1000
}

export type CPPOptData = {
	CPPOpt: number | null
	LLA: number | null
	ULA: number | null
	yValues: (number | null)[]
	errors: (number | null)[]
	coefficients: (number | null)[]
}
