import { useRef, useState, createContext } from "react"
import { useOnMount } from "../Hooks/useOnMount"
import React from "react"
import { useResizeObserver } from "../Pages/Data/Visualize/DataReview/Hooks/useResizeObserver"

export type Dimensions = {
	height: number
	width: number
}

export const DimensionsContext = createContext<Dimensions>({ height: 0, width: 0 })

export const DimensionsProvider = (props: any) => {
	const domNode = useRef<HTMLDivElement>(null)
	const [dimensions, setDimensions] = useState<Dimensions>({ height: 0, width: 0 })

	function updateDimensions() {
		if (domNode.current) {
			setDimensions({ height: domNode.current.clientHeight, width: domNode.current.clientWidth })
		}
	}

	useOnMount(updateDimensions)
	useResizeObserver({
		ref: domNode,
		afterResize: updateDimensions
	})

	return (
		<div ref={domNode} style={{ height: "100%" }}>
			<DimensionsContext.Provider value={dimensions}>{props.children}</DimensionsContext.Provider>
		</div>
	)
}

export const withDimensions = (element: JSX.Element) => (
	<DimensionsProvider>
		{element}
	</DimensionsProvider>
)
