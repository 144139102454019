
import { Empty } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import * as MdIcons from "react-icons/md";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from 'styled-components';
import { ModalFooter, Scrollbar, RequiredField, VisualizationInput, ElementConfigureDiv, ModalityConfigurationDiv } from "../../../../Constants/StyledComponents";
import { useLayoutService } from "../../../../Hooks/useLayoutService";
import { useOnMount } from "../../../../Hooks/useOnMount";
import { editedLayoutGroupAtom, madeChangesToEditedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { modalityUpdates, useModalitiesProvider } from "../../../../Providers/ModalitiesProvider";
import { ChromePicker } from "react-color";
import { modalityGraphGroupConfigAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/ModalityGraphGroup';
import { ModalTitleAndCloseButton } from '../../../../Pages/Data/Visualize/DataReview/Components/Modals/ModalTitleAndCloseButton';
import { MobergButton, MobergButtonShape, MobergButtonVariant } from '../../../../Components/MobergButton/MobergButton';
import { MobergFontSize } from '../../../../Components/MobergFont/MobergFont';
import { MobergTheme } from '../../../../Components/MobergThemes/MobergColors';
import ConfirmationModal from '../../../../Components/ConfirmationModal/ConfirmationModal';
import { MobergDropdown } from '../../../../Components/MobergDropdown/MobergDropdown';
import { currentPatientFileInfoAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile';
import { MobergRow } from '../../../../Moberg';
import VisualizationManager from '../../VisualizationManager';
import { DataSource } from '../../../../Pages/Data/Visualize/DataReview/Types/DataSource'
import { WINDOW_TIME_PRESETS } from '../../Viewport/Components/XAxis';

export function ConfigureWindowModal({ layoutId, windowId }) {
    const { createModal, close } = useModalProvider()
    const { modifyLayoutGroup } = useLayoutService()
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
    const setTimeSeriesGraphGroup = useSetRecoilState(modalityGraphGroupConfigAtom({ windowId, layoutId }))
    const { dataObjectId } = useRecoilValue(currentPatientFileInfoAtom)

    const modalitiesProvider = useModalitiesProvider()
    const madeChanges = useRecoilValue(madeChangesToEditedLayoutGroupAtom)

    const [editedLayoutGroup, setEditedLayoutGroup] = useRecoilState(editedLayoutGroupAtom)
    const [selectedGraphId, setSelectedGraphId] = useState()
    const [colorPickerTraceIndex, setColorPickerTraceIndex] = useState()
    const [waitingForNetwork, setWaitingForNetwork] = useState(false)
    const [error, setError] = useState()
    const colorPickerBlockRefs = useRef(new Map())
    const colorPickerRef = useRef()

    const currentLayout = editedLayoutGroup?.layouts.find(layout => layout.id === layoutId)
    const currentWindow = currentLayout?.areas.find(({ area }) => area === windowId)
    const currentGraph = !selectedGraphId && (currentWindow?.props?.graphs ?? []).length > 0
        ? currentWindow.props.graphs[0]
        : currentWindow?.props.graphs.find(graph => selectedGraphId && graph.id === selectedGraphId)

    const sortedModalities = [...modalitiesProvider.modalities].sort();

    const mobergDropdownOptions = sortedModalities.filter(modality => !modality.startsWith('EEG')).map(name => ({
        label: name,
        value: {
            name,
            dataKey: name,
            dataSource: DataSource.CURRENT_PATIENT,
        },
        highlight: VisualizationManager.patientModalities.includes(name)
    }))

    // TODO: Remove this when the Run Analysis changes have merged (CONN-5076)
    mobergDropdownOptions.push({
        label: "PRx",
        value: {
            name: "PRx",
            dataKey: "PRx_na",
            dataSource: DataSource.CPPOPT_ANALYSIS,
        }
    })

    mobergDropdownOptions.push({
        label: "CPPOpt",
        value: {
            name: "CPPOpt",
            dataKey: "CPPOpt,Composite,SampleSeries",
            dataSource: DataSource.CPPOPT_ANALYSIS,
            isCompositePart: true,
            compositeIndex: 0
        }
    })

    useOnMount(() => {
        modalitiesProvider.update(modalityUpdates.MODALITIES)
    })

    useEffect(() => {
        if (!selectedGraphId && currentWindow?.props.graphs.length > 0) {
            setSelectedGraphId(currentWindow.props.graphs[0].id)
        }

    }, [currentWindow.props.graphs, selectedGraphId])


    const toggleCurrentGraph = (graph) => {
        if (selectedGraphId === graph.id) {
            return
        }

        setSelectedGraphId(graph.id)
    }

    function addGraph() {

        const newGraph = {
            id: `graph-${new Date(Date.now()).toISOString()}`,
            name: 'New graph',
            traces: [],
            min: 0,
            max: 100
        }

        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs ?? [], newGraph]
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    function removeGraph(graph) {
        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs?.filter(g => g !== graph)] ?? []
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    const modalBodyGraph = (
        <div style={{ position: 'relative', minWidth: '231px', }}>
            <div style={{ height: "100%", display: 'flex', }}>
                <Col md={2} style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative", width: '100%' }}>

                    <p id='AddGraphButton' style={{ fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: '600', fontSize: '16px', lineHeight: '150%', textAlign: 'left', marginLeft: '12px', cursor: 'pointer' }}>
                        Graphs
                    </p>

                    <Scrollbar
                        style={{
                            overflowY: "scroll",
                            overflow: "hidden",
                            overflowX: 'auto',
                            height: '562px',
                            width: '235px',
                            justifyContent: "left",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        {currentWindow?.props.graphs.length > 0 ?
                            currentWindow.props.graphs.map(graph => (
                                <div style={{
                                    display: "flex", height: "32px", background: graph.id === currentGraph?.id ? "#E5F5FD" : "white", borderRadius: "3px", width: "95%", marginLeft: "12px",
                                }}>

                                    <button
                                        onClick={() => toggleCurrentGraph(graph)}
                                        style={{ flex: 1, margin: 0, border: "none", paddingLeft: "15px", background: "none" }}> {graph.name} </button>
                                    <RemoveButton
                                        style={{
                                            width: "28px",
                                            height: "28px",
                                            alignSelf: "center",
                                            marginRight: "2px",

                                        }}
                                        onClick={() => removeGraph(graph)}
                                    >
                                        <MdIcons.MdClose size={18} style={{ cursor: "pointer", alignSelf: "center", }} />
                                    </RemoveButton>
                                </div>

                            ))
                            : <Empty description={<p style={{ fontFamily: 'Source Sans Pro', fontSize: '26px' }}>No Graphs</p>} />}
                        <MobergButton
                            onClick={addGraph}
                            shape={MobergButtonShape.FULL_WIDTH}
                            theme={MobergTheme.BLUE}
                            variant={MobergButtonVariant.OUTLINE}
                            style={{
                                width: "95%", marginLeft: "12px", marginTop: "18px",
                            }}
                        >Add Graph
                        </MobergButton>
                    </Scrollbar>

                </Col>
            </div>
        </div>
    )

    function saveChanges() {
        setWaitingForNetwork(true)
        modifyLayoutGroup(editedLayoutGroup.id, editedLayoutGroup)
            .then(close)
            .catch(err => {
                setWaitingForNetwork(false)
                setError(err)
            })
        setUneditedLayoutGroup(editedLayoutGroup)
        setTimeSeriesGraphGroup(previous => ({ ...previous, ...currentWindow.props }))
    }

    function closeConfigurationModal() {
        if (madeChanges) {
            createModal(
                <ConfirmationModal
                    title={"Discard Changes?"}
                    description={"You have unsaved changes. Are you sure you want to close without saving?"}
                    confirmButton={{ text: "Close without saving", theme: MobergTheme.RED }}
                    afterClose={close}
                />
            )
        } else {
            close()
        }
    }

    function updateGraphProperty(property, newValue) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            graphs: window.props.graphs.map(graph => {
                                                if (graph.id === currentGraph?.id) {
                                                    return {
                                                        ...graph,
                                                        [property]: newValue
                                                    }
                                                }
                                                return graph
                                            })
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function updateDisplayPageSize(newValue) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            viewDuration: newValue
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function addModality() {
        const newTraces = [...currentGraph.traces,
        {
            dataKey: modalitiesProvider.modalities[0],
            name: modalitiesProvider.modalities[0],
            color: "#000",
            units: "?",
            dataSource: DataSource.CURRENT_PATIENT
        }
        ]

        // This technically has the potential for bugs. It should be relatively safe, but watch out for that.
        updateGraphProperty("traces", newTraces)
    }

    function removeModalityAt(index) {
        const newTraces = [...currentGraph.traces]
        newTraces.splice(index, 1) // be careful. Splice returns the elements that were removed.

        // This technically has the potential for bugs. It should be relatively safe, but watch out for that.
        updateGraphProperty("traces", newTraces)
    }

    function modalityChanged(traceIndex, traceConfig) {
        const newTraces = [...currentGraph.traces]

        const isCompositePart = traceConfig.isCompositePart

        const newTrace = {
            ...newTraces[traceIndex],
            name: traceConfig.name,
            dataKey: traceConfig.dataKey,
            dataSource: traceConfig.dataSource,
            isCompositePart
        }

        if (isCompositePart) {
            newTrace.compositeIndex = traceConfig.compositeIndex
        }

        newTraces[traceIndex] = newTrace

        updateGraphProperty("traces", newTraces)
    }

    const handleColorChange = (traceIndex, color) => {
        const newTraces = [...currentGraph.traces]
        newTraces[traceIndex] = { ...newTraces[traceIndex], color }
        updateGraphProperty("traces", newTraces)
    }

    function toggleColorPicker(event, index) {
        const node = colorPickerRef.current

        if (node.style.display === "block" && index === colorPickerTraceIndex) {
            node.style.display = "none"
            return
        }

        setColorPickerTraceIndex(index)

        if (node) {
            node.style.display = "block"
            node.style.top = `${event.target.getBoundingClientRect().bottom}px`
            node.style.left = `${event.target.getBoundingClientRect().right}px`
        }
    }

    function addColorPickerBlockRef(node, key) {
        colorPickerBlockRefs.current.set(key, node)
    }

    useEffect(() => {
        const clickHandler = (event) => {
            let clickedOutside = true

            colorPickerBlockRefs.current.forEach((node) => {
                if (node?.contains(event.target)) {
                    clickedOutside = false
                }
            })

            if (colorPickerRef.current?.contains(event.target)) {
                clickedOutside = false
            }

            if (clickedOutside && colorPickerRef.current) {
                colorPickerRef.current.style.display = "none"
            }
        }

        document.addEventListener("click", clickHandler)
        return () => document.removeEventListener("click", clickHandler)
    })

    return (<>
        <ModalTitleAndCloseButton title={"Configure graphs"} closeFunction={closeConfigurationModal} />

        <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '5px 0px 5px' }}>
            <ElementConfigureDiv style={{ width: '250px', padding: '10px' }}>
                Initial Page Size

                <MobergDropdown
                    selectedValue={currentWindow?.props?.viewDuration}
                    onChange={value => updateDisplayPageSize(parseInt(value))}
                    options={WINDOW_TIME_PRESETS
                        .filter(preset => preset.label !== "Page Size")
                        .map(preset => ({ label: preset.label, value: preset.time }))}
                />
            </ElementConfigureDiv>

            <div id='ConfigureWindowContent' style={{ display: 'flex', height: '100%', overflow: 'auto' }}>
                {modalBodyGraph}

                {!currentGraph
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <p style={{ fontFamily: 'Source Sans Pro', fontWeight: 500, fontSize: '16px', textAlign: 'center', color: '#000000' }}>
                            Select a Graph to Customize
                        </p>
                    </div>
                    :
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', paddingBottom: '10px', width: '100%', }}>

                            <div style={{ display: 'flex', height: '100%', minWidth: '600px', maxHeight: '614px' }}>
                                <div style={{ background: '#FFFFFF', borderLeft: '2px solid #DDDDDD', height: '100%', margin: '0px 10px', display: 'flex', justifyContent: 'center', alignItems: currentGraph === '' ? 'center' : 'unset', flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: '10px' }}>
                                        <p style={{ fontFamily: 'Source Sans Pro', fontWeight: 600, fontSize: '16px', textAlign: 'center', color: '#000000', marginRight: '10px' }}>
                                            Element
                                        </p>

                                        <Scrollbar style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
                                            <ElementConfigureDiv>
                                                <RequiredField>
                                                    Graph title
                                                </RequiredField>
                                                <VisualizationInput id='Graph Title' name="ConfigureElementInput" type='text' defaultValue={currentGraph?.name} value={currentGraph?.name} onChange={(e) => updateGraphProperty('name', e.target.value)} />
                                            </ElementConfigureDiv>
                                            <hr style={{ color: '#a9a9a9', margin: '0px', border: '2px solid #a9a9a9' }} />

                                            <p style={{ color: '#293241', fontFamily: 'Source Sans Pro', fontWeight: 700, fontSize: '16px', textAlign: 'center', marginBottom: '5px', marginTop: '10px' }}>
                                                Modalities
                                            </p>

                                            <ModalityConfigurationDiv>
                                                {currentGraph?.traces.length > 0 ?
                                                    <>
                                                        {currentGraph.traces.map((trace, index) => {
                                                            return (
                                                                <ElementInputDiv key={trace.label} style={{
                                                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center'
                                                                }}>

                                                                    <div style={{ backgroundColor: "white", display: "flex", flexDirection: "row", alignItem: "center", justifyContent: "space-between" }}>
                                                                        <MobergDropdown
                                                                            options={mobergDropdownOptions}
                                                                            onChange={traceConfig => modalityChanged(index, traceConfig)}
                                                                            selectedValue={{
                                                                                name: trace.name,
                                                                                dataKey: trace.dataKey,
                                                                                dataSource: trace.dataSource
                                                                            }}
                                                                            equals={(a, b) => (
                                                                                a.name === b.name &&
                                                                                a.dataKey === b.dataKey &&
                                                                                a.dataSource === b.dataSource
                                                                            )}
                                                                            width={150}
                                                                        />
                                                                        <div>
                                                                            <div
                                                                                onClick={event => toggleColorPicker(event, index)}
                                                                                style={{
                                                                                    width: '25px', height: '25px', backgroundColor: trace.color, border: '0px solid #5A6679', borderRadius: '25px', cursor: 'pointer', marginTop: "6px", marginLeft: "10px"
                                                                                }}
                                                                                ref={ref => addColorPickerBlockRef(ref, index)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <RemoveButton
                                                                        onClick={() => removeModalityAt(index)}

                                                                    >
                                                                        <MdIcons.MdClose size={'18px'} style={{
                                                                            alignSelf: 'center',
                                                                        }}

                                                                        />
                                                                    </RemoveButton>
                                                                </ElementInputDiv>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <p>No Modalities</p>
                                                }
                                                <div id={`ColorPickerWindow`} ref={colorPickerRef} style={{ position: 'fixed', display: 'none' }} >
                                                    <ChromePicker color={colorPickerTraceIndex !== undefined && currentGraph?.traces[colorPickerTraceIndex]?.color} onChange={color => handleColorChange(colorPickerTraceIndex, color.hex)} />
                                                </div>
                                            </ModalityConfigurationDiv>
                                            <MobergButton onClick={addModality}
                                                theme={MobergTheme.BLUE}
                                                variant={MobergButtonVariant.OUTLINE}
                                                style={{
                                                    width: "150px", marginLeft: "17px",
                                                }}
                                            >Add Modality
                                            </MobergButton>

                                        </Scrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>

        <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

        <ModalFooter>
            <MobergRow gap="8px" horizontalAlign="right">
                {waitingForNetwork && (
                    <div style={{ display: "flex", alignItems: "center" }}> Please wait... </div>
                )}

                {!waitingForNetwork && error && (
                    <div style={{ display: "flex", alignItems: "center", color: "red" }}> {error} </div>
                )}

                <MobergButton
                    disabled={!madeChanges || waitingForNetwork}
                    onClick={saveChanges}
                    theme={MobergTheme.BLUE}
                    variant={MobergButtonVariant.FILLED}
                    fontSize={MobergFontSize.LARGE}
                    shape={MobergButtonShape.WIDE}
                >
                    Save
                </MobergButton>
            </MobergRow>
        </ModalFooter>
    </>)
}

export default ConfigureWindowModal;

const ElementInputDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 30px;
    p {
        font-family: Source Sans Pro;
        font-weight: 500;
        font-size: 16px;
        color: #293241;
    }
`;

const RemoveButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;
height: 30px;
width: 30px;
border-radius: 25px;
&:hover {
    cursor: pointer;
    background-color: #EFF1F4;
}
`;