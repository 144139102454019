import { debounce } from "lodash"
import { getDataQuerySocket } from "../../../../../Providers/Socket"
import { PersystTrendsTitlesAndLabelsSocketResponse } from "../Types/SocketResponse"
import { ImagePage } from "./ImagePage"
import { PersystPageManager } from "./PersystPageManager"
import { PersystTrendsImagePage } from "./PersystTrendsImagePage"
import { ImagePageManagerConfig } from "./ImageTimeSeriesPageManager"
import { ModalityDataSource } from "../Types/ModalityDataSource"

export type PersystTrendsPageManagerConfig = ImagePageManagerConfig & {
	artifactReductionEnabled: boolean
}
export class PersystTrendsImagePageManager extends PersystPageManager<PersystTrendsPageManagerConfig> {
	protected getNewPage(startTime: number, endTime: number, index: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, socketId: string): ImagePage {
		return new PersystTrendsImagePage(startTime, endTime, index, width, height, patientId, modalityDataSources, timeZoneOffsetMs, socketId, this)
	}

	public isArtifactReductionEnabled = () => this.config.artifactReductionEnabled

	// Debounce allows us to call this function a lot and not worry about sending a butt ton of requests.
	protected requestTitlesAndLabels = debounce((height: number, width: number, panel: string) => {
		this.setRequestDimensions({ height, width, panel })

		const eventName = "render_persyst_trend_labels"
		const socket = getDataQuerySocket(this.config.windowId)

		const listener = (event: PersystTrendsTitlesAndLabelsSocketResponse) => {
			if (event.height !== height || event.width !== width) {
				socket.off(eventName, listener)
				return
			}

			const data = {
				titles: event.titles,
				labels: event.labels,
			}

			this.setTitlesAndLabelsCache({
				panel,
				dimensions: { height, width },
				data: data,
			})

			socket.off(eventName, listener)
			this.setRequestDimensions(null)
			this.onTitlesAndLabelsLoaded()
		}

		socket.on(eventName, listener)
		socket.emit(eventName, this.config.patientId, this.config.dataObjectId, panel, width, 50, height)
	}, 200)
}
