import React, { CSSProperties } from "react"
import { MobergFontFamily, MobergFontSize } from "../MobergFont/MobergFont"

type MobergTextProps = {
    fontSize?: MobergFontSize
    fontFamily?: MobergFontFamily
    fontWeight?: string
    style?: CSSProperties
}

export const MobergText: React.FC<MobergTextProps> = ({ fontSize=MobergFontSize.REGULAR, fontFamily=MobergFontFamily.REGULAR, fontWeight="regular", style, children }) => {
    return (
        <span style={{ fontSize, fontFamily, fontWeight, ...style }}>
            {children}
        </span>
    )
}
