import { ModalityDataSource } from "../Types/ModalityDataSource";
import { EEGPage } from "./EEGPage";
import { TimeSeriesPageManager } from "./TimeSeriesPageManager";

export class EEGTimeSeriesPageManager extends TimeSeriesPageManager<EEGPage> {

    protected generatePages = (startTime: number, endTime: number, pageDuration: number, pageWidth: number, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, startAtIndex=0) => {
		const pages = []

		if (!this.config) {
			return []
		}

		let start = startTime
		let end = startTime + pageDuration
		let idx = startAtIndex

		while (start < endTime) {
			const width = end < endTime ? pageWidth : Math.floor(((endTime - start) * pageWidth) / pageDuration)
			const new_page = new EEGPage(start, end < endTime ? end : endTime, idx++, width, modalityDataSources, this.config.patientId, timeZoneOffsetMs, this.config.windowId)
			
			pages.push(new_page)

			start += pageDuration
			end += pageDuration
		}

		return pages
	}
}