import React, { useRef, useContext, useState } from "react"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { DimensionsContext, Dimensions, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { selectedAnnotationAtom, annotationsAtom } from "../../../../Atoms/Annotations"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useResizeObserver } from "../../../../Hooks/useResizeObserver"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { ParsedJSONOptions } from "../../../../Types/ParsedJSON"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { D3PersystEEGWindow } from "../D3/D3PersystEEGWindow"
import { PersystEEGImagePageManager } from "../../../../Data/PersystEEGImagePageManager"
import { PersystEEGWindowSettings } from "./PersystEEGWindowSettings"
import { PersystEEGWindowConfig, PersystEEGWindowJSON } from "../../../../Types/PersystWindow"
import { persystEEGAtom } from "../../../../Atoms/PersystWindow"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { useD3LiveRecordingEndDate } from "../../../../Hooks/useLiveRecordingTimes"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"

export type PersystEEGWindowProps = {
	windowId: string
	json: PersystEEGWindowJSON
}

export const PersystEEGWindow = (props: PersystEEGWindowProps) => { 
	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<div style={{ flex: 0 }}>
				<PersystEEGWindowSettings windowId={props.windowId} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<PersystEEGVisualization {...props} />
				</DimensionsProvider>
			</div>
		</div>
		
	)
}

export const PersystEEGVisualization = (props: PersystEEGWindowProps) => {
	const { createModal } = useModalProvider()
	const container = useRef<HTMLDivElement>(null)
	const dimensions = useContext(DimensionsContext)

	const { fileStartDate, fileEndDate, dataObjectId, patientId } = useRecoilValue(currentPatientFileInfoAtom)

	const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
	const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)
	const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)

	const [config, setRootConfig] = useRecoilState(persystEEGAtom(visualizationId))
    const [d3Controller, setD3Controller] = useState<D3PersystEEGWindow>()

    const annotations = useRecoilValue(annotationsAtom)
    const { inProgressAnnotation } = useInProgressAnnotation(props.windowId)

	const [viewStart, viewEnd] = config.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()
	const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, fileStartDate, fileEndDate)

    const getParsedJSON = (options?: ParsedJSONOptions): PersystEEGWindowConfig => {
		const base = {...config}

		if (options?.initialize) {
			Object.assign(base, props.json)
		}

        return {
			...base,
			id: props.windowId,
			viewScale: viewScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration ?? 30 * 1000 }),
			fileScale,
			dataObjectId,
			patientId,
			dimensions,
            annotations,
            inProgressAnnotation,
			timelineController
		}
    }

	// Update the D3 component when the React component re-renders
	if (d3Controller) {
		d3Controller.updateConfig(getParsedJSON())
	}

	useResizeObserver({
		ref: container,
		onResize: (dimensions: Dimensions) => {
			d3Controller?.updateConfig({...getParsedJSON(), dimensions})
		},
	})

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [
            hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
        ]
    })

	useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            viewDuration,
			montage: config.montage
        },
		fileScale
    })

	useOnMount(() => {
		if (!container.current) {
			return
		}

		const parsedJSON = getParsedJSON({ initialize: true })
		const reactCallbacks = { createModal, setRootConfig, setSelectedAnnotation, setLinkedWindowsTimelineController }
		const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.PERSYST_EEG, new PersystEEGImagePageManager())
		const persystWindow = new D3PersystEEGWindow(container.current, parsedJSON, pageManager, reactCallbacks)

        setD3Controller(persystWindow)
		setRootConfig(parsedJSON)

		return () => {
			persystWindow.unmount()
		}
	})

	useD3LiveRecordingEndDate({
		fileScale,
		d3Controller,
		liveModeEnabled: config.liveModeEnabled
	})

	return <div ref={container} style={{ height: "100%" }} />
}