import { atom } from "recoil"

type PatientFileInfo = {
    fileStartDate: Date
    fileEndDate: Date
    patientId: string
    dataObjectId: string
    patientModalities: string[],
    timeZone: string
}

export const currentPatientFileInfoAtom = atom<PatientFileInfo>({
    key: "currentPatientFileInfo",
    default: {
        fileStartDate: new Date(0), // file times are local to the time zone of the patient file, NOT UTC
		fileEndDate: new Date(0),
        patientId: "?",
        dataObjectId: "",
        patientModalities: [],
        timeZone: "America/New_York"
    }
})

export const livePatientFileEndTimeAtom = atom<any>({
    key: "livePatientFileEndTime",
    default: new Date(0)
})