import { useContext, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { D3ModalityGraphGroup } from "../D3/D3ModalityGraphGroup"
import { annotationsAtom, selectedAnnotationAtom } from "../../../../Atoms/Annotations"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import React from "react"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { GraphType } from "../../../../../../../../Enums/GraphType"
import { useInProgressAnnotation } from "./useInProgressAnnotation"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { WindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/WindowSettings"
import { modalityGraphGroupConfigAtom } from "../../../../Atoms/ModalityGraphGroup"
import { ModalityGraphGroupConfig, ModalityGraphGroupConfigJSON } from "../../../../Types/ModalityGraphGroup"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import {  linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { getVisibleGraphs } from "../visibleGraphs"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { useD3LiveRecordingEndDate } from "../../../../Hooks/useLiveRecordingTimes"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { DataSource } from "../../../../Types/DataSource"

type ModalityGraphGroupProps = {
	json: ModalityGraphGroupConfigJSON
	area: string
}

export function ModalityGraphGroup(props: ModalityGraphGroupProps) {
	return (
		<div style={{height: "100%", display: "flex", flexDirection: "column"}} >
			<div style={{flex: 0}}>
				<WindowSettings visualizationArea={props.area} />
			</div>
			<div style={{flex: 1}}>
				<DimensionsProvider>
					<ModalityGraphGroupD3Wrapper {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

function ModalityGraphGroupD3Wrapper(props: ModalityGraphGroupProps) {
	// Providers
	const dimensions = useContext(DimensionsContext)
	const { createModal } = useModalProvider()

	// Recoil State
	const { fileStartDate, fileEndDate, patientId, dataObjectId, patientModalities, timeZone } = useRecoilValue(currentPatientFileInfoAtom)
	const { inProgressAnnotation } = useInProgressAnnotation(props.area)
	const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)
	const annotations = useRecoilValue<Annotation[]>(annotationsAtom)
	const layoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: layoutId as string, windowId: props.area }
	const atom = modalityGraphGroupConfigAtom(visualizationId)
	const [config, setRootConfig] = useRecoilState(atom)

	const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)
	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
	

	// Local State
	const domNode = useRef(null)
	const [group, setGroup] = useState<D3ModalityGraphGroup | undefined>()
	const visibleGraphs = getVisibleGraphs(props.json.graphs, patientModalities, config.hideEmptyGraphs)

	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, fileStartDate, fileEndDate)

	if (group && dimensions.height && dimensions.width && Object.keys(config).length > 0) {
		group.updateConfig({ ...config, annotations, inProgressAnnotation, dimensions, timelineController, viewScale, patientModalities, fileScale })
	}

	function getDataObjectId(dataSource: DataSource) {
		switch (dataSource) {
			case DataSource.CURRENT_PATIENT:
				return Number(dataObjectId)
			case DataSource.CPPOPT_ANALYSIS:
				return 999999
			default:
				return Number(dataObjectId)
		}
	}

	useOnMount(() => {
		const node = domNode.current
		const reactCallbacks = { createModal, setRootConfig, setSelectedAnnotation, setLinkedWindowsTimelineController }

		if (node === null) {
			throw new Error("time series group did not initialize as expected.")
		}

		const parsedJSON: ModalityGraphGroupConfig = {
			id: props.area,
			patientId,
			dataObjectId: dataObjectId,
			viewScale,
			graphs: props.json.graphs.map((graph, index) => ({
				...graph, 
				traces: graph.traces.map(trace => ({
					...trace,
					dataObjectId: getDataObjectId(trace.dataSource)
				})),
				type: GraphType.MODALITY_GRAPH, 
				id: index.toString(), 
				offset: 0, 
				height: 0, 
				width: 0, 
				xScale: viewScale
			})) ?? [],
			playbackSpeed: props.json.playbackSpeed ?? 1,
			annotations,
			inProgressAnnotation,
			dimensions: { width: 0, height: 0 },
			fileScale,
			hideEmptyGraphs: false,
			patientModalities,
			isLinked: config.isLinked ?? false,
			timelineController,
			timeZone,
			liveModeEnabled: config.liveModeEnabled ?? false
		}

		const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.TIME_SERIES_GROUP, new TimeSeriesPageManager())
		const d3Group = new D3ModalityGraphGroup(node, parsedJSON, pageManager, reactCallbacks)

		setGroup(d3Group)
		setRootConfig(parsedJSON)

		return () => {
			d3Group.unmount()
		}
	})

	useD3UpdateVisibleTraces({
		d3Controller: group,
		windowId: props.area,
	})

	useD3KeyboardShortcuts({
		d3Controller: group,
		windowId: props.area,
		shortcuts: [
			hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.ZOOM_IN,
			hotkeyActions.ZOOM_OUT,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
		]
	})

	useD3CheckReloadData({
		d3Controller: group,
		clearDataIfChanges: {
			viewDuration,
			modalities: [...new Set(config.graphs?.flatMap(graph => graph.traces.map(trace => trace.dataKey)))].sort(),
		},
		clearRenderCacheIfChanges: {
			dimensions,
			visibleGraphs: visibleGraphs.length,
			traceColors: [...new Set(config.graphs?.flatMap(graph => graph.traces.map(trace => trace.color)))].sort()
		},
		fileScale
	})

	useD3LiveRecordingEndDate({ 
		d3Controller: group,
		liveModeEnabled: config.liveModeEnabled,
		fileScale
	})
	
	// This is the last taste of React. From here, we use D3
	return <div ref={domNode} style={{ height: "100%" }} />
}
