import React, { createContext, useState, useContext } from 'react';
import { useEndpointProvider } from './EndpointProvider';
import { LINKS } from "../Constants/BackendLinks";
import { UpdateDispatcher } from './UpdateDispatcher';

// Create a context object
const AnnotationsContext = createContext()

export const annotationModalityUpdates = {
    MODALITIES_UNION: "modalitiesUnion"
}

// Create a provider component
export const AnnotationsProvider = ({ children }) => {
    const endpointProvider = useEndpointProvider()

    // this useState stores all modality configs for an annotation in the form: [{label: 'ABP_Mean', name: 'ABP,Dias,Numeric,Float,IntelliVue,data', type: 'Trends', checked: false}]
    const [annotationModalities, setAnnotationModalities] = useState([])

    // boolean useState becomes true when there are unsaved edits/changes for modalities
    const [unsavedModalityEdits, setUnsavedModalityEdits] = useState(false)

    // this useState stores unedited modalities for an annotation in the form: [{label: 'ABP_Mean', name: 'ABP,Dias,Numeric,Float,IntelliVue,data', type: 'Trends', checked: false}]
    const [uneditedModalitiesProvider, setUneditedModalitiesProvider] = useState([])

    // boolean value. Keeps track of if the selected annotation is a point annotation
    const [isPointAnnotation, setIsPointAnnotation] = useState(false)

    // stores raw, unformatted modalities returned from the get_modalities_union_v2 endpoint
    const [modalitiesUnion, setModalitiesUnion] = useState([])

    /**
    * Gets modalities for each file (modalities union)
    *
    * @async
    * @returns {Dictionary} - A dictionary in the form: {ABP_Dias: {file_name: 'ABP,Dias,Numeric,Float,IntelliVue,data}}
    */
    async function update(...updates) {
        console.log('UPDATED ANNOTATION MODALITIES')
        const initState = {
            [annotationModalityUpdates.MODALITIES_UNION]: modalitiesUnion
        }
        const dispatcher = new UpdateDispatcher(updates, initState, annotationModalityUpdates)

        dispatcher.dispatch(annotationModalityUpdates.MODALITIES_UNION,
            () => endpointProvider.post(LINKS.DATA.TRIALS.GET_MODALITIES_UNION_V2, {}),
            modalities => setModalitiesUnion(modalities))
    }

    /**
    * A function handles resetting everything in AnnotationsProvider when closing the Edit Annotation modal or deleting the selected annotation
    */
    function resetAnnotationsProvider() {
        setAnnotationModalities([])
        setUnsavedModalityEdits(false)
        setUneditedModalitiesProvider([])
    }

    return (
        <AnnotationsContext.Provider value={{ annotationModalities, setAnnotationModalities, unsavedModalityEdits, setUnsavedModalityEdits, uneditedModalitiesProvider, setUneditedModalitiesProvider, isPointAnnotation, setIsPointAnnotation, modalitiesUnion, resetAnnotationsProvider, update }}>
            {children}
        </AnnotationsContext.Provider>
    )
}

export const useAnnotationsProvider = () => {
    return useContext(AnnotationsContext)
}