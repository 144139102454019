import React from "react"
import { BaseVisualizationConfigureModal } from "./BaseVisualizationConfigureModal"
import { ElementConfigureDiv } from "../../../../../../Constants/StyledComponents"
import { WAVEFORM_WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { EEGMontageConfigJSON } from "../../Types/EEGMontageConfig"
import { eegMontageConfigAtom } from "../../Atoms/EEGMontage"
import { EEG_MONTAGES, EEG_LOW_FILTERS, EEG_HIGH_FILTERS, EEG_NOTCH_FILTERS, EEG_SENSITIVITY } from "../../Types/EEG"
import { useEditWindow } from "./useEditWindow"
import { MobergDropdown } from "../../../../../../Components/MobergDropdown/MobergDropdown"

type ConfigureEEGModalProps = {
	layoutId: string
	windowId: string
	escClose?: boolean
	clickOutsideClose?: boolean
}

export const ConfigureEEGModal = (props: ConfigureEEGModalProps) => {
	const atom = eegMontageConfigAtom({ windowId: props.windowId, layoutId: props.layoutId })
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<EEGMontageConfigJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })

	return (
		<BaseVisualizationConfigureModal
			layoutId={props.layoutId}
			windowId={props.windowId}
			escClose={props.escClose}
			clickOutsideClose={props.clickOutsideClose}
			saveChanges={saveChanges}
			title={"Configure EEG Montage"}
		>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: "20px", width: "400px" }}>
					<ElementConfigureDiv>
						Initial Page Size

						<MobergDropdown
							selectedValue={editedConfig?.viewDuration}
							onChange={value => updateProperty("viewDuration", parseInt(value as string))}
							options={WAVEFORM_WINDOW_TIME_PRESETS
								.filter(preset => preset.label !== "Page Size")
								.map(preset => ({ label: preset.label, value: preset.time }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Montage

						<MobergDropdown
							selectedValue={editedConfig?.montage}
							onChange={value => updateProperty("montage", value)}
							options={Object.keys(EEG_MONTAGES)
								.filter(montage => ["Longitudinal-bipolar", "Bipolar-Transverse"].includes(montage))
								.map(montage => ({ label: montage, value: montage }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Lower Filter Frequency

						<MobergDropdown
							selectedValue={editedConfig?.LFF}
							onChange={value => updateProperty("LFF", parseFloat(value as string))}
							options={Object.entries(EEG_LOW_FILTERS).map(([label, value]) => ({ label, value }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Upper Filter Frequency

						<MobergDropdown
							selectedValue={editedConfig?.HFF}
							onChange={value => updateProperty("HFF", parseFloat(value as string))}
							options={Object.entries(EEG_HIGH_FILTERS).map(([label, value]) => ({ label, value }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Notch Filter Frequency

						<MobergDropdown
							selectedValue={editedConfig?.notch}
							onChange={value => updateProperty("notch", parseFloat(value as string))}
							options={Object.entries(EEG_NOTCH_FILTERS).map(([label, value]) => ({ label, value }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Sensitivity
						<MobergDropdown
							selectedValue={editedConfig?.sensitivityMicroVolts}
							onChange={value => updateProperty("sensitivityMicroVolts", parseFloat(value as string))}
							options={Object.entries(EEG_SENSITIVITY).map(([label, value]) => ({ label, value }))}
						/>
					</ElementConfigureDiv>
				</div>
			</div>
		</BaseVisualizationConfigureModal>
	)
}
