import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import GridContainer from "../../../../Components/TextField/GridContainer"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import TableWrapper from "../../../../Components/TextField/TableWrapper"
import MUITable from "../../../../Components/MUITable/MUITable"
import { LINKS } from "../../../../Constants/BackendLinks"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import { useTableContext } from "../../../../Providers/TableProvider"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton"
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors"
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const userColumns = [
	{ field: "email", flex: 1, headerName: "Email", visible: true },
	{ field: "first_name", flex: 0.5, headerName: "First name", visible: true },
	{ field: "last_name", flex: 0.5, headerName: "Last name", visible: true },
	{
		field: "is_verified",
		flex: 0.3,
		headerName: "Verified",
		renderCell: params => {
			return params.value ? <CheckCircleIcon color="success" /> : null
		},
		headerAlign: "center",
		align: "center",
		visible: true
	},
]

export default function ModifyProjectModal({ row, viewMode = false }) {
	const { close } = useModalProvider()
	const { setToastProps } = useTableContext()
	const endpointProvider = useEndpointProvider()
	const queryClient = useQueryClient()
	const [selectedRows, setSelectedRows] = useState(viewMode ? [] : row.user_ids)
	const queryProps = {
		queryKey: "users",
		endpoint: viewMode ? LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS_BY_PROJECT : LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS,
		body: viewMode ? { project_id: row.id } : {},
	}
	const preselectedProps = { selectedRows, setSelectedRows }
	const schema = yup
		.object({
			name: yup.string().required("Name is required."),
			identifier: yup.string().required("Project ID is required."),
		})
		.required()

	const { control, handleSubmit } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: row.name, identifier: row.identifier },
	})

	const modifyProject = async body => endpointProvider.post(LINKS.ADMIN.PROJECTS.MODIFY_PROJECT, body)
	const modifyProjectMutation = useMutation({
		mutationFn: body => modifyProject(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error.message })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "Project successfully modified." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("projects")
		},
	})

	const onSubmit = data => {
		if (selectedRows.length === 0) {
			alert("Please select at least one user.")
			return
		}
		modifyProjectMutation.mutate({
			project_id: row.id,
			name: data.name,
			type: "TYPE",
			user_ids: selectedRows,
		})
		close()
	}

	return (
		<>
			<ModalHeader headerText={viewMode ? "View Project" : "Modify Project"} />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>Project Details</h2>
					<GridContainer>
						<BasicTextField inputId="modify-project-name-id" label="Name" placeholder="Project Name" autoFocus={true} control={control} name="name" numberColumn={6} disabled={viewMode} />
						<BasicTextField
							inputId="modify-project-id-id"
							label="Project ID"
							placeholder="Project ID"
							control={control}
							name="identifier"
							numberColumn={6}
							disabled={true}
						/>
						<TableWrapper inputId="modify-project-users-id" label="Users" >
							<MUITable columns={userColumns} preselectedProps={preselectedProps} {...queryProps} isRowSelectable={() => !viewMode} isCheckboxSelection={!viewMode}/>
						</TableWrapper>
					</GridContainer>
				</div>
			</ModalScrollbar>

			{!viewMode && (
				<ModalFooter style={{ justifyContent: "right" }}>
					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.FILLED}
						fontSize={MobergFontSize.LARGE}
						shape={MobergButtonShape.WIDE}
						onClick={handleSubmit(onSubmit)}>
						Submit
					</MobergButton>
				</ModalFooter>
			)}

		</>
	)
}
