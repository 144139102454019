import { select, Selection, EnterElement } from "d3"
import { D3TimeBasedVisualization } from "./D3TimeBasedVisualization"
import { D3Timeline } from "../D3/Timeline/D3Timeline"

export abstract class D3VisualizationRenderer<VisualizationType extends D3TimeBasedVisualization<any, any, any, any>, ConfigBuilder> {
    public timeline?: D3Timeline
    
    protected visualization: VisualizationType
    protected configBuilder: ConfigBuilder

    protected className: string

    private boundEnter: (enterElements: Selection<EnterElement, any, any, any>) => Selection<any, any, any, any> 
    private boundUpdate: (updatedElements: Selection<any, any, any, any>) => Selection<any, any, any, any>
    private boundExit: (exitedElements: Selection<any, any, any, any>) => void


    constructor(visualization: VisualizationType, configBuilder: ConfigBuilder, className: string) {
        this.visualization = visualization
        this.configBuilder = configBuilder
        this.className = className

        this.boundEnter = this.handleEnter.bind(this)
        this.boundUpdate = this.handleUpdate.bind(this)
        this.boundExit = this.exit.bind(this)
    }

    public abstract viewTimesChanged(): void
    public abstract onTimelineSliderDrag(): void
    public liveEndDateUpdated = () => this.timeline?.liveEndDateUpdated()

    public render() {
		if (!this.canRender()) {
            return
        }

		select(this.visualization.root)
			.selectAll<SVGSVGElement, any>("." + this.className)
			.data([this.visualization.config])
			.join(this.boundEnter, this.boundUpdate, this.boundExit)
	}

    // When elements enter the screen, we need to reset the page manager.
    // This wrapper prevents child renderers from having to remember to do this.
    private handleEnter(enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> {
        // enter gets called regardless of whether there are elements entering the screen or not. Prevents confusion in child classes.
        if (enterElements.nodes().length > 0) {
            return this.enter(enterElements)
        }

        return enterElements
    }

    private handleUpdate(updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> {
        // update gets called regardless of whether there are elements updating on the screen or not. Prevents confusion in child classes
        if (updatedElements.nodes().length > 0) {
            return this.update(updatedElements)
        }

        return updatedElements
    }

    protected abstract canRender(): boolean
    protected abstract enter(enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any>
    protected abstract update(updateElements: Selection<any, any, any, any>): Selection<any, any, any, any>

    protected exit(exitedElements: Selection<any, any, any, any>) {
        exitedElements.remove()
    }
}