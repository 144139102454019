import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TimeoutModal = ({ showModal,removeModal,logoutTime }) => {
    const date = new Date(logoutTime)
    return <Modal isOpen={showModal} keyboard={false} backdrop="static">
        <ModalHeader>Session Timeout!</ModalHeader>
        <ModalBody>
            Your session is about to expire at {date.toLocaleTimeString('en-US')} due to inactivity. You will be redirected to the login page.
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={removeModal}>Stay Logged In</Button>
        </ModalFooter>
    </Modal>
}

export default TimeoutModal;