import { Selection, EnterElement, ScaleTime, select } from "d3"
import { debounce } from "lodash"
import { ReactCallbacks } from "../../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "../D3OneToOneRenderable"
import { zonedTimeToUtc } from "date-fns-tz"

export type D3TimelineTooltipConfig = {
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	timeZone: string
}

type DateFormatterOptions = {
	timeZone?: boolean
	timeOnly?: boolean
}

export class D3TimelineTooltip extends D3OneToOneRenderable<SVGGElement, SVGTextElement, D3TimelineTooltipConfig> {
	private tooltipSelection?: Selection<any, any, any, any>

	constructor(root: SVGGElement, config: D3TimelineTooltipConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-timeline-tooltip", reactCallbacks)
		this.render()
	}

	public moveTo(date: Date) {
		const middlePosition = this.config.fileScale(date)

		requestAnimationFrame(() => {
			this.tooltipSelection
				?.text(this.getStartEndDateString())
				.attr("x", middlePosition)
				.style("opacity", 1)
		})
	}

	public setText(text: string) {
		requestAnimationFrame(() => {
			this.tooltipSelection?.text(text)
		})
	}

	public cancelTooltipHide() {
		this.hideTimelineTooltip.cancel()
	}

	public hide(milliseconds: number) {
		const hideFunction = this.getDebouncedHide(milliseconds)
		hideFunction(this.tooltipSelection as Selection<any, any, any, any>)
	}

	protected enter(newElements: Selection<EnterElement, D3TimelineTooltipConfig, any, any>): Selection<SVGTextElement, D3TimelineTooltipConfig, SVGGElement, any> {
		const [minX, maxX] = this.config.viewScale.range()

		const tooltip = newElements
			.append("text")
			.attr("class", this.className)
			.text(this.getStartEndDateString())
			.attr("text-anchor", "middle")
			.attr("x", (minX + maxX) / 2)
			.attr("y", -10)
			.attr("font-size", "0.8em")
			.style("font-family", '"source-sans-pro", sans-serif')
			.style("opacity", 0)
			.style("user-select", "none")
			.style("transition", "opacity 0.2s ease-in-out")
		
		return tooltip
	}

	protected update(updatedElements: Selection<any, any, any, any>) {
		const [minX, maxX] = this.config.viewScale.range()
		const averageDate = this.config.viewScale.invert((minX + maxX) / 2)
		this.moveTo(averageDate)
		this.hideTimelineTooltip(updatedElements)

		this.tooltipSelection = updatedElements

		return updatedElements
	}

	private getDebouncedHide = (milliseconds: number) => debounce((updatedElements: Selection<any, any, any, any>) => updatedElements.style("opacity", 0), milliseconds)

	private hideTimelineTooltip = this.getDebouncedHide(1000)

	public formatDate(date: Date, options: DateFormatterOptions={}) {
		try {
			// The date is zoned to the time zone of the patient file. toLocaleTimeString works with UTC.
			const dateFormatOptions: Intl.DateTimeFormatOptions = {
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
			}

			if (options.timeZone) {
				dateFormatOptions.timeZone = this.config.timeZone
				dateFormatOptions.timeZoneName = "shortGeneric"
			}

			if (!options.timeOnly) {
				dateFormatOptions.month = "short"
				dateFormatOptions.day = "2-digit"
				dateFormatOptions.year = "numeric"
			}

			return zonedTimeToUtc(date, this.config.timeZone).toLocaleTimeString("en-us", dateFormatOptions)

		} catch (error) {
			return `${error}`
		}
	}

	private getStartEndDateString(): string {
		const [startDate, endDate] = this.config.viewScale.domain()

		const formattedStart = this.formatDate(startDate)
		const formattedEnd = this.formatDate(endDate, { timeOnly: true, timeZone: true })

		return `${formattedStart} - ${formattedEnd}`
	}
}
