import { Selection, EnterElement, ScaleTime } from "d3"
import { createButtonOutlineRectangle, createIconButtonPath } from "./D3TimelineButtons"
import { ReactCallbacks } from "../../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "../D3OneToOneRenderable"

export type D3TimelinePlayButtonConfig = {
	id: string
	color: string
	speed: number
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	timelineController: string | null
	canInteract: boolean
	isLinked: boolean
	updateViewTimes(start: Date, end: Date): void
}

export class D3TimelinePlayButton extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3TimelinePlayButtonConfig> {
	private iconClassName: string = "play-pause-icon"
	private previousUpdateTimestamp?: number
	private animationFrameId?: number

	constructor(root: SVGGElement, config: D3TimelinePlayButtonConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-timeline-play-button", reactCallbacks)
		this.render()
	}

	enter = (newElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> => {
		const group = newElements.append("g").attr("class", this.className)

		createButtonOutlineRectangle(group, this.playPause)

		group
			.append("circle")
			.attr("pointer-events", "none")
			.attr("r", 9)
			.attr("stroke-width", 1.5)
			.attr("stroke", this.config.canInteract ? this.config.color : "gray")
			.attr("fill", this.getCircleFillColor())
			.attr("cx", 15)
			.attr("cy", 15)

		const icon = group.append("g").attr("class", this.iconClassName)

		if (this.isPlaying()) {
			this.createPauseButton(icon)
		} else {
			this.createPlayButton(icon)
		}

		return group
	}

	update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const group = updatedElements
		group
			.select("circle")
			.attr("stroke", this.config.canInteract ? this.config.color : "gray")
			.attr("fill", this.getCircleFillColor())

		const icon = updatedElements.select("." + this.iconClassName)

		icon.selectChildren().remove()

		if (this.isPlaying()) {
			this.createPauseButton(icon)
		} else {
			this.createPlayButton(icon)
		}

		return updatedElements
	}

	protected updateDerivedState(): void {
		this.checkLinkedWindowTimelineController()
	}

	private checkLinkedWindowTimelineController() {
		if (!this.config.isLinked && this.config.timelineController === this.config.id) {
			// We unlinked the window while we were in control of the timeline, so this window should not control the linked window timelines anymore.
			this.reactCallbacks.setLinkedWindowsTimelineController(null)
		} else if (this.config.isLinked && this.isPlaying() && this.config.timelineController === null) {
			// We linked the window while the timeline was playing, and nothing was in control of the timelines. So, we should become the controller.
			this.reactCallbacks.setLinkedWindowsTimelineController(this.config.id)
		} else if (this.config.isLinked && this.isPlaying() && this.config.timelineController !== this.config.id) {
			// We linked the window while it was playing, and there was already a controller. We should stop playing and listen to linked window events instead.
			this.stop()
		}
	}

	private getCircleFillColor = () => {
		if (this.isPlaying()) {
			if (this.config.canInteract) {
				return this.config.color
			}

			return "gray"
		}

		return "none"
	}

	private createPlayButton = (selection: Selection<any, any, any, any>) => {
		const color = this.config.canInteract ? this.config.color : "gray"

		selection
			.append("polygon")
			.attr("pointer-events", "none")
			.attr("cursor", "pointer")
			.attr("fill", color)
			.attr("points", "12.5,19.5 19,15 12.5,10.5")
	}

	private createPauseButton(selection: Selection<any, any, any, any>) {
		createIconButtonPath(selection, "M13,11 13,19", "white", 1.5)
		createIconButtonPath(selection, "M17,11 17,19", "white", 1.5)
	}

	private realTimeUpdateFrame = (timestamp: number) => {
		if (!this.previousUpdateTimestamp) {
			this.previousUpdateTimestamp = timestamp
		}

		if (!this.isPlaying()) {
			return
		}

		const deltaTime = timestamp - this.previousUpdateTimestamp

		const [start, end] = this.config.viewScale.domain()
		const newStart = start.getTime() + this.config.speed * deltaTime
		const newEnd = end.getTime() + this.config.speed * deltaTime

		if (newEnd > this.config.fileScale.domain()[1].getTime()) {
			this.previousUpdateTimestamp = undefined
			this.stop()
			this.render()
			return
		}

		this.config.updateViewTimes(new Date(newStart), new Date(newEnd))
		this.previousUpdateTimestamp = timestamp
		this.animationFrameId = requestAnimationFrame(this.realTimeUpdateFrame)
	}

	public isPlaying = () => {
		return this.animationFrameId !== undefined
	}

	playPause = () => {
		if (!this.config.canInteract) {
			return
		}

		if (this.isPlaying()) {
			this.stop()
		} else {
			this.animationFrameId = requestAnimationFrame(this.realTimeUpdateFrame)

			if (this.config.isLinked) {
				this.reactCallbacks.setLinkedWindowsTimelineController(this.config.id)
			}
		}

		this.render()
	}

	stop = () => {
		cancelAnimationFrame(this.animationFrameId as number)
		this.animationFrameId = undefined
		this.previousUpdateTimestamp = undefined
		this.reactCallbacks.setLinkedWindowsTimelineController(null)
	}
}
