import { D3Trace } from "./D3Trace"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { TraceConfig } from "../../../../Types/Trace"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { Selection, EnterElement } from "d3"
import { ModalityPage } from "../../../../Data/ModalityPage"

type D3TracesWrapperConfig = {
	traces: TraceConfig[]
}

export class D3TracesWrapper extends D3OneToManyRenderable<SVGGElement, D3TracesWrapperConfig, TraceConfig, ModalityGraphGroupReactCallbacks> {
	private traces: Map<string, D3Trace> = new Map()
	private pageManager: TimeSeriesPageManager<ModalityPage>

	constructor(root: SVGGElement, config: D3TracesWrapperConfig, pageManager: TimeSeriesPageManager<ModalityPage>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, "d3-trace-wrapper", reactCallbacks)
		this.pageManager = pageManager
		this.mount()
	}

	public renderPage = (page: ModalityPage) => {
		this.traces.forEach(trace => trace.renderPage(page))
	}

	public rescale = () => {
		this.traces.forEach(trace => trace.rescale())
	}

	public redraw = () => {
		this.traces.forEach(trace => trace.redraw())
	}

	public takeSnapshot = () => {
		this.traces.forEach(trace => trace.takeSnapshot())
	}

	public clearSnapshot = () => {
		this.traces.forEach(trace => trace.clearSnapshot())
	}

	public isRescaling = (): boolean => {
		for (const [, trace] of this.traces) {
			if (trace.isRescaling()) {
				return true
			}
		}

		return false
	}

	protected updateDerivedState(): void {
		this.config.traces.forEach(trace => this.traces.get(trace.id)?.updateConfig(trace))
	}

	protected datumIdentifier(datum: TraceConfig): string | number {
		return JSON.stringify(datum)
	}

	protected getConfigs(): TraceConfig[] {
		return this.config.traces
	}

	protected enter(newElements: Selection<EnterElement, TraceConfig, SVGGElement, any>): Selection<SVGGElement, TraceConfig, SVGGElement, any> {
		const containers = newElements.append("g").attr("class", this.className)
		containers.each((config, index, nodes) => this.createChildren(config, index, nodes))
		return containers
	}

	protected update(updatedElements: Selection<SVGGElement, TraceConfig, SVGGElement, any>): Selection<SVGGElement, TraceConfig, SVGGElement, any> {
		this.config.traces.forEach(trace => this.traces.get(trace.id)?.render())
		return updatedElements
	}

	protected exit(exitedElements: Selection<SVGGElement, TraceConfig, SVGGElement, any>): void {
		exitedElements.each(config => this.traces.delete(config.id))
		exitedElements.remove()
	}

	protected createChildren = (traceConfig: TraceConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		this.traces.set(traceConfig.id, new D3Trace(nodes[index], traceConfig, this.pageManager, this.reactCallbacks))
	}
}
