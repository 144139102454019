import { atomFamily } from "recoil"
import { LayoutWindowId } from "../Types/LayoutWindowId"
import { scaleTime } from "d3"
import { PersystEEGWindowConfig, PersystTrendsWindowConfig } from "../Types/PersystWindow"

export const persystTrendsAtom = atomFamily<PersystTrendsWindowConfig, LayoutWindowId>({
	key: "persystTrends",
	default: {
		id: "default",
		dataObjectId: "?",
		viewScale: scaleTime(),
		fileScale: scaleTime(),
		patientId: "?",
		annotations: [],
		inProgressAnnotation: {
			id: "?",
			startDate: null,
			endDate: null,
			color: "red",
			opacity: 0.2,
			text: "",
			modalities: [],
			keyPressed: null,
		},
		playbackSpeed: 1,
		dimensions: { height: 0, width: 0 },
		isLinked: false,
		timelineController: null,
		artifactReductionEnabled: true,
		timeZone: "America/New_York",
		liveModeEnabled: false,
	},
})

export const persystEEGAtom = atomFamily<PersystEEGWindowConfig, LayoutWindowId>({
	key: "persystEEG",
	default: {
		id: "default",
		dataObjectId: "?",
		viewScale: scaleTime(),
		fileScale: scaleTime(),
		patientId: "?",
		annotations: [],
		inProgressAnnotation: {
			id: "?",
			startDate: null,
			endDate: null,
			color: "red",
			opacity: 0.2,
			text: "",
			modalities: [],
			keyPressed: null,
		},
		playbackSpeed: 1,
		dimensions: { height: 0, width: 0 },
		isLinked: false,
		timelineController: null,
		montage: null,
		timeZone: "America/New_York",
		liveModeEnabled: false,
	},
})
