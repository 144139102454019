import { useState, useEffect } from "react"
import styled from 'styled-components'
import * as MdIcons from 'react-icons/md'
import { LINKS } from '../../../../Constants/BackendLinks'
import { ENCRYPT, FORM } from "../../../../Constants/UploadLink"
import { createMD5 } from 'hash-wasm';
import { useEndpointProvider } from "../../../../Providers/EndpointProvider";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { useUploadIntoQueueProvider } from "../../../../Providers/UploadIntoQueueProvider";
import loadingSpinner from '../../../../Constants/Graphics/loadingSpinner.svg'
import { useUploadQueueProvider } from "../../../../Providers/UploadQueueProvider";
import { useSetRecoilState } from "recoil"
import { percentUploaded, timeRemaining, uploadFailed, fileHashed, fileUploaded, totalHashFiles, totalUploadFiles } from "../../../../../src/recoil_state"
import { useAuthProvider } from "../../../../Providers/AuthProvider"
import * as SparkMD5 from "spark-md5"
import * as aesjs from "aes-js"
import ConfirmationModal from "../../../../Components/ConfirmationModal/ConfirmationModal"
import { MobergTheme } from "../../../../Moberg"
import { getUploadWorker } from "../../../../Providers/UploadWorker"
import { useWorkspacesProvider } from "../../../../Providers/WorkspacesProvider"

export function useOnMount(effect) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(effect, [])
}

export const useOnlineStatus = ({ onOnline, onOffline }) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useOnMount(() => {
		const handleOnline = () => {
			if (onOnline) {
				onOnline()
			}
			setIsOnline(true)
		}

		const handleOffline = () => {
			if (onOffline) {
				onOffline()
			}
			setIsOnline(false)
		}

		window.addEventListener("online", handleOnline)
		window.addEventListener("offline", handleOffline)

		return () => {
			window.removeEventListener("online", handleOnline)
			window.removeEventListener("offline", handleOffline)
		}
	})

	return isOnline
}

const UploadQueue = () => {

	const setPercentUploaded = useSetRecoilState(percentUploaded) // set percentUploaded method from queue to recoil
	const setTimeRemaining = useSetRecoilState(timeRemaining) // set timeRemaining method from queue to recoil
	const setRecoilFileHashed = useSetRecoilState(fileHashed) // set fileProcessed method from queue to recoil
	const setRecoilFileUploaded = useSetRecoilState(fileUploaded) // set fileUploaded method from queue to recoil
	const setRecoilTotalHashFiles = useSetRecoilState(totalHashFiles) // set totalFiles method from queue to recoil
	const setRecoilTotalUploadFiles = useSetRecoilState(totalUploadFiles) // set totalFiles method from queue to recoil
	const setRecoilUploadFailed = useSetRecoilState(uploadFailed) // set uploadFailed method from queue to recoil

	const uploadIntoQueueProvider = useUploadIntoQueueProvider(); // The provider that pass in the files for the upload. Scope : Outer Whole.js
	const uploadQueueProvider = useUploadQueueProvider(); // The provider that pass the metadata of each upload to the loading page. Scope : Outer Whole.js
	const endpointProvider = useEndpointProvider() // The provider that pass in the endpoint. Scope : Outer Whole.js
	const authProvider = useAuthProvider() // The provider that pause the check inactivity process while uploading. Scope : Outer Whole.js
	const workspacesProvider = useWorkspacesProvider()

	const { createModal, close } = useModalProvider() // The provider let the queue open the loading page. Scope : Outer Whole.js

	const UploadWorker = getUploadWorker()

	const defaultVerticalTimelineData = [
		{
			title: "Preparing",
			description: "Waiting to prepare files...",
			complete: false,
			progressStarted: false,
		},
		{
			title: "Uploading",
			description: "",
			complete: false,
			progressStarted: false,
		},
	]

	let hasher = null
	let messages = []

	// Variables to calculate time remaining, you should console.log them in each for loop if something goes wrong

	let fileModified = 0
	let progress = 0
	let fileChecked = 0

	const [queue, setQueue] = useState([]); // Queue contain multiple uploads
	const [uploadList, setUploadList] = useState([]); // uploadList is to display the UI of the uploads
	const [timeDisplay, setTimeDisplay] = useState();
	const [queuePercentUploaded, setQueuePercentUploaded] = useState(0) // Percent uploaded display on Queue (not loadingPage)

	const [isExpand, setIsExpand] = useState(true) //Expand or collapse the queue
	const [isUploading, setIsUploading] = useState(false) // a flag to check if there is an upload in progress so we dont run uploads concurrently

	const [uploadProgressMessage, setUploadProgressMessage] = useState("")
	const [uploadsInProgress, setUploadsInProgress] = useState(0)
	const [uploadsCompleted, setUploadsCompleted] = useState(0)
	const [uploadsFailed, setUploadsFailed] = useState(0)
	//Numbers for overall upload progress displayed in the queue

	const [enableCloseQueue, setEnableCloseQueue] = useState(false) // handles enabling/disabling X button in Upload Queue

	// Remove pop up modal
	function renderStopUploadModal(selectedUpload) {
		uploadQueueProvider.setItemToDelete(selectedUpload.id) //Delete the cache of the upload
		uploadIntoQueueProvider.undoExistingValidation(selectedUpload.id) //Since we did not allow duplication upload, when we remove an upload, we need to undo the validation

		createModal(<ConfirmationModal
			title={"Remove upload?"}
			description={`Would you like to remove the upload for Patient ${selectedUpload.patient_id} at Site ${selectedUpload.site_id} from the queue? Removing it will prevent the upload process from occurring.`}
			confirmButton={{ text: "Yes, cancel upload", theme: MobergTheme.RED, onClick: cancelUpload }}
			cancelButton={{ text: "No, continue upload" }}
			afterClose={close}
		/>)

		selectedUpload = null
	}

	// handle remove from queue
	useEffect(() => {
		if (uploadQueueProvider.removeFromQueue) {
			removeFromQueue(uploadQueueProvider.itemToDelete)
			uploadQueueProvider.setItemToDelete(null)
			uploadQueueProvider.setRemoveFromQueue(false)
		}
	}, [uploadQueueProvider.removeFromQueue])

	const removeFromQueue = itemToRemoveID => {
		setQueue(queue => queue.filter(item => item.id !== itemToRemoveID))
		setUploadList(uploadList => uploadList.filter(item => item.id !== itemToRemoveID))
	}

	async function uploadForm(formUrl) {
		let body = {
			form: uploadIntoQueueProvider.form,
			form_url: formUrl
		}
		return endpointProvider.post(LINKS.DATA.UPLOAD.UPLOAD_FORM, body)
	}

	// This stop the page from logging out while uploading even when the user is inactive
	async function updateTokenExpireTime() {
		return endpointProvider.post(LINKS.ACCOUNT.UPDATE_TOKEN_EXPIRE_TIME, {})
	}

	async function cancelUpload() {
		let body = {
			upload_id: uploadQueueProvider.uploadID,
		}

		UploadWorker.postMessage({ action: 'resetValues' })

		return endpointProvider.post(LINKS.DATA.UPLOAD.CANCEL_UPLOAD, body)
	}

	// Clean up the remnants of upload after cancel
	async function cleanUpCanceledUpload(uploadID) {
		let body = {
			upload_id: uploadID,
		}
		return endpointProvider.post(LINKS.DATA.UPLOAD.DELETE_CANCELED_UPLOAD, body)
	}

	// Create the upload id and prepare the slots on the cloud
	async function createUpload(body) {
		return endpointProvider.post(LINKS.DATA.UPLOAD.CREATE_UPLOAD, body)
	}

	async function modifyUpload(body) {
		return endpointProvider.post(LINKS.DATA.UPLOAD.MODIFY_UPLOAD, body)
	}

	function getTotalBytes(files) {
		const totalBytes = files.reduce((totalBytes, file) => totalBytes + file.size, 0)
		files = null
		return totalBytes
	}

	//uploadData contains multiple fields, each field contains multiple files, this function extract all the files from uploadData
	function getAllFiles(processingData) {
		const allFiles = [];
		for (const key in processingData) {
			if (Array.isArray(processingData[key]) && processingData[key].length > 0) {
				allFiles.push(...processingData[key]);
			}
		}
		processingData = null;
		return allFiles;
	}

	function getFormUrl(urls, filepath) {
		let formUrl = null;
		for (let key of Object.keys(urls)) {
			if (key === filepath) {
				formUrl = urls[key];
			}
		}

		return formUrl;
	}


	function getPatientInfosPresignedURLs(urls, filepath) {
		let encryptedPatientInfoURL = null
		let unencryptedPatientInfoURL = null
		for (let key of Object.keys(urls)) {
			if (key === filepath) {
				encryptedPatientInfoURL = urls[key]
			} else if (key === `${filepath}.json`) {
				unencryptedPatientInfoURL = urls[key]
			}
		}
		return { encryptedPatientInfoURL, unencryptedPatientInfoURL }
	}

	function updateCancelUploadUI(processingMetaData) {
		processingMetaData.status = "Canceled"
		uploadIntoQueueProvider.undoExistingValidation(queue[0].id);
	}

	async function uploadFailedEmail(upload_id) {
		let body = {
			upload_id: upload_id,
			patient_id: uploadQueueProvider.patientID,
			site_id: uploadQueueProvider.selectedSiteID,
			external: true
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.UPLOAD_FAILED_NOTIFICATION, body)
	}

	function updateFailedUploadUI(processingMetaData, upload_id) {
		processingMetaData.status = "Failed"
		uploadIntoQueueProvider.undoExistingValidation(queue[0].id);
		setUploadsFailed(uploadsFailed => (uploadsFailed + 1))
		setRecoilUploadFailed(true)
		UploadWorker.postMessage({ action: 'resetValues' })

		if (!upload_id) return
		uploadFailedEmail(upload_id)
	}

	/**
	* Generate MD5 hashes for an array of files, update upload progress, and handle errors.
	*
	* @async
	* @param {string} upload_id - Unique identifier for the upload.
	* @param {File[]} fileArray - Array of File objects to hash.
	* @param {string|undefined} filepath - Base directory path for file upload (optional).
	* @param {number} totalBytes - Total size of all files in bytes.
	* @param {object} processingMetaData - Metadata related to the current upload processing.
	* @returns {object} - An object containing MD5 hashes for the processed files.
	*/
	async function getMD5Hashes(upload_id, fileArray, filepath = undefined, totalBytes, processingMetaData) {
		let res = {}
		for (let file of fileArray) {
			try {
				const hashMethod = file.size > 40000000 ? 'calculateS3ETag' : 'getMD5Hash'
				const relativePath = file.webkitRelativePath === '' ? `${filepath}/${file.name}` : `${filepath}/${file.webkitRelativePath}`
				file.cloudDirectory = relativePath

				const hash = await hashFileWithWorker(UploadWorker, file, hashMethod, totalBytes)
				res[relativePath] = hash
			} catch (e) {
				console.log('ERROR: ', e)
				updateFailedUploadUI(processingMetaData, upload_id)
				return null
			}
			// Update the upload progress and status on the loading page
			uploadQueueProvider.updateVerticalTimeline(0, "Preparing", file.webkitRelativePath, false, true)

			try {
				const res = await modifyUpload({ upload_id, progress })
				if (res === 'Upload has been canceled') {
					UploadWorker.postMessage({ action: 'resetValues' })
					updateCancelUploadUI(processingMetaData)
					return null
				}
			} catch (e) {
				updateFailedUploadUI(processingMetaData, upload_id)
				console.log(`Could not modify upload: ${e}`);
				return null;
			}

			fileModified += 1
			setRecoilFileHashed(fileModified)
		}
		return res
	}

	function updateLoadingPageCompleteHash() {
		uploadQueueProvider.updateVerticalTimeline(0, "Preparing", "", true, false)
		uploadQueueProvider.updateVerticalTimeline(0, "Prepared", "", true, true)
	}

	// since both estimation and handleUpload use startUpload, we initialize the upload_id in estimation and pass it to handleUpload
	async function initializeUploadID(site_id, patient_id, processingMetaData) {
		try {
			await updateTokenExpireTime()
		} catch (e) {
			updateFailedUploadUI(processingMetaData)
			console.log(`Could not update token expire time: ${e}`)
			return;
		}

		try {
			let data = await createUpload({ project_id: uploadQueueProvider.projectPrimaryKey, site_id: site_id, patient_id: patient_id, user_id: authProvider?.currentUser?.id })
			uploadQueueProvider.setUploadID(data['upload_id'])
			uploadIntoQueueProvider.setUploadID(data['upload_id'])
			return data;
		} catch (e) {
			updateFailedUploadUI(processingMetaData)
			console.log(`Could not create upload: ${e}`)
			return;
		}
	}

	function hashFileWithWorker(worker, file, method, totalBytes) {
		return new Promise((resolve, reject) => {
			worker.onmessage = (e) => {
				if (e.data.type === 'result') {
					resolve(e.data.result)
				} else if (e.data.type === 'error') {
					reject(e.data.error)
				} else if (e.data.type === 'progress') {
					setPercentUploaded(e.data.progress)
					setQueuePercentUploaded(e.data.progress)
				}
			}

			worker.onerror = (error) => {
				alert("Worker had a problem " + error)
				reject(error)
			}

			worker.postMessage({ action: method, file: file, totalBytes: totalBytes })
		})
	}

	/**
	 * Initiates the upload process, including hashing files, initializing the upload, and sending data to the backend.
	 *
	 * @async
	 * @param {object} processingData - Data related to the upload process.
	 * @param {object} processingMetaData - Metadata related to the upload process.
	 * @returns {object|null} - An object containing upload-related information or null if the upload fails or is canceled.
	 */
	async function startUpload(processingData, processingMetaData) {
		// Retrieve all files to be uploaded
		let allFiles = getAllFiles(processingData)

		// Extract site_id and patient_id from processingData
		let site_id = parseInt(processingMetaData.site_id)
		let patient_id = processingData.patient_id

		const totalBytes = getTotalBytes(allFiles)
		processingMetaData.status = "Hashing"
		uploadQueueProvider.updateVerticalTimeline(1, "Uploading", "", false, false)
		uploadQueueProvider.updateVerticalTimeline(0, "Preparing Files", "Waiting to prepare files...", false, true)
		uploadQueueProvider.setQueueStatus(processingMetaData.status)

		// Initialize the upload ID for the site and patient
		let data = await initializeUploadID(site_id, patient_id, processingMetaData)
		if (!data) return null;

		// Extract the upload_id and db_patient_id from the initialization response
		let upload_id = data['upload_id']
		let db_patient_id = data['patient_id']

		let files = {
			sizes: {},
			md5s: {},
		}

		// Loop through processingData keys to compute MD5 hashes for file arrays
		for (let key in processingData) {
			if (Array.isArray(processingData[key])) {
				// Compute MD5 hashes and store in the md5s field
				const md5 = await getMD5Hashes(upload_id, processingData[key], `${key}`, totalBytes, processingMetaData)
				if (!md5) return null
				files['md5s'] = { ...files['md5s'], ...md5 }
			}
		} // Settings md5 hash values to the md5s field. These md5 hashes are used to compare with the md5 hashes on the cloud

		if (processingData?.cns?.length > 0 && ENCRYPT) {
			// If encryption is enabled, prepare placeholders for patientInfo JSON files
			let patientInfos = getPatientInfos(allFiles)
			if (patientInfos.length === 0) {
				updateFailedUploadUI(processingMetaData, upload_id)
				alert("No patient info found")
				return null
			}
			for (let patientInfo of patientInfos) {
				files.md5s[`${patientInfo.cloudDirectory}.json`] = ""
				files.sizes[`${patientInfo.cloudDirectory}.json`] = patientInfo.size
			}
		}

		if (FORM) {
			const formFileName = "form/form.frm";
			files.md5s[formFileName] = ""; // Assign an empty MD5 for now
			files.sizes[formFileName] = 0; // Assign a dummy size for now
			uploadQueueProvider.updateVerticalTimeline(0, "Preparing", "form/form.frm", false, true)
			setRecoilFileHashed(1)
		}

		for (let file in allFiles) {
			files['sizes'] = { ...files['sizes'], [allFiles[file].cloudDirectory]: allFiles[file].size };
		} // Settings the sizes of the files to the sizes field. These sizes are used to decide on using presigned url or multipart upload

		// Send the md5 hashes to the backend
		let body = {
			'upload_id': upload_id,
			'files': files,
		}

		try {
			let res = await endpointProvider.post(LINKS.DATA.UPLOAD.START_UPLOAD, body)
			// Attach the db_patient_id to the response and update loading page
			res['db_patient_id'] = db_patient_id
			updateLoadingPageCompleteHash()
			return res
		} catch (e) {
			updateFailedUploadUI(processingMetaData, upload_id)
			console.log(`Could not start upload: ${e}`)
			return null;
		}
	}

	async function checkFileMD5(uploadID, filename, md5) {
		//checking in the MD5 hashes with the cloud
		let body = {
			upload_id: uploadID,
			filename: filename,
			md5: md5,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.CHECK_MD5_HASH, body)
	}

	async function uploadPatientInfo(patientInfoText, patientInfoTextEnc, unencryptedPatientInfoURL, encryptedPatientInfoURL) {
		//uploading the patient info separately to the cloud
		let body = {
			patient_info_text: patientInfoText,
			patient_info_text_enc: patientInfoTextEnc,
			unencrypted_patient_info_url: unencryptedPatientInfoURL,
			encrypted_patient_info_url: encryptedPatientInfoURL,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.UPLOAD_PATIENT_INFO, body)
	}

	async function updateMD5IV(uploadID, patientInfoTextMD5, patientInfoTextEncMD5, patientInfoTextEncIV, patientInfoPath) {
		//Update the MD5 of patientinfo separately to the cloud
		let body = {
			upload_id: uploadID,
			patient_info_text_md5: patientInfoTextMD5,
			patient_info_text_enc_md5: patientInfoTextEncMD5,
			patient_info_text_enc_iv: patientInfoTextEncIV,
			filepath: patientInfoPath,
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.UPDATE_MD5_IV, body)
	}

	async function getBucket(body) {
		//Getting the bucket from the back-end
		return endpointProvider.post(LINKS.ADMIN.BUCKETS.GET_BUCKET, body)
	}

	async function checkCancelStatus(uploadID) {
		// Call an endpoint to check if the upload is canceled
		let body = {
			upload_id: uploadID,
		}
		return endpointProvider.post(LINKS.DATA.UPLOAD.CHECK_CANCEL_STATUS, body)
	}

	function getPatientInfos(files) {
		const patientInfo = files.filter(file => {
			return file.name === "patient.info" || file.name === "admission.info"
		})
		return patientInfo
	}

	// Encrypt patient.info file
	async function encryptPatientInfo(file) {
		var private_key = '1e3f85f097b36c3020476bb4f2a65ea6';

		var key = private_key.match(/.{1,2}/g).map((pair) => { return parseInt(pair, 16) })

		return new Promise((resolve, reject) => {
			let fileReader = new FileReader();
			fileReader.readAsText(file);

			fileReader.onload = function () {
				const parser = new DOMParser();
				const serializer = new XMLSerializer();

				let fileText = fileReader.result

				// parse file for data into xml
				let fileXMLEncrypt = parser.parseFromString(fileText, "text/xml");

				// add Upload node to xml
				let newEle = fileXMLEncrypt.createElement("Upload");
				let newText = fileXMLEncrypt.createTextNode(Date.now());
				newEle.appendChild(newText);
				fileXMLEncrypt.getElementsByTagName("PatientInfo")[0].appendChild(newEle);

				// serialize xml to text
				let fileTextEncrypt = serializer.serializeToString(fileXMLEncrypt);

				// add IV to MD5 table
				let iv = SparkMD5.hash(fileText).match(/.{1,2}/g).map((pair) => { return parseInt(pair, 16) })

				// https://github.com/ricmoo/aes-js
				let aesCtr = new aesjs.ModeOfOperation.ctr(key, iv);
				var textBytes = aesjs.utils.utf8.toBytes(fileTextEncrypt);
				let encryptedBytes = aesCtr.encrypt(textBytes);
				let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

				// update md5 hash in MD5 table
				let patientInfoTextEncMD5 = SparkMD5.hash(encryptedHex);
				let patientInfoTextEncIV = iv.map((e) => e.toString(16).padStart(2, '0')).join('')

				// parse file for data into xml
				let fileXMLKeep = parser.parseFromString(fileText, "text/xml");

				// get PatientInfo node
				let patientInfoXML = fileXMLKeep.getElementsByTagName('PatientInfo')[0]
				let childNodes = patientInfoXML.childNodes;

				let keepNodes = ['TimeStamp', 'RecordingEndTime', 'Timezone', 'SystemOffset', 'SegmentEndTime', 'FileVersion']

				let cnsinfo = {}

				// remove child nodes that are not present in keepNodes
				for (let childNode of childNodes) {
					if (keepNodes.includes(childNode.nodeName)) {
						const nodeValue = childNode.childNodes[0]?.nodeValue;
						cnsinfo[childNode.nodeName] = nodeValue !== undefined ? nodeValue.trim() : undefined;
					}
				}


				// serialize xml to text
				let fileTextKeep = JSON.stringify(cnsinfo)
				let patientInfoTextMD5 = SparkMD5.hash(fileTextKeep);

				// create patient.info raw
				// create patient.info.enc encrypted

				// store iv for patient.info.enc
				// update hash for both

				resolve({ 'patientInfoText': fileTextKeep, 'patientInfoTextEnc': encryptedHex, 'patientInfoTextEncIV': patientInfoTextEncIV, 'patientInfoTextMD5': patientInfoTextMD5, 'patientInfoTextEncMD5': patientInfoTextEncMD5 })
			}
		})
	}

	// Remove the placeholder
	function removePatientInfo(files) {
		return files.filter(file => file.name !== "patient.info" && file.name !== "admission.info")
	}

	async function updateMD5Form(uploadID, formJsonHash, formUrl) {
		let body = {
			'upload_id': uploadID,
			'form_md5': formJsonHash,
			'filepath': formUrl
		}

		return endpointProvider.post(LINKS.DATA.UPLOAD.UPDATE_MD5_FORM, body)
	}

	async function hashForm(formJson) {
		let formString = JSON.stringify(formJson)
		hasher = await createMD5();
		const formBytes = new TextEncoder().encode(formString);
		hasher.update(formBytes);
		const hash = hasher.digest();
		return Promise.resolve(hash);
	}

	async function processForm(urls, upload_id) {
		uploadQueueProvider.updateVerticalTimeline(1, "Uploading", "form/form.frm", false, true)
		let form = uploadIntoQueueProvider.form
		let formUrl = getFormUrl(urls, 'form/form.frm')
		try {
			const uploadRes = await uploadForm(formUrl)
			if (uploadRes !== "Success") {
				throw Error("Error uploading form.frm file. ")
			}
			let formJsonHash = await hashForm(form)
			const updateRes = await updateMD5Form(upload_id, formJsonHash, formUrl)
			if (updateRes !== 'Success') {
				throw Error("Error update MD5 form.frm file. ")
			}

			uploadQueueProvider.updateVerticalTimeline(1, "Uploaded", "", true, true)
			setPercentUploaded(100)
			setQueuePercentUploaded(100)

			return true
		} catch (e) {
			console.log(e)
			return false
		}
	}

	// Process of encryption as a whole    
	async function processEncryptPatientInfos(patientInfos, urls, upload_id) {
		try {
			for await (let patientInfo of patientInfos) {
				let patientInfoPath = patientInfo.cloudDirectory

				let { encryptedPatientInfoURL, unencryptedPatientInfoURL } = getPatientInfosPresignedURLs(urls, patientInfoPath)

				if (!encryptedPatientInfoURL) {
					console.log('There is no URL for the patient.info file.')
					return false
				}
				const res = await encryptPatientInfo(patientInfo)
				if (!res) {
					console.log('This directory does not contain a patient.info file. Please include it during upload. It will be encrypted on upload.')
					return false
				}

				let patientInfoText = res.patientInfoText
				let patientInfoTextEnc = res.patientInfoTextEnc
				let patientInfoTextEncIV = res.patientInfoTextEncIV
				let patientInfoTextMD5 = res.patientInfoTextMD5
				let patientInfoTextEncMD5 = res.patientInfoTextEncMD5

				const uploadRes = await uploadPatientInfo(patientInfoText, patientInfoTextEnc, unencryptedPatientInfoURL, encryptedPatientInfoURL)
				if (uploadRes !== "200") {
					throw Error("Error uploading patient.info file. ")
				}

				const updateRes = await updateMD5IV(upload_id, patientInfoTextMD5, patientInfoTextEncMD5, patientInfoTextEncIV, patientInfoPath)
				if (updateRes !== 'Success') {
					throw Error("Error update MD5 patient.info file. ")
				}
			}

			return true
		} catch (e) {
			console.log(e)
			return false
		}
	}

	async function handleUploadWithWorker({ method, file, totalBytes, token, endpoint, bucket, key, multipartUploadID, uploadID, url, backendLink, workspaceName, bearer_token }) {
		return new Promise((resolve, reject) => {
			UploadWorker.onmessage = (e) => {
				switch (e.data.type) {
					case 'result':
						resolve(e.data.result)
						break
					case 'error':
						reject(e.data.error)
						break
					case 'progress':
						setPercentUploaded(e.data.progress)
						setQueuePercentUploaded(e.data.progress)
						break
					case 'timeDisplay':
						setTimeDisplay(e.data.timeDisplay)
						break
					case 'timeRemaining':
						setTimeRemaining(e.data.timeRemaining)
						break
					default:
						alert('invalid worker type')
						break
				}
			}

			UploadWorker.onerror = (error) => {
				alert("Worker had a problem " + error)
				reject(error)
			}

			UploadWorker.postMessage({ action: method, file: file, totalBytes: totalBytes, token, endpoint, bucket, key, multipartUploadID, uploadID, url, backendLink, workspaceName, bearer_token })
		})
	}


	/**
	 * This function is long just because we have to handle cancel and error cases.
	 * Breaking it down we have :
	 * 1. Check if the upload is canceled from after start_upload
	 * 2. Get all the files
	 * 3. Handle the encryption case
	 * 4. Get bucket and url to upload to
	 * 5. Upload the files either using presigned url or multipart upload
	 * 6. Check if the file is uploaded properly
	 * 7. Check if the upload is canceled from after checkFileMD5
	 * 8. Update the progress bar
	 * 9. Update time remaining
	 */
	async function upload(bearer_token, urls, upload_id, multipartUploadIDs, md5s, processingData, processingMetaData) {
		try {
			const res = await checkCancelStatus(upload_id)
			if (res === "Upload has been canceled") {
				updateCancelUploadUI(processingMetaData)
				return null
			}
		} catch (e) {
			alert(e)
			return null
		}

		let allFiles = getAllFiles(processingData);

		if (ENCRYPT && allFiles.some(file => file.name === 'patient.info')) {
			let encrypt = undefined;
			let patientInfos = getPatientInfos(allFiles);
			if (patientInfos.length === 0) {
				updateFailedUploadUI(processingMetaData, upload_id)
				alert("No patient info found")
				return null
			}
			else {
				encrypt = await processEncryptPatientInfos(patientInfos, urls, upload_id);
			}
			if (!encrypt) {
				updateFailedUploadUI(processingMetaData, upload_id)
				alert("Encryption failed")
				return null
			}
			allFiles = removePatientInfo(allFiles)
			fileChecked += patientInfos.length
		}

		if (FORM) {
			let form = await processForm(urls, upload_id)
			fileChecked += 1
			setRecoilFileUploaded(fileChecked)
			if (!form) {
				processingMetaData.status = "Failed"
				setRecoilUploadFailed(true)
				setUploadsFailed(uploadsFailed => (uploadsFailed + 1))
				uploadIntoQueueProvider.undoExistingValidation(queue[0].id)
				return null
			}
		}

		processingData = null;
		let totalBytes = getTotalBytes(allFiles)
		let bucketAndUrl = undefined
		try {
			bucketAndUrl = await getBucket({ project_id: uploadQueueProvider.projectPrimaryKey })
		} catch (e) {
			console.log(`Could not modify upload: ${e}`)
			return null
		}

		const bucket = bucketAndUrl['bucket']
		const endpoint = bucketAndUrl['endpoint']

		uploadQueueProvider.updateVerticalTimeline(1, "Uploading", "", false, true)

		for (let file of allFiles) {
			uploadQueueProvider.updateVerticalTimeline(1, "Uploading", file.webkitRelativePath, false, true)

			let url = urls[`${file.cloudDirectory}`]
			let md5 = md5s[file.cloudDirectory]
			let key = `${upload_id}/${file.cloudDirectory}` // Key for the file on the cloud
			let multipartUploadID = multipartUploadIDs[`${file.cloudDirectory}`] // ID for multipart upload

			if (file.size > 40000000) {
				try {
					await handleUploadWithWorker({ method: 'uploadLargeFileInChunks', token: authProvider.token, endpoint: endpoint, bucket: bucket, key: key, file: file, multipartUploadID: multipartUploadID, uploadID: upload_id, totalBytes: totalBytes, backendLink: LINKS.DATA.UPLOAD.CHECK_CANCEL_STATUS.LINK, workspaceName: workspacesProvider?.selectedWorkspace, bearer_token: bearer_token })
				} catch (e) {
					updateFailedUploadUI(processingMetaData, upload_id)
					console.log(`Could not upload ${file.cloudDirectory}: ${e}`)
					return null;
				}
			}
			else {
				try {
					await handleUploadWithWorker({ method: 'uploadToPresignedURL', file: file, url: url, totalBytes: totalBytes })
				} catch (e) {
					updateFailedUploadUI(processingMetaData, upload_id)
					console.log(`Could not upload ${file.cloudDirectory}: ${e}`)
					return null;
				}
			}

			try {
				let data = await checkFileMD5(upload_id, file.cloudDirectory, md5)

				if (data === "Upload has been canceled") {
					updateCancelUploadUI(processingMetaData)
					return null
				}

				if (!data['transmitted_properly']) {
					updateFailedUploadUI(processingMetaData, upload_id)
					console.log(`MD5 Hash of ${file.cloudDirectory} is incorrect`)
					return null
				}
			} catch (e) {
				updateFailedUploadUI(processingMetaData, upload_id)
				console.log(`Could not check ${file.cloudDirectory}: ${e}`)
				return null
			}

			setRecoilFileUploaded(fileChecked += 1)
		}
		return "Success"
	}

	// Call an endpoint that handle the finished upload
	async function finishUpload(body) {
		return endpointProvider.post(LINKS.DATA.UPLOAD.FINISH_UPLOAD, body)
	}

	// The big function as a whole that handle the upload process
	async function handleUpload(processingData, processingMetaData,) {
		let res = await startUpload(processingData, processingMetaData)
		if (!res) return

		if (res.status === 'failed') {
			updateFailedUploadUI(processingMetaData, res.upload_id)
			await cleanUpCanceledUpload(res.upload_id).catch(e => {
				console.log(e);
			})
			return
		}

		let upload_res = await upload(res.bearer_token, res.urls, res.upload_id, res.multipart_upload_ids, res.md5s, processingData, processingMetaData)

		if (upload_res === null) {
			await cleanUpCanceledUpload(res.upload_id).catch(e => {
				console.log(e);
			})
			return;
		}

		try {

			finishUpload({ upload_id: res.upload_id, encrypt: ENCRYPT }).then(async (data) => {
				if (data.messages) messages.push(...data.messages);
			})
			UploadWorker.postMessage({ action: 'resetValues' })
		} catch (e) {
			if (e.statusCode !== 504) {
				updateFailedUploadUI(processingMetaData, res.upload_id)
				await cleanUpCanceledUpload(res.upload_id).catch(e => {
					console.log(e);
				})
				messages.push(`Failed to exit: ${e.statusCode}`);
				console.log(e)
			}
		}

		if (processingMetaData.status === "Failed") {
			await cleanUpCanceledUpload(res.upload_id).catch(e => {
				console.log(e)
			})
			return
		}

		processingMetaData.status = "Done"
		setUploadsInProgress(uploadsInProgress => uploadsInProgress - 1)
		setUploadsCompleted(uploadsCompleted => uploadsCompleted + 1)
		uploadQueueProvider.updateVerticalTimeline(1, "Uploaded", "", true, true)
	}

	// When the uploadData comes in, we add it to the queue and the uploadList
	/**
	 * A React useEffect hook that manages the upload queue and triggers uploads based on queue status.
	 * It also updates the counts of uploads in progress, completed, and failed.
	 */
	useEffect(() => {
		if (uploadIntoQueueProvider.uploadData) {
			let processingData = { ...uploadIntoQueueProvider.uploadData }
			uploadIntoQueueProvider.setUploadData()
			let uploadMetaData = {
				site_id: processingData.site_id,
				patient_id: processingData.patient_id,
				id: processingData.id,
				status: "InQueue",
			}
			uploadQueueProvider.setQueueStatus(uploadMetaData.status)
			setQueue(queue => [...queue, processingData])
			uploadQueueProvider.setQueueLength(queue.length)
			setUploadList(uploadList => [uploadMetaData, ...uploadList])
		}
	}, [uploadIntoQueueProvider.uploadData])

	function resetForNextUpload() {
		setTimeDisplay()
		setPercentUploaded(0)
		setQueuePercentUploaded(0)
		setRecoilFileHashed(0)
		setRecoilFileUploaded(0)
		setRecoilTotalHashFiles(0)
		setRecoilTotalUploadFiles(0)
		setTimeRemaining()
		setRecoilUploadFailed(false)
		uploadQueueProvider.setVerticalTimelineData(defaultVerticalTimelineData)
	}

	useEffect(() => {
		setUploadsInProgress(0)
		setUploadsCompleted(0)
		setUploadsFailed(0)
		uploadList.map((data) => {
			if (data.status === "Hashing" || data.status === "Uploading" || data.status === "InQueue")
				setUploadsInProgress(uploadsInProgress => (uploadsInProgress + 1))
			else if (data.status === "Done")
				setUploadsCompleted(uploadsCompleted => (uploadsCompleted + 1))
			else if (data.status === "Failed")
				setUploadsFailed(uploadsFailed => (uploadsFailed + 1))
		})
		// As long as there is upload pending in the queue, it will runs upload process. When an upload is done, we dequeue it and run the next upload, eventually the queue will be emptied
		if (queue.length > 0 && isUploading === false) {
			authProvider.setEnableCheckActivity(false)
			uploadQueueProvider.setUploadInProgress(true)
			uploadQueueProvider.setQueueLength(queue.length)
			setIsUploading(true)
			resetForNextUpload()
			let processingMetaData = null;
			for (let i = 0; i < uploadList.length; i++) {
				if (uploadList[i].id === queue[0].id) {
					processingMetaData = uploadList[i]
					break
				}
			}
			uploadQueueProvider.setPatientID(queue[0].patient_id)
			uploadQueueProvider.setSelectedSiteID(queue[0].site_id)
			const totalBytes = getTotalBytes(getAllFiles(queue[0]))
			const totalFile = getAllFiles(queue[0]).length
			setRecoilTotalHashFiles(FORM ? totalFile + 1 : totalFile)
			setRecoilTotalUploadFiles(FORM ? totalFile + 1 : totalFile)
			uploadIntoQueueProvider.setFolderID(queue[0].id)
			handleUpload(queue[0], processingMetaData, 0, totalBytes, undefined, undefined, totalFile).then(() => {
				setIsUploading(false)
				setQueue((prevQueue) => prevQueue.slice(1));
				delete queue[0]
				queue.length -= 1
				uploadQueueProvider.setQueueLength(queue.length)
			})
			uploadIntoQueueProvider.setUploadID()
		} else if (queue.length <= 0) {
			setEnableCloseQueue(true)
			authProvider.setEnableCheckActivity(true)
			uploadQueueProvider.setUploadInProgress(false)
		}
	}, [queue])

	const toggleExpand = () => {
		setIsExpand(!isExpand)
	}

	const handleView = () => {
		//Open loading page
		uploadIntoQueueProvider.setOpenLoading(true)
	}

	useEffect(() => {
		// Update the upload progress message
		let totalUploads = uploadList.map((data, index) => {
			return index
		})
		if (uploadsInProgress === 1) {
			setUploadProgressMessage(`${uploadsInProgress} upload in progress...`)
		} else if (uploadsInProgress > 1) {
			setUploadProgressMessage(`${uploadsInProgress} uploads in progress...`)
		} else if (uploadsInProgress === 0 && uploadsCompleted === 0 && uploadsFailed === 0) {
			setUploadProgressMessage(`${uploadsInProgress} uploads in progress...`)
		} else if (uploadsCompleted === 1 && uploadsFailed === 1) {
			setUploadProgressMessage(`${uploadsCompleted} upload complete, ${uploadsFailed} upload failed`)
		} else if (uploadsCompleted === 1 && uploadsFailed > 1) {
			setUploadProgressMessage(`${uploadsCompleted} upload complete, ${uploadsFailed} uploads failed`)
		} else if (uploadsCompleted > 1 && uploadsFailed === 1) {
			setUploadProgressMessage(`${uploadsCompleted} uploads complete, ${uploadsFailed} upload failed`)
		} else if (uploadsCompleted > 1 && uploadsFailed > 1) {
			setUploadProgressMessage(`${uploadsCompleted} uploads complete, ${uploadsFailed} uploads failed`)
		} else if (uploadsCompleted === 1 && uploadsFailed === 0) {
			setUploadProgressMessage(`${uploadsCompleted} upload complete`)
		} else if (uploadsCompleted > 1 && uploadsFailed === 0) {
			setUploadProgressMessage(`${uploadsCompleted} uploads complete`)
		} else if (uploadsCompleted === 0 && uploadsFailed === 1) {
			setUploadProgressMessage(`${uploadsFailed} upload failed`)
		} else if (uploadsCompleted === 0 && uploadsFailed > 1) {
			setUploadProgressMessage(`${uploadsFailed} uploads failed`)
		}

		if (uploadsCompleted === totalUploads.length || queue.length === 0) {
			setEnableCloseQueue(true)
		} else {
			setEnableCloseQueue(false)
		}
	}, [uploadsInProgress, uploadsCompleted, uploadsFailed, uploadList])

	async function handleBackOnline() {
		setUploadsInProgress(uploadsInProgress => uploadsInProgress - 1)
		if (queue[0]) {
			for (let i = 0; i < uploadList.length; i++) {
				if (uploadList[i].id === queue[0].id) {
					uploadList[i].status = "Failed"
					break
				}
			}
			uploadIntoQueueProvider.undoExistingValidation(queue[0].id)
			setIsUploading(false)
			setQueue(prevQueue => prevQueue.slice(1))
			delete queue[0]
		}
		if (uploadQueueProvider.uploadID) {
			await cancelUpload()
			await cleanUpCanceledUpload()
		}
	}

	useOnlineStatus({
		onOnline: () => console.log("Internet Connection is back"),
		onOffline: () => alert("Internet Connection was cut off"),
	})

	useEffect(() => {
		//Immediately set the upload to cancel on the GUI
		if (uploadQueueProvider.cancelGUI === true) {
			setUploadsInProgress(uploadsInProgress => uploadsInProgress - 1)
			if (queue[0]) {
				for (let i = 0; i < uploadList.length; i++) {
					if (uploadList[i].id === queue[0].id) {
						uploadList[i].status = "Canceled"
						break
					}
				}
				uploadIntoQueueProvider.undoExistingValidation(queue[0].id)
			}
			setIsUploading(false)
			uploadQueueProvider.setQueueLength(queue.length)
			uploadQueueProvider.setCancelGUI(false)
		}
	}, [uploadQueueProvider.cancelGUI])

	// function renderUploadFailedModal() {
	// 	createModal(<UploadFailedModal escClose={false} clickOutsideClose={false} />)
	// }

	//Close queue only enabled when there is no pending upload and will delete any remnant in the queue
	function closeQueue() {
		uploadQueueProvider.setCloseQueue(true)
		setUploadList([])
		setPercentUploaded(0)
		setQueuePercentUploaded(0)
		setTimeRemaining()
		uploadQueueProvider.setPatientID("")
		uploadQueueProvider.setSelectedSiteID("")
		uploadQueueProvider.setVerticalTimelineData(defaultVerticalTimelineData)
		uploadQueueProvider.setCancelGUI(false)
		setQueue([])
		let body = {
			upload_id: uploadIntoQueueProvider.uploadID,
		}
		return endpointProvider.post(LINKS.DATA.UPLOAD.CANCEL_UPLOAD, body)
	}

	return (
		<>
			<div>
				<UploadQueueContainer style={{ display: uploadQueueProvider.closeQueue ? "none" : "block" }}>
					<UploadQueueHeader>
						{uploadProgressMessage}
						<Button onClick={toggleExpand} style={{ marginRight: "20px", cursor: "pointer" }}>
							{isExpand ? <MdIcons.MdOutlineExpandMore size={22} /> : <MdIcons.MdOutlineExpandLess size={22} />}
						</Button>

						<Button
							onClick={closeQueue}
							style={{
								right: "20px",
								pointerEvents: enableCloseQueue ? "all" : "none",
								cursor: enableCloseQueue ? "pointer" : "none",
								opacity: enableCloseQueue ? "1" : "0.3",
							}}
						>
							<MdIcons.MdClose size={18} />
						</Button>
					</UploadQueueHeader>

					{isExpand && <UploadQueueContent>
						{uploadList.map((data, index) => (
							<UploadRowDiv key={index}>
								{data.status === "Done" ? <MdIcons.MdCheckCircle size={16} style={{ color: "#207DEA", marginRight: "8px" }} />
									: (data.status === "Failed") ? <MdIcons.MdAddCircle size={16} style={{ color: "#E54E58", marginRight: "8px", transform: "rotate(45deg)" }} />
										: (data.status === "Canceled") ? <MdIcons.MdDoNotDisturb size={16} style={{ color: "#E54E58", opacity: "0.3", marginRight: "8px" }} />
											: (data.status === "InQueue") ? <img src={loadingSpinner} alt="inQueue" style={{ width: "14px", marginLeft: "0px", marginRight: "10px" }} />
												: <p style={{ color: "#207DEA", marginRight: "4px", fontFamily: "Montserrat", fontSize: "12px", marginLeft: "-14px", width: "34px", textAlign: "right" }}>{queuePercentUploaded}%</p>}
								<p style={{ color: (data.status === "InQueue") ? "#AEB7C6" : data.status !== "Done" ? "#5F6775" : data.status === "Canceled" ? "#E54E58" : data.status === "Failed" ? "#E54E58" : "#293241", opacity: data.status === "Canceled" ? "0.3" : "1" }}>
									{`Patient_${data.site_id}_${data.patient_id}`.length > 20 ?
										`Patient_${data.site_id}_${data.patient_id}`.slice(0, 20) + "..." :
										`Patient_${data.site_id}_${data.patient_id}`}
								</p>
								{(queue[0]) && (data.id === queue[0].id) && (data.status !== "Failed" && data.status !== "Canceled") && (queue.length > 0) && (
									<div onClick={handleView} style={{ marginLeft: "8px", cursor: "pointer", color: "#AEB7C6", textDecorationLine: "underline" }}>View</div>
								)}
								<div style={{ position: "absolute", right: "18px" }}>
									{(data.status === "InQueue" && data.status === "Canceled" === false) ? (<p style={{ color: "#AEB7C6" }}>Next In Queue {(data != queue[0]) && <MdIcons.MdDelete size={14} style={{ color: "#E54E58", opacity: "0.3", cursor: "pointer" }} onClick={() => { renderStopUploadModal(data) }} />}</p>)
										: (data.status === "Done" ? (<p style={{ color: "#207DEA" }}>Completed</p>)
											: (data.status === "Failed") ? <p style={{ color: "#E54E58", cursor: "pointer", textDecorationLine: "underline" }} onClick={() => { handleView() }}>Upload failed</p>
												: data.status === "Canceled" ? <p style={{ color: "#E54E58", opacity: "0.3" }}>Upload canceled</p>
													: (<p><MdIcons.MdOutlineAccessTime size={16} style={{ marginRight: "6px" }} /><strong>{timeDisplay}</strong>  </p>))}
								</div>
							</UploadRowDiv>
						))}
					</UploadQueueContent>}
				</UploadQueueContainer>
			</div>
		</>
	)
}

const UploadQueueHeader = styled.div`
	width: 495px;
	height: 40px;
	background: rgba(32, 125, 234, 0.2);
	display: flex;
	position: relative;
	border-radius: 8px 8px 0px 0px;
	font-family: "Montserrat" !important;
	font-style: normal;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 21px;
	color: #293241;
	text-align: left;
	padding: 9px 18px;
`

const UploadQueueContent = styled.div`
	background: #ffffff;
	max-height: 180px;
	overflow-y: scroll;
	scrollbar-width: thin;
	::-webkit-scrollbar {
		display: block;
		width: 5px;
		color: #313a4a;
	}
	::-webkit-scrollbar-track {
		background: #bec4cf;
		width: 5px;
		border-radius: 2px;
	}
`

const Button = styled.button`
	background: none;
	color: #293241;
	position: absolute;
	right: 20px;
	border: none;
`

const UploadRowDiv = styled.div`
	display: flex;
	width: 100%;
	height: 36px;
	padding: 8px 18px;
	position: relative;
	border: 1px solid #e2e2e2;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 150%;
	color: #293241;
`

const UploadQueueContainer = styled.div`
	position: absolute;
	bottom: 0px;
	right: 40px;
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.15);
	width: 495px;
	height: fit-content;
`

export default UploadQueue
