import { TimeSeriesPageQueue } from "./TimeSeriesDataQueue"
import { ScaleTime, scaleTime } from "d3"
import { Page } from "./Page"
import { debounce, isEqual, throttle } from "lodash"
import { ModalityPage } from "./ModalityPage"
import { zonedTimeToUtc } from "date-fns-tz"
import { ModalityDataSource } from "../Types/ModalityDataSource"

export interface TimeSeriesPageManagerConfig {
	patientId: string
	windowId: string
	dataObjectId: string
	modalityDataSources: ModalityDataSource[]
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	timeZone: string
}

export class TimeSeriesPageManager<PageType extends Page<any>, ConfigType extends TimeSeriesPageManagerConfig = TimeSeriesPageManagerConfig> {
	protected pageQueue = new TimeSeriesPageQueue<PageType>()
	protected config: ConfigType
	protected pages: PageType[] = []

	private pageLoadOrder = Array(this.getNumberOfCachedPages() + 1).fill(0).map((_, i) => Math.floor((i * (-1) ** i) / 2) * -1)
	private neighborhood = Array(this.getNumberOfCachedPages() + 1)
	private pagesInView = Array(2)

	constructor() {
		const defaultConfig: TimeSeriesPageManagerConfig = {
			patientId: "",
			windowId: "",
			dataObjectId: "",
			modalityDataSources: [],
			viewScale: scaleTime(),
			fileScale: scaleTime(),
			timeZone: "America/New_York"
		}

		this.config = defaultConfig as ConfigType
	}

	public update = (config: ConfigType, onPageLoaded: (page: PageType) => void) => {
		let oldModalities = this.config ? [...this.config.modalityDataSources] : []
		this.config = config

		if (this.pages.length === 0) {
			this.resetPages()
		}

		const sortFn = (a: ModalityDataSource, b: ModalityDataSource) => a.modality < b.modality ? 1 : -1
						
		if (!isEqual(this.config.modalityDataSources.sort(sortFn), oldModalities.sort(sortFn))) {
			this.pages.forEach(page => page.modalityDataSources = config.modalityDataSources)
		}

		this.pageQueue.onSuccess = (page: PageType) => onPageLoaded(page)

		this.requestLoad()
	}

	public unloadAllPages = () => {
		this.pages.forEach(page => page.unload())
		this.pages.length = 0
	}

	public resetPages = () => {
		this.pages.forEach(page => page.unload())

		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		const fileEndTime = this.config.fileScale.domain()[1].getTime()
		const timeZoneOffsetMs = zonedTimeToUtc(this.config.fileScale.domain()[0], this.config.timeZone).getTime() - fileStartTime
		const pageDuration = this.config.viewScale.domain()[1].getTime() - this.config.viewScale.domain()[0].getTime()
		const widthInPixels = this.config.viewScale.range()[1]

		if (widthInPixels <= 0) {
			this.pages = []
		} else {
			this.pages = this.generatePages(fileStartTime, fileEndTime, pageDuration, widthInPixels, this.config.modalityDataSources, timeZoneOffsetMs)
		}
	}

	public onEndDateUpdated = (endDate: Date) => {
		const lastPage = this.pages[this.pages.length - 1]
		const [start, end] = this.config.viewScale.domain()
		const viewDuration = end.getTime() - start.getTime()
		const newEndTime = endDate.getTime()

		const updateLastPage = (endTime: number) => {
			lastPage.endTime = endTime
			lastPage.width = this.config.viewScale(endTime) - this.config.viewScale(lastPage.startTime)
			lastPage.unloadWithoutClearingCache()
		}

		if (newEndTime - lastPage.startTime <= viewDuration) {
			// We can simply update the last page with the new end time and reload it.
			updateLastPage(newEndTime)
		} else {
			// It's possible that there are a number of pages that need to be created and then loaded.
			// First, finish the last page, if it is a partial page.
			if (lastPage.endTime - lastPage.startTime < viewDuration) {
				updateLastPage(lastPage.startTime + viewDuration)
			}

			// Then, generate pages up to the new time, using the last page's end time as a starting point.
			const newPages = this.generatePages(lastPage.endTime, newEndTime, viewDuration, lastPage.width, lastPage.modalityDataSources, lastPage.timeZoneOffsetMs, this.pages.length)
			this.pages.push(...newPages)
		}
	}

	protected getNumberOfCachedPages() { return 30 }

	private updateQueue = () => {
		const neighborhood = this.getNeighborhood()
		const notLoadedPages = neighborhood.filter(page => !page.loaded)
		this.pageQueue.push(notLoadedPages) 
		this.pageQueue.unloadOutside(neighborhood)
	}

	// throttle allows us to update regularly, but control how many.
	private throttledUpdate = throttle(this.updateQueue, 100)

	// debounce makes sure that a final load gets called when we stop requesting loads.
	private debouncedUpdate = debounce(this.updateQueue, 200)

	public requestLoad = () => {
		this.throttledUpdate()
		this.debouncedUpdate()
	}

	public clearQueue = () => {
		this.pageQueue.clear()
	}

	public clearQueueAndLoad = () => {
		this.clearQueue()
		this.requestLoad()
	}

	public getUnloadedRegions(): [number, number][] {
		const regions: [number, number][] = []

        const pagesLoaded = this.getNeighborhood().filter(page => page.loaded).sort((a, b) => a.startTime < b.startTime ? -1 : 1)
        const length = pagesLoaded.length
		const minStartTime = this.config.fileScale.domain()[0].getTime()
		const maxEndTime = this.config.fileScale.domain()[1].getTime()

        if (length > 0) {                     
            regions.push([minStartTime, pagesLoaded[0].startTime]) // the unloaded region from the display start to the beginning of the loaded pages
            let lastEndTime = pagesLoaded[0].startTime

            pagesLoaded.forEach(page => { 
                if (lastEndTime !== page.startTime){
                    regions.push([lastEndTime, page.startTime]) // insert new unloaded region from the last loaded page to the begining of this page
                }

                lastEndTime = page.endTime                        
            })

			// Sometimes, the page end time is actually past the current file end time.
			if (pagesLoaded[length - 1].endTime <= maxEndTime) {
				regions.push([pagesLoaded[length - 1].endTime, maxEndTime])  // the unloaded region from the end of the loaded region to the end of the display 
			}

        } else {
			regions.push([minStartTime, maxEndTime]) // nothing is loaded
		}

		return regions
	}
	
	// TODO: this function is slow and blocks the UI when there are tons of pages.
	protected generatePages = (startTime: number, endTime: number, pageDuration: number, pageWidth: number, modalityDataSources: ModalityDataSource[], timeZoneOffsetMs: number, startAtIndex=0) => {
		const pages = []

		let start = startTime
		let end = startTime + pageDuration
		let idx = startAtIndex

		while (start < endTime) {
			const width = end < endTime ? pageWidth : Math.floor(((endTime - start) * pageWidth) / pageDuration)
			const new_page = new ModalityPage(start, end < endTime ? end : endTime, idx++, width, modalityDataSources, this.config.patientId, timeZoneOffsetMs, this.config.windowId) as unknown as PageType
			
			pages.push(new_page)

			start += pageDuration
			end += pageDuration
		}

        return pages
	}

	public getPagesInView(): (PageType | undefined)[] {
		const currentIndex = this.currentPageIndex()
		this.pagesInView[0] = this.pages[currentIndex]
		this.pagesInView[1] = (currentIndex < this.pages.length - 1) ? this.pages[currentIndex + 1] : undefined

		return this.pagesInView
	}

	public getPageBeforeView(): PageType | undefined {
		return this.pages[this.currentPageIndex() - 1]
	}

	public getPageAfterView(): PageType | undefined {
		return this.pages[this.currentPageIndex() + 2]
	}

	public getAllLoadedPages(): Map<number, PageType> {
		return this.pageQueue.allLoadedPages
	}

	public currentPageIndex(): number {
		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const pageDuration = this.config.viewScale.domain()[1].getTime() - viewStartTime
		return Math.max(Math.floor((viewStartTime - fileStartTime) / pageDuration), 0)
	}

	private getNeighborhood(): PageType[] {
		const pageIndex = this.currentPageIndex()

		for (let i = 0; i < this.pageLoadOrder.length; i++) {
			this.neighborhood[i] = this.pages[this.pageLoadOrder[i] + pageIndex]
		}

		return this.neighborhood.filter(page => page !== undefined)
	}
}
