import React from 'react'
import { useController } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import { MobergDropdown, MobergDropdownSize } from '../MobergDropdown/MobergDropdown'

export default function DropdownField(props) {
	const { name, control, label, tooltip, inputId, autoFocus, disabled = false, options = [], numberColumn, size: dropdownSize } = props

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	const transformedOptions = options.map(option => ({
		value: option.value,
		label: option.label,
	}))

	const handleChange = (selectedValue) => {
		onChange(selectedValue)
	}

	return (
		<div style={{ margin: '0px 40px', display: 'flex', flex: 1 }}>
			<InputWrapper inputId={inputId} label={label} tooltip={tooltip} error={error} numberColumn={numberColumn}>
				<div style={{ margin: '30px 0px', display: 'flex', flex: 1 }}>
					<MobergDropdown
						options={transformedOptions}
						onChange={handleChange}
						selectedValue={value}
						size={dropdownSize || MobergDropdownSize.REGULAR}
						width={'100%'}
						ref={ref}
					/>
				</div>
			</InputWrapper>
		</div>

	)
}
