import { useRecoilValue, useSetRecoilState } from "recoil"
import { AreaConfig, Layout, LayoutGroup } from "../Pages/Data/Visualize/DataReview/Types/Layout"
import { editedLayoutGroupAtom, layoutGroupsAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { useEndpointProvider } from "../Providers/EndpointProvider"
import { LINKS } from "../Constants/BackendLinks"
import { layoutOptions } from "../Managers/VisualizationManager/ToolBar/Modals/LayoutTemplateSelector"
import { VisualizationComponent } from "../Pages/Data/Visualize/DataReview/VisualizationComponentFactory"
import { getDefaultVisualizationProps } from "../Pages/Data/Visualize/DataReview/Atoms/Visualizations"
import { useModalProvider } from "../Providers/ModalProvider"
import React from "react"
import { ConfigureLayoutModal } from "../Managers/VisualizationManager/ToolBar/Modals/ConfigureLayoutModal"

type LayoutServiceAPI = {
	// Layout Groups (Display Groups)
	createLayoutGroup: (type: string, layoutGroup?: LayoutGroup) => Promise<LayoutGroup>,
	deleteLayoutGroup: (layoutGroupId: string | undefined) => Promise<any> ,
	modifyLayoutGroup: (layoutGroupId: string, newLayoutGroup: Partial<LayoutGroup>) => Promise<any>,
	copyLayoutGroup: (layoutGroup: LayoutGroup, type: string) => Promise<any>,

	// Layouts (Displays)
	addLayoutToLayoutGroup: (layoutGroup: LayoutGroup, layout?: Layout) => Promise<void>,
	removeLayoutFromLayoutGroup: (layoutGroup: LayoutGroup, layoutId: string) => Promise<any>,
	copyLayoutForLayoutGroup: (layoutGroup: LayoutGroup, layoutId: string, properties?: any) => Promise<any>,

	// Windows
	updateWindowProperty: (property: string, layoutId: string, windowId: string, newValue: any) => void,

	// Modals
	resetEditedLayoutGroup: (layoutId: string) => void,
	openConfigureLayoutModal: (layoutId: string) => void ,
}

export const useLayoutService = (): LayoutServiceAPI => {
	const endpointProvider = useEndpointProvider()
	const setLayoutGroups = useSetRecoilState(layoutGroupsAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const { createModal } = useModalProvider()

	async function createLayoutGroup(type: string, layoutGroup?: LayoutGroup): Promise<LayoutGroup> {
		const newLayoutGroup = {
			id: `display-group-${Math.random()}-${new Date(Date.now()).toISOString()}`,
			name: "New display group",
			type: type.toLowerCase(),
			layouts: [
				{
					id: `layout-${Math.random()}-${new Date(Date.now()).toISOString()}`,
					name: "New display",
					cssGridTemplate: [["a"]],
					areas: [{
						area: "a",
						componentId: VisualizationComponent.TIME_SERIES_GROUP,
						props: getDefaultVisualizationProps(VisualizationComponent.TIME_SERIES_GROUP)
					}]
				}
			],
			...layoutGroup,
		}

		const body = {
			name: newLayoutGroup.name,
			layouts: newLayoutGroup.layouts,
			layout_type: type.toLowerCase(),
		}

		return endpointProvider
			.post(LINKS.DATA.PROFILING.CREATE_LAYOUT, body)
			.then(data => {
				setLayoutGroups(previous => [...previous, { ...newLayoutGroup, id: data.id }])
				return data
			})
			.catch(error => alert(error))
	}

	async function deleteLayoutGroup(layoutGroupId: string | undefined): Promise<any> {
		let body = {
			layout_id: layoutGroupId,
		}

		return endpointProvider
			.post(LINKS.DATA.PROFILING.DELETE_LAYOUT, body)
			.then(() => {
				setLayoutGroups(previous => [...previous].filter(layout => layout.id !== layoutGroupId))
			})
			.catch(error => alert(error))
	}

	async function modifyLayoutGroup(layoutGroupId: string, newLayoutGroup: Partial<LayoutGroup>): Promise<any> {
		const body = {
			name: newLayoutGroup.name,
			layouts: newLayoutGroup.layouts,
			layout_type: newLayoutGroup.type,
			layout_id: layoutGroupId,
		}

		return endpointProvider
			.post(LINKS.DATA.PROFILING.MODIFY_LAYOUT, body)
			.then(() =>
				setLayoutGroups(previous =>
					previous.map(layoutGroup => {
						if (layoutGroup.id === layoutGroupId) {
							return {
								...layoutGroup,
								...newLayoutGroup,
							}
						}
						return layoutGroup
					})
				)
			)
			.catch(error => alert(error))
	}

	function copyLayoutGroup(layoutGroup: LayoutGroup, type: string): Promise<any> {
		const deepCopyLayoutGroup = structuredClone(layoutGroup)
		deepCopyLayoutGroup.id += "-" + new Date(Date.now()).toISOString()
		deepCopyLayoutGroup.type = type
		deepCopyLayoutGroup.layouts.forEach(layout => (layout.id += "-" + new Date(Date.now()).toISOString()))

		return createLayoutGroup(type, deepCopyLayoutGroup)
	}

	function updateWindowProperty(property: string, layoutId: string, windowId: string, newValue: any): void {
		setEditedLayoutGroup((previous: LayoutGroup | null) => {
			if (!previous) {
				return previous
			}

			const newLayoutGroup = {
				...previous,
				layouts: previous.layouts.map(layout => {
					if (layout.id === layoutId) {
						return {
							...layout,
							areas: layout.areas.map((window: AreaConfig) => {
								if (window.area === windowId) {
									// have to do this for immutability
									const newProps = structuredClone(window.props)

									// Allows deep paths, for instance "eegConfig.overlay.LFF"
									const keys = property.split(".")
									let current: any = newProps

									// Go through the deep path
									for (let i = 0; i < keys.length - 1; i++) {
										const temp = current[keys[i]]

										if (!temp) {
											// create the nested object if it doesn't exist
											current = current[keys[i]] = {}
										} else {
											current = temp
										}
									}

									current[keys[keys.length - 1]] = newValue

									return {
										...window,
										props: newProps,
									}
								}
								return window
							}),
						}
					}
					return layout
				}),
			}

			return newLayoutGroup
		})
	}

	function addLayoutToLayoutGroup(layoutGroup: LayoutGroup, layout?: Layout): Promise<void> {
		if (layoutGroup.layouts.length > 9) {
			return new Promise((_, reject) => reject("Refused: Cannot have more than 10 layout groups."))
		}

		let newLayout = layout

		if (!newLayout) {
			newLayout = {
				id: `display-${new Date(Date.now()).toISOString()}`,
				name: "New display",
				cssGridTemplate: layoutOptions[0].cssGridTemplate,
				areas: layoutOptions[0].areas.map(area => ({
					...area,
					componentId: VisualizationComponent.TIME_SERIES_GROUP,
					props: getDefaultVisualizationProps(VisualizationComponent.TIME_SERIES_GROUP),
				})),
			}
		}

		const modifiedLayoutGroup = { ...layoutGroup, layouts: [...layoutGroup.layouts, newLayout] }

		return modifyLayoutGroup(layoutGroup.id, modifiedLayoutGroup)
	}

	function removeLayoutFromLayoutGroup(layoutGroup: LayoutGroup, layoutId: string): Promise<any> {
		const modifiedLayoutGroup = {
			...layoutGroup,
			layouts: layoutGroup.layouts.filter(layout => layout.id !== layoutId),
		}

		return modifyLayoutGroup(layoutGroup.id, modifiedLayoutGroup)
	}

	function copyLayoutForLayoutGroup(layoutGroup: LayoutGroup, layoutId: string, properties?: any): Promise<any> {
		let layout = layoutGroup.layouts.find(layout => layout.id === layoutId)

		if (!layout) {
			throw new Error("Layout not found: " + layoutId)
		}

		layout = { ...layout }

		layout.id = new Date(Date.now()).toISOString()

		if (properties) {
			Object.assign(layout, properties)
		}

		const modifiedLayoutGroup = {
			...layoutGroup,
			layouts: [...layoutGroup.layouts, layout],
		}

		return modifyLayoutGroup(layoutGroup.id, modifiedLayoutGroup)
	}

	function openConfigureLayoutModal(layoutId: string): void {
		resetEditedLayoutGroup()
		createModal(<ConfigureLayoutModal layoutId={layoutId} />)
	}

	function resetEditedLayoutGroup() {
		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)
	}

	return {
		createLayoutGroup,
		deleteLayoutGroup,
		modifyLayoutGroup,
		copyLayoutGroup,
		addLayoutToLayoutGroup,
		removeLayoutFromLayoutGroup,
		copyLayoutForLayoutGroup,
		updateWindowProperty,
		resetEditedLayoutGroup,
		openConfigureLayoutModal,
	}
}
