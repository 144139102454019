export let LABEL_SETTINGS = {
    "ABP_Dias": {'label': 'Diastolic ABP', "min": 50, "max": 120, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ABP_Mean": {'label': 'Mean ABP', "min": 50, "max": 150, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ABP_Syst": {'label': 'Systolic ABP', "min": 90, "max": 180, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ABP_na": {'label': 'ABP Waveform', "min": 50, "max": 200, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ART_na": {'label': 'ART Waveform', "min": 50, "max": 200, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ART_Dias": {'label': 'Diastolic ABP', "min": 50, "max": 120, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ART_Syst": {'label': 'Systolic ABP', "min": 90, "max": 180, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ART_Mean": {'label': 'Mean ABP', "min": 50, "max": 150, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "CPP_na": {'label': 'CPP Waveform', "min": 50, "max": 120, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EtCO2_na": {'label': 'EtCO2 Waveform', "min": 35, "max": 45, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "HR_na": {'label': 'HR', "min": 40, "max": 150, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ICP_Mean": {'label': 'Mean ICP', "min": 0, "max": 40, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 200},
    "ICP_na": {'label': 'Mean ICP', "min": 0, "max": 40, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 200},
    "RR_na": {'label': 'RR', "min": 18, "max": 25, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "SpO2_na": {'label': 'SpO2 Waveform', "min": 85, "max": 100, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "DEFAULT": {'label': 'ADD ME', "min": 0, "max": 100, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 50},
    "EEG_C3": {'label': 'EEG C3', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_C3-Cz": {'label': 'EEG C3-Cz', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_C4": {'label': 'EEG_C4', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_C4-T4": {'label': 'EEG C4-T4', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_Composite": {'label': 'Composite EEG', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_Cz": {'label': 'EEG Cz', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_Cz-C4": {'label': 'Diastolic ABP', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_F3": {'label': 'EEG F3', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_F4": {'label': 'EEG F4', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_F7": {'label': 'EEG F7', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_F8": {'label': 'EEG F8', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_Fp1": {'label': 'EEG Fp1', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_Fp2": {'label': 'EEG Fp2', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_O1": {'label': 'EEG O1', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_O2": {'label': 'EEG O2', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_T3": {'label': 'EEG T3', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_T3-C3": {'label': 'EEG T3-C3', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_T4": {'label': 'EEG T4', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_T5": {'label': 'EEG T5', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "EEG_T6": {'label': 'EEG T6', "min": -4000, "max": 6000, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "ECG_II": {'label': 'ECG II', "min": 0, "max": 1, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "RESP_na": {'label': 'Resp', "min": 0, "max": 100, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "PLENTH_na": {'label': 'Plenth', "min": 0, "max": 1, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 100},
    "Sodium_Chloride": {'label': 'Sodium Chloride', "min": 0, "max": 30, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 50},
    "Fentanyl": {'label': 'Fentanyl', "min": 0, "max": 30, "delta": 30, "translation": 10, 'color': '#ff0000', 'graph_height': 50},
    'albuterol_sulfate': {'label': 'Albuterol Sulfate', "min": -5, "max": 5, "delta": 10, "translation": 10, 'color': '#000000', 'graph_height': 50},
    'acetaminophen': {'label': 'Acetaminophen', "min": 630, "max": 680, "delta": 30, "translation": 10, 'color': '#ff0000', 'graph_height': 50},
}

