import { D3DragEvent } from "d3"
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "../../Data/TimeSeriesPageManager"
import { Page } from "../../Data/Page"
import { allWindowsDispatch, linkedWindowsDispatch } from "../../Atoms/Visualizations"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { MIN_WINDOW_TIME_MS } from "../D3/D3UTCAxis"
import { debounce } from "lodash"
import { LinkedWindowUpdateEvent, LinkedWindowUpdateOptions } from "../../Types/LinkedWindowInfo"
import { TimeBasedVisualizationConfig } from "../../Types/TimeBasedVisualizationConfig"
import { D3VisualizationRenderer } from "./D3VisualizationRenderer"
import { TraceConfigJSON } from "../../Types/Trace"
import { WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { ModalityDataSource } from "../../Types/ModalityDataSource"

type GoToEndOptions = {
	waitForTimelinesToStopPlaying: boolean
}

export abstract class D3TimeBasedVisualization<
	Config extends TimeBasedVisualizationConfig,
	Callbacks extends ReactCallbacks<Config>,
	Renderer extends D3VisualizationRenderer<any, any>,
	PageManager extends TimeSeriesPageManager<any, any>
> {
	public root: HTMLDivElement
	public config: Config
	public reactCallbacks: Callbacks
	public timeSeriesPageManager: PageManager

	protected renderer?: Renderer

	constructor(root: HTMLDivElement, config: Config, pageManager: PageManager, reactCallbacks: Callbacks) {
		this.root = root
		this.config = config
		this.reactCallbacks = reactCallbacks
		this.timeSeriesPageManager = pageManager
	}

	public abstract getVisibleTraces(): TraceConfigJSON[]

	protected abstract renderPage(page: Page<any>): void
	protected abstract getModalityDataSources(): ModalityDataSource[]
	protected abstract updateDerivedState(): void
	protected onPageLoadedDecorator() {}

	public catchUpPages(endDate: Date): void {
		this.timeSeriesPageManager.onEndDateUpdated(endDate)
	}

	// PUBLIC

	public render() {
		this.renderer?.render()
	}

	public liveEndDateUpdated(endDate: Date) {
		const [start,] = this.config.fileScale.domain()
		this.config.fileScale.domain([start, endDate])

		this.renderer?.liveEndDateUpdated()
		this.catchUpPages(endDate)
		this.goToEnd({ waitForTimelinesToStopPlaying: false })
	}

	public updateConfig(config: Config) {
		this.config = config
		this.updateDerivedState()
		this.timeSeriesPageManager.update(this.getPageManagerConfig(), this.onPageLoaded)
		this.render()
	}

	public unmount() {
		this.timeSeriesPageManager.clearQueue()
		this.renderer?.timeline?.stop()
		linkedWindowsDispatch.on(`update.${this.config.id}`, null)
		allWindowsDispatch.on(`jump.${this.config.id}`, null)
	}

    public clearDataAndReload = () => {
        this.timeSeriesPageManager.resetPages()
        this.timeSeriesPageManager.clearQueueAndLoad()
        this.render()
    }

	public clearCacheAndRedraw = () => {
		this.timeSeriesPageManager.getAllLoadedPages().forEach((page: Page<any>) => page.clearRenderCache())
        this.timeSeriesPageManager.clearQueueAndLoad()
        this.render()
    }

    public goToStart = () => {
		if (this.config.liveModeEnabled) {
			return
		}
		
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newStart = this.config.fileScale.domain()[0]
		const newEnd = new Date(newStart.getTime() + viewDuration)
		this.viewTimesChanged(newStart, newEnd)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public goToEnd = (options?: GoToEndOptions) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newEnd = this.config.fileScale.domain()[1]
		const newStart = new Date(newEnd.getTime() - viewDuration)
		const { start: clampedStart, end: clampedEnd } = this.getClampedStartAndEnd(newStart, newEnd)
		
		const finishUpdate = () => {
			this.viewTimesChanged(clampedStart, clampedEnd)
			this.updateLinkedWindows()
			this.timeSeriesPageManager.clearQueueAndLoad()
		}

		// If we don't wait for any timelines to stop playing, they'll set the time back to what it was before the jump.
		// This is only a problem when jumping to the end.
		if (options?.waitForTimelinesToStopPlaying || options?.waitForTimelinesToStopPlaying === undefined) {
			setTimeout(finishUpdate, 100)
		} else {
			finishUpdate()
		}
	}

	public goToPreviousPage = () => {
		if (this.config.liveModeEnabled) {
			return
		}

		const { startTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newStart = Math.max(this.config.fileScale.domain()[0].getTime(), startTime - viewDuration)
		const newEnd = newStart + viewDuration
		this.viewTimesChanged(newStart, newEnd)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public goToNextPage = () => {
		if (this.config.liveModeEnabled) {
			return
		}

		const { endTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const newEnd = Math.min(this.config.fileScale.domain()[1].getTime(), endTime + viewDuration)
		const newStart = newEnd - viewDuration
		this.viewTimesChanged(newStart, newEnd)
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows()
	}

	public zoomIn = () => {
		const { startTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const windowSizeOptionsLargeToSmall = [...this.getZoomOptions().filter(time => time > 0)].sort((a, b) => b - a)
		const nextSmallestWindowSize = windowSizeOptionsLargeToSmall.find(time => time < viewDuration) ?? windowSizeOptionsLargeToSmall[windowSizeOptionsLargeToSmall.length-1]
		const zoomDelta = (viewDuration - nextSmallestWindowSize) / 2
		const newStart = Math.min(this.config.fileScale.domain()[1].getTime() - MIN_WINDOW_TIME_MS, startTime + zoomDelta)
		const newEnd = Math.max(newStart + MIN_WINDOW_TIME_MS, newStart + nextSmallestWindowSize)

		// The React state update will handle everything for us.
		this.reactCallbacks.setRootConfig(previous => ({ ...previous, viewScale: this.config.viewScale.domain([newStart, newEnd]) }))
	}

	public zoomOut = () => {
		const { startTime, endTime, viewDuration } = this.getStartTimeEndTimeViewDuration()
		const windowSizeOptionsSmallToLarge = [...this.getZoomOptions().filter(time => time > 0)].sort((a, b) => a - b)
		const nextBiggestWindowSize = windowSizeOptionsSmallToLarge.find(preset => preset > viewDuration) ?? windowSizeOptionsSmallToLarge[windowSizeOptionsSmallToLarge.length-1]
		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		const fileEndTime = this.config.fileScale.domain()[1].getTime()

		let startZoomDelta = (nextBiggestWindowSize - viewDuration) / 2
		let endZoomDelta = startZoomDelta

		const nextStartTime = startTime - startZoomDelta

		if (nextStartTime < fileStartTime) {
			endZoomDelta += fileStartTime - nextStartTime
		}

		const nextEndTime = endTime + endZoomDelta

		if (endTime + endZoomDelta > fileEndTime) {
			startZoomDelta += nextEndTime - fileEndTime
		}

		const newStart = Math.max(this.config.fileScale.domain()[0].getTime(), startTime - startZoomDelta)
		const newEnd = Math.min(this.config.fileScale.domain()[1].getTime(), endTime + endZoomDelta)

		// The React state update will handle everything for us.
		this.reactCallbacks.setRootConfig(previous => ({ ...previous, viewScale: this.config.viewScale.domain([newStart, newEnd]) }))
	}

	public playPause = () => {
		this.renderer?.timeline?.playPause()
	}

	public updateLinkedWindows = (options?: LinkedWindowUpdateOptions) => {
		if (this.config.isLinked) {
			const event: LinkedWindowUpdateEvent = { startDate: this.config.viewScale.domain()[0], controller: this.config.id, options }
			linkedWindowsDispatch.call("update", undefined, event)
		}
	}

	public onPan = (event: D3DragEvent<SVGRectElement, any, any>) => {
		const [startDate, endDate] = this.config.viewScale.domain()

		const startTime = startDate.getTime()
		const endTime = endDate.getTime()
		const minStartTime = this.config.fileScale.domain()[0].getTime()
		const maxEndTime = this.config.fileScale.domain()[1].getTime()

		const timeDiff = endTime - startTime
		const width = this.config.viewScale.range()[1]
		const offsetTime = (-event.dx * (endTime - startTime)) / width

		let newStartTime = startTime + offsetTime
		let newEndTime = endTime + offsetTime

		// clamp to file end
		if (newEndTime > maxEndTime) {
			newStartTime = maxEndTime - timeDiff
			newEndTime = maxEndTime
		}

		// clamp to file start
		else if (newStartTime < minStartTime) {
			newStartTime = minStartTime
			newEndTime = minStartTime + timeDiff
		}

		this.config.viewScale.domain([newStartTime, newEndTime])
		this.timeSeriesPageManager.requestLoad()

		requestAnimationFrame(() => {
			this.viewTimesChanged(newStartTime, newEndTime)
			this.updateLinkedWindows()
		})
	}

	public getPageManagerConfig = (): TimeSeriesPageManagerConfig => {
		return {
			patientId: this.config.patientId,
			windowId: this.config.id,
			dataObjectId: this.config.dataObjectId,
			viewScale: this.config.viewScale,
			fileScale: this.config.fileScale,
			modalityDataSources: this.getModalityDataSources(),
			timeZone: this.config.timeZone
		}
	}

	public getStartTimeEndTimeViewDuration = () => {
		const [start, end] = this.config.viewScale.domain()
		const startTime = start.getTime()
		const endTime = end.getTime()
		const viewDuration = endTime - startTime
		return { startTime, endTime, viewDuration }
	}

	public onWheel = (event: WheelEvent) => {
		const [startDate, endDate] = this.config.viewScale.domain()

		const startTime = startDate.getTime()
		const endTime = endDate.getTime()
		const minStartTime = this.config.fileScale.domain()[0].getTime()
		const maxEndTime = this.config.fileScale.domain()[1].getTime()

		const timeDiff = endTime - startTime
		const offset = ((-1 * event.deltaY) / 500) * (endTime - startTime)

		let newStartTime = startTime + offset
		let newEndTime = endTime + offset

		// clamp to file end
		if (newEndTime > maxEndTime) {
			newStartTime = maxEndTime - timeDiff
			newEndTime = maxEndTime
		}
		// clamp to file start
		else if (newStartTime < minStartTime) {
			newStartTime = minStartTime
			newEndTime = minStartTime + timeDiff
		}

		// prevent unnecessary re-renders
		if (event.deltaY < 0 && endTime === maxEndTime) {
			return
		}

		// prevent unnecessary re-renders
		if (event.deltaY > 0 && startTime === minStartTime) {
			return
		}

		this.timeSeriesPageManager.requestLoad()
		this.onWheelStopped() // it's a debounced function so it only gets called after we stop wheeling.

		this.updateLinkedWindows()

		requestAnimationFrame(() => {
			this.viewTimesChanged(newStartTime, newEndTime)
		})
	}

	public onWheelStopped = debounce(() => {
		// this.reactCallbacks.setRootConfig(previous => ({
		// 	...previous,
		// 	viewStartDate: this.config.viewScale.domain()[0],
		// 	viewEndDate: this.config.viewScale.domain()[1],
		// }))
		this.timeSeriesPageManager.clearQueueAndLoad()
	}, 100)

	// PROTECTED

	protected getZoomOptions = (): number[] => WINDOW_TIME_PRESETS.map(preset => preset.time)

	protected mount(renderer: Renderer) {
		this.renderer = renderer

		linkedWindowsDispatch.on(`update.${this.config.id}`, this.onLinkedWindowsUpdate)
		allWindowsDispatch.on(`jump.${this.config.id}`, this.onJumpToTime)

		this.updateDerivedState()

		this.timeSeriesPageManager.update(this.getPageManagerConfig(), this.onPageLoaded)
		this.render()
	}

	protected anotherLinkedWindowIsPlaying = () => this.config.timelineController !== null && this.config.isLinked && this.config.timelineController !== this.config.id

	public viewTimesChanged = (start: Date | number, end: Date | number) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const startTime = new Date(start).getTime()
		const endTime = new Date(end).getTime()
		const calculatedViewDuration = endTime - startTime

		let startDate = new Date(start)
		let endDate = new Date(end)

		if (viewDuration !== calculatedViewDuration) {
			const { start: clampedStart, end: clampedEnd } = this.getClampedStartAndEnd(startDate, endDate)
			startDate = clampedStart
			endDate = clampedEnd

			// If the view duration has changed, we need to let React handle the update.
			// Otherwise, we end up reverting the duration back to the old duration.
			// This bug is caused by the updates from another component's timeline when it is playing.
			if (this.anotherLinkedWindowIsPlaying()) {
				return
			}
		}

		this.config.viewScale.domain([startDate, endDate])
		this.renderer?.viewTimesChanged()
		this.timeSeriesPageManager?.clearQueueAndLoad()
	}
   
	public onTimelineSliderDrag = () => {
		this.timeSeriesPageManager?.clearQueueAndLoad()
		this.renderer?.onTimelineSliderDrag()
		this.updateLinkedWindows()
	}

	public onTimelineSliderDragEnd = () => {
		this.timeSeriesPageManager.clearQueueAndLoad()
		this.updateLinkedWindows({ autoScale: true })
	}

	protected onJumpToTime = (event: LinkedWindowUpdateEvent) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const { start, end } = this.getClampedStartAndEnd(event.startDate, new Date(event.startDate.getTime() + viewDuration))

		requestAnimationFrame(() => this.viewTimesChanged(start, end))
	}

	protected onLinkedWindowsUpdate = (event: LinkedWindowUpdateEvent) => {
		if (this.config.isLinked && event.startDate && event.controller !== this.config.id) {
			this.onJumpToTime(event)
		}
	}

	protected onPageLoaded = (page: Page<any>) => {
		const currentIndex = this.timeSeriesPageManager.currentPageIndex()
		this.renderer?.timeline?.updateNotLoadedRegions()

		if (currentIndex !== undefined && (page.index === currentIndex || page.index === currentIndex + 1)) {
			page.clearRenderCache()
			this.renderPage(page)
			this.onPageLoadedDecorator()
		}
	}

	protected getClampedStartAndEnd(start: Date, end: Date) {
		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		const fileEndTime = this.config.fileScale.domain()[1].getTime()
		const startTime = start.getTime()
		const endTime = end.getTime()

		let startDelta = 0
		let endDelta = 0

		if (startTime < fileStartTime) {
			endDelta += fileStartTime - startTime
		}

		if (endTime > fileEndTime) {
			startDelta += endTime - fileEndTime
		}

		const adjustedStart = startTime - startDelta
		const adjustedEnd = endTime + endDelta

		const newStart = new Date(Math.max(fileStartTime, adjustedStart))
		const newEnd = new Date(Math.min(fileEndTime, adjustedEnd))

		return { start: newStart, end: newEnd }
	}
}
