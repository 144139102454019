import { TraceConfigJSON } from "./Trace"

export type HistogramJSON = {
    modalityConfigs: TraceConfigJSON[]
    binMinimum: number
    binMaximum: number
    binSize: number
    viewDuration: number
}

export const DEFAULT_HISTOGRAM_JSON: HistogramJSON = {
    modalityConfigs: [],
    binMinimum: 5,
    binMaximum: 95,
    binSize: 10,
    viewDuration: 60000
}