import { atomFamily } from "recoil";
import { SdDetectionConfig, SdDetectionJSON } from "../Types/SdDetection";
import { scaleTime } from "d3";
import { EEG_SENSITIVITY } from "../Types/EEG";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { SD_SENSITIVITY } from "../Types/SpreadingDepolarization";

export const DEFAULT_SD_DETECTION_JSON: SdDetectionJSON = {
    viewDuration: 60 * 60 * 1000,
    montage: "SD-Detection",
    eegConfig: {
        LFF: 1,
        HFF: 30,
        notch: 60,
        sensitivityMicroVolts: EEG_SENSITIVITY["50 uV"],
    },
    overlayEEGConfig: {
        LFF: 0.005,
        HFF: 0.5,
        notch: 60,
        sensitivityMicroVolts: SD_SENSITIVITY["440 uV"]
    },
    isLinked: false
}

export const SdDetectionConfigAtom = atomFamily<SdDetectionConfig, LayoutWindowId>({
    key: "SdDetectionConfig",
    default: {
        id: "?",
        patientId: "?",
        dataObjectId: "?",
        viewScale: scaleTime(),
        fileScale: scaleTime(),
        isLinked: false,
        montage: "SD-Detection",
        dimensions: {height: 0, width: 0},
        annotations: [],
		inProgressAnnotation: {
			id: "?",
			startDate: null,
			endDate: null,
			color: "red",
			opacity: 0.2,
			text: "",
			modalities: [],
			keyPressed: null,
		},
        playbackSpeed: 1,
        eegConfig: {
            LFF: 1,
            HFF: 30,
            notch: 60,
            sensitivityMicroVolts: EEG_SENSITIVITY["50 uV"],
        },
        overlayEEGConfig: {
            LFF: 0.005,
            HFF: 0.5,
            notch: 60,
            sensitivityMicroVolts: SD_SENSITIVITY["440 uV"]
        },
        timelineController: null,
        timeZone: "America/New_York",
        liveModeEnabled: false,
    }
})