import { useController } from "react-hook-form"
import { InputBase } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import InputWrapper from "./InputWrapper"

const CustomInput = styled(InputBase)(({ theme, error, changeDisabledTheme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
		border: "1px solid",
		borderColor: error ? "red" : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
		fontSize: 16,
		width: "100%",
		padding: "12px 14px",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		"&:focus": {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		},
	},
    ".Mui-disabled": {
        borderColor: changeDisabledTheme ? "#000000" : undefined,
      },
    '& input:disabled': {
        WebkitTextFillColor: changeDisabledTheme ? "#000000" : undefined
      },
}))

export default function BasicTextField(props) {
	const { name, control, label, tooltip, inputId, placeholder, autoFocus, disabled = false, numberColumn, changeDisabledTheme, customHandleOnChange = false } = props

	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '' || (/^\d+$/.test(newValue) && newValue.length <= 4)) {
            onChange(newValue);
        }
    };

	return (
		<InputWrapper inputId={inputId} label={label} tooltip={tooltip} error={error} numberColumn={numberColumn}>
			<CustomInput 
				placeholder={placeholder} 
				id={inputId} 
				autoFocus={autoFocus} 
				disabled={disabled} 
				{...inputProps} 
				error={!!error} 
				ref={ref} 
				changeDisabledTheme={changeDisabledTheme} 
				value={value}
				onChange={customHandleOnChange ? handleChange : onChange}
        	/>
		</InputWrapper>
	)
}
