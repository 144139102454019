import { DragBehavior, EnterElement, Selection } from "d3"
import { BoundingBox } from "../../Types/BoundingBox"
import { D3OneToOneRenderable } from "./D3OneToOneRenderable"
import { ReactCallbacks } from "../../Types/ReactCallbacks"

type D3DragOverlayConfig = {
	boundingBox: BoundingBox
	dragBehavior: DragBehavior<any, any, any>
	cursor?: string
}

type D3AxisOverlayHooks = {
	onDoubleClick?: () => void
}

export class D3DragOverlay extends D3OneToOneRenderable<SVGGElement, SVGRectElement, D3DragOverlayConfig, ReactCallbacks<any>> {
	private hooks?: D3AxisOverlayHooks

	constructor(root: SVGGElement, config: D3DragOverlayConfig, reactCallbacks: ReactCallbacks<any>, hooks?: D3AxisOverlayHooks) {
		super(root, config, "d3-drag-overlay", reactCallbacks)
		this.hooks = hooks
		this.render()
	}

	protected enter(newElements: Selection<EnterElement, D3DragOverlayConfig, any, any>): Selection<SVGRectElement, D3DragOverlayConfig, SVGGElement, any> {
		const dragOverlays = newElements
			.append("rect")
			.attr("class", this.className)
			.attr("x", this.config.boundingBox.x)
			.attr("y", this.config.boundingBox.y)
			.attr("width", this.config.boundingBox.width)
			.attr("height", this.config.boundingBox.height)
			.attr("cursor", this.config.cursor ? this.config.cursor : "zoom-in")
			.attr("fill", "transparent")
			.call(this.config.dragBehavior)
		
		if (this.hooks?.onDoubleClick) {
			dragOverlays.on("dblclick", this.hooks.onDoubleClick)
		}		

		return dragOverlays
	}

	protected update(updatedElements: Selection<SVGRectElement, D3DragOverlayConfig, any, any>): Selection<SVGRectElement, D3DragOverlayConfig, SVGGElement, any> {

		updatedElements
			.attr("x", this.config.boundingBox.x)
			.attr("y", this.config.boundingBox.y)
			.attr("width", this.config.boundingBox.width)
			.attr("height", this.config.boundingBox.height)
			.attr("cursor", this.config.cursor ? this.config.cursor : "zoom-in")

		return updatedElements
	}
}
