import { atomFamily } from "recoil";
import { LayoutWindowId } from "../Types/LayoutWindowId";
import { scaleTime } from "d3";
import { CPPOptPlotConfig } from "../Types/CPPOptPlot";

export const cppOptPlotConfigsAtom = atomFamily<CPPOptPlotConfig, LayoutWindowId>({
    key: "cppoptPlotConfigs",
    default: {
        id: "default",
        dataObjectId: "?",
        viewScale: scaleTime(),
        fileScale: scaleTime(),
        patientId: "?",
        playbackSpeed: 1,
        dimensions: { height: 0, width: 0 },
        isLinked: false,
        timelineController: null,
        timeZone: "America/New_York",
        liveModeEnabled: false
    }
})
