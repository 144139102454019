import { EnterElement, ScaleBand, Selection, axisBottom } from "d3";
import { ReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox";

export type HistogramXAxisConfig = {
    boundingBox: MarginedBoundingBox
    scale: ScaleBand<any>
    label?: string
}

export class D3HistogramXAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, HistogramXAxisConfig> {
    private axisClassName: string = "d3-histogram-x-axis"
    private labelClassName: string = "d3-histogram-x-axis-label"

    constructor(root: SVGGElement, config: HistogramXAxisConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-histogram-x-axis-container", reactCallbacks)
        this.render()
    }

    protected enter(newElements: Selection<EnterElement, HistogramXAxisConfig, any, any>): Selection<SVGGElement, HistogramXAxisConfig, SVGGElement, any> {
        // Create the Axis Group
		const axisGroup = newElements
            .append("g")
            .attr("class", this.className)
            .attr("transform", `translate(0, ${this.config.boundingBox.height})`)

        // create the D3 axis
        axisGroup.append("g")
            .attr("class", this.axisClassName)
            .call(axisBottom(this.config.scale))
            .style("user-select", "none") // disables highlighting the ticks

        // create the label
        axisGroup.append("text")
            .attr("text-anchor", "middle")
            .attr("class", this.labelClassName)
            .attr("transform", `translate(${this.config.scale.range()[1] / 2}, 40)`)
            .text(() => this.config.label ?? "")
        
        axisGroup.each(this.createChildren)

        return axisGroup
    }

    protected update(updatedElements: Selection<SVGGElement, HistogramXAxisConfig, any, any>): Selection<SVGGElement, HistogramXAxisConfig, SVGGElement, any> {
        const axisGroup = updatedElements.attr("transform", `translate(0, ${this.config.boundingBox.height})`)

        // redraw the axis
        const d3Axis = axisGroup.select("." + this.axisClassName) as Selection<SVGGElement, any, any, any>
		d3Axis.call(axisBottom(this.config.scale))

        // update the label
        axisGroup.select("." + this.labelClassName)
            .attr("transform", `translate(${this.config.scale.range()[1] / 2}, 40)`)
            .text(() => this.config.label ?? "")

		return updatedElements
    }
}