import { D3ModalityGraph } from "./D3ModalityGraph"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { ModalityGraphConfig } from "../../../../Types/Graph"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { Selection, EnterElement } from "d3"
import { ModalityPage } from "../../../../Data/ModalityPage"

export type ModalityGraphsWrapperConfig = {
	graphs: ModalityGraphConfig[]
}

export class D3ModalityGraphsWrapper extends D3OneToManyRenderable<SVGGElement, ModalityGraphsWrapperConfig, ModalityGraphConfig, ModalityGraphGroupReactCallbacks> {
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private graphs: Map<string, D3ModalityGraph> = new Map()

	constructor(root: SVGGElement, config: ModalityGraphsWrapperConfig, pageManager: TimeSeriesPageManager<ModalityPage>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, "d3-modality-graph-wrapper", reactCallbacks)
		this.pageManager = pageManager
		this.mount()
	}

	renderPage = (page: ModalityPage) => {
		this.graphs.forEach(graph => graph.renderPage(page))
	}

	rescale = () => {
		this.graphs.forEach(graph => graph.rescale())
	}

	takeSnapshot = () => {
		this.graphs.forEach(graph => graph.takeSnapshot())
	}

	clearSnapshot = () => {
		this.graphs.forEach(graph => graph.clearSnapshot())
	}

	viewTimesChanged () {
		this.graphs.forEach(graph => graph.viewTimesChanged())
	}

	autoScaleGraphs = () => {
		this.graphs.forEach(graph => graph.autoScale())
	}

	// We need to know this to know whether we should bother fetching data along with the visual render or not.
	isRescaling = (): boolean => {
		for (const [, graph] of this.graphs) {
			if (graph.isRescaling()) {
				return true
			}
		}

		return false
	}

	protected updateDerivedState() {
		this.config.graphs.forEach(graph => this.graphs.get(graph.id)?.updateConfig(graph))
	}
	
	protected datumIdentifier(datum: ModalityGraphConfig): string | number {
		return datum.id
	}

	protected getConfigs(): ModalityGraphConfig[] {
		return this.config.graphs
	}

	protected enter(newElements: Selection<EnterElement, ModalityGraphConfig, SVGGElement, any>): Selection<SVGGElement, ModalityGraphConfig, SVGGElement, any> {
		const containers = newElements.append("g").attr("class", this.className)
		containers.each((config, index, nodes) => this.createChildren(config, index, nodes))
		return containers
	}

	protected update(updatedElements: Selection<SVGGElement, ModalityGraphConfig, SVGGElement, any>): Selection<SVGGElement, ModalityGraphConfig, SVGGElement, any> {
		this.graphs.forEach(graph => graph.render())
		return updatedElements
	}

	protected createChildren = (graphConfig: ModalityGraphConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.graphs.set(graphConfig.id, new D3ModalityGraph(root, graphConfig, this.pageManager, this.reactCallbacks))
	}
}
