import { atomFamily } from "recoil"
import { scaleTime } from "d3"
import { ModalityGraphGroupConfigJSON, ModalityGraphGroupConfig } from "../Types/ModalityGraphGroup"
import { LayoutWindowId } from "../Types/LayoutWindowId"

export const DEFAULT_TIME_SERIES_GROUP_JSON: ModalityGraphGroupConfigJSON = {
	viewDuration: 60 * 1000,
	graphs: [{
        min: 0,
        max: 100,
        name: "Default graph",
        traces: []
    }],
	playbackSpeed: 1,
}

export const modalityGraphGroupConfigAtom = atomFamily<ModalityGraphGroupConfig, LayoutWindowId>({
    key: "modalityGraphGroups",
    default: {
        id: "default",
        dataObjectId: "?",
        viewScale: scaleTime(),
        fileScale: scaleTime(),
        graphs: [],
        patientId: "?",
        annotations: [],
        inProgressAnnotation: {
            id: "?",
            startDate: null,
            endDate: null,
            color: "red",
            opacity: 0.2,
            text: "",
            modalities: [],
            keyPressed: null
        },
        playbackSpeed: 1,
        dimensions: { height: 0, width: 0 },
        hideEmptyGraphs: false,
        isLinked: false,
        patientModalities: [],
        timelineController: null,
        timeZone: "America/New_York",
        liveModeEnabled: false,
    }
})
