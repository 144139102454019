import { getDataQuerySocket } from "../../../../../Providers/Socket"
import { useRecoilCallback } from "recoil"
import { currentPatientFileInfoAtom, livePatientFileEndTimeAtom } from "../Atoms/PatientFile"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { useEffect } from "react"
import { ScaleTime } from "d3"

type LiveRecordingTimesSocketResponse = {
	data_object_id: string
	new_end_date_iso: string
}

type useD3LiveRecordingTimesProps = {
	d3Controller?: D3TimeBasedVisualization<any, any, any, any>
	liveModeEnabled: boolean
	fileScale: ScaleTime<any, any, any>
}

/*
	When we are reviewing a recording that is currently updating live, it's nice to see those changes in real-time or near real-time.
	This hook listens for events from data query updating the recording end time.
	When the recording time updates, we can do special actions to re-request or re-render data as needed.
*/

export const useUpdateLiveRecordingEndDate = () => {
	const handleSocketResponse = useRecoilCallback(({ snapshot, set }) => (socketResponse: LiveRecordingTimesSocketResponse) => {
		const { dataObjectId } = snapshot.getLoadable(currentPatientFileInfoAtom).getValue()

		if (socketResponse.data_object_id.toString() === dataObjectId.toString()) {
			const newDate = new Date(socketResponse.new_end_date_iso)
			set(livePatientFileEndTimeAtom, newDate)
		}
	}, [])

	useEffect(() => {
		const socket = getDataQuerySocket()
		socket.on("live_recording_times_update", handleSocketResponse)

		return () => {
			socket.off("live_recording_times_update", handleSocketResponse)
		}
	}, [handleSocketResponse])
}

export const useD3LiveRecordingEndDate = ({ d3Controller, liveModeEnabled, fileScale }: useD3LiveRecordingTimesProps) => {
	const handleSocketResponse = useRecoilCallback(({ snapshot }) => (socketResponse: LiveRecordingTimesSocketResponse) => {
		const { dataObjectId } = snapshot.getLoadable(currentPatientFileInfoAtom).getValue()

		if (socketResponse.data_object_id.toString() === dataObjectId.toString() && liveModeEnabled) {
			const newEndDate = new Date(socketResponse.new_end_date_iso)
			d3Controller?.liveEndDateUpdated(newEndDate)
		}
	}, [d3Controller, liveModeEnabled])

	useEffect(() => {
		const socket = getDataQuerySocket()
		socket.on("live_recording_times_update", handleSocketResponse)

		return () => {
			socket.off("live_recording_times_update", handleSocketResponse)
		}
	}, [handleSocketResponse])
}
