import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../Providers/ModalProvider"
import { WAVEFORM_WINDOW_TIME_PRESETS } from "../Viewport/Components/XAxis"

import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { ConfigureEEGModal } from "../../../Pages/Data/Visualize/DataReview/Components/Modals/ConfigureEEGModal"
import { eegMontageConfigAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/EEGMontage"
import { EEG_SENSITIVITY } from "../../../Pages/Data/Visualize/DataReview/Types/EEG"
import { MobergDropdown, MobergDropdownSize } from "../../../Components/MobergDropdown/MobergDropdown"
import { VisualizationToolbar } from "../../../Pages/Data/Visualize/DataReview/Components/React/VisualizationToolbar"
import { EEGCollapseMenu } from "./EEGCollapseMenuSettings"

type EEGWindowSettingsProps = {
	visualizationArea: string
}

export function EEGWindowSettings(props: EEGWindowSettingsProps) {
	const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)
	
	const [eegConfig, setEEGConfig] = useRecoilState(eegMontageConfigAtom({layoutId: selectedLayout?.id as string, windowId: props.visualizationArea}))
	const [viewStart, viewEnd] = eegConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)

		createModal(
			<ConfigureEEGModal
				escClose={false}
				clickOutsideClose={false}
				windowId={props.visualizationArea}
				layoutId={selectedLayout.id}
			/>
		)
	}


	const visualizationSpecificSettings = (<>
		<MobergDropdown 
			label={"Sensitivity"}
			selectedValue={eegConfig.sensitivityMicroVolts}
			size={MobergDropdownSize.SMALL} 
			onChange={value => setEEGConfig(previous => ({ ...previous, sensitivityMicroVolts: parseFloat(value as string) }))}
			options={Object.entries(EEG_SENSITIVITY).map(([label, value]) => ({ label, value }))} />

		<EEGCollapseMenu eegConfig={eegConfig} setEEGConfig={setEEGConfig} />

	</>)

	return (
		<VisualizationToolbar 
			viewDuration={viewDuration}
			visualizationSpecificSettings={visualizationSpecificSettings}
			timePresetOptions={WAVEFORM_WINDOW_TIME_PRESETS}
			atomValue={eegConfig}
			setAtom={setEEGConfig}
			renderConfigureModal={renderConfigureModal}
		/>
	)
}
