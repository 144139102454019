import { axisLeft, Selection, EnterElement, ScaleBand } from "d3"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"

export type D3BandScaleAxisConfig = {
	scale: ScaleBand<string>
}

export class D3BandScaleAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3BandScaleAxisConfig> {
	private d3AxisClassName: string = "d3-axis-left"

	constructor(root: SVGGElement, config: D3BandScaleAxisConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-value-axis", reactCallbacks)
		this.render()
	}

	protected enter = (enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> => {
		const axisGroup = enterElements
			.append("g")
			.attr("class", this.className)

		axisGroup.append("g")
			.attr("class", this.d3AxisClassName)
			.call(axisLeft(this.config.scale))
			.style("user-select", "none") // disables highlighting the ticks

		return axisGroup
	}

	protected update = (updateElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const d3AxisLeft = updateElements.select("." + this.d3AxisClassName) as Selection<SVGGElement, any, any, any>
		d3AxisLeft.call(axisLeft(this.config.scale))
		return updateElements
	}
}
