import React, { useState, useEffect, useLayoutEffect } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { MdOutlineModeEdit } from "react-icons/md"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks';
import { LINKS } from '../../../../Constants/BackendLinks';
import { useModalProvider } from '../../../../Providers/ModalProvider';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import FormViewModal from './FormViewModal';
import SubpageTabs from '../../../../Components/SubpageTabs/SubpageTabs';
import MUITable from '../../../../Components/MUITable/MUITable';
import { Box } from '@mui/material';
import { SubHeader } from '../Subpages/Components/SubHeader';

const UploadedForm = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [patient, setPatient] = useState(location.state)
    const endpointProvider = useEndpointProvider();
    const locationURL = useLocation();
    const { createModal } = useModalProvider();
    const query = new URLSearchParams(locationURL.search);
    const UID = query.get("patient_id")

    const formColumns = [
        { field: "forms", flex: 0.4, headerName: "Form", visible: true, minWidth: 120 },
        { field: "users", flex: 0.4, headerName: "Uploaded by", visible: true, minWidth: 120 },
        { field: "filledTime", flex: 0.4, headerName: "Date created", visible: true, minWidth: 120 },
        { 
            field: "actions", 
            flex: 0.4, 
            headerName: "Actions", 
            visible: true, 
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            renderCell: (params) => <GridActionsCellItem icon={<MdOutlineModeEdit size={20} />} onClick={() => renderViewFormModal(params.row.formID, parseInt(UID))}/>
        }
    ]

    useEffect(() => {
		async function getPatient() {
			let body = {
				patient_primary_key: UID,
			}

			if (UID === undefined) {
				alert(`Patient ID does not exist.`)
				navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
			}

			try {
				const patientData = await endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
				return patientData
			} catch (error) {
				alert(`Patient id=${UID} does not exist.`)
				navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
			}
		}

		if (!patient || Object.keys(patient).length === 0) {
			getPatient().then(patientData => {
				setPatient(patientData)
			})
		}
	}, [UID])

    function useWindowSize() {
        const [size, setSize] = useState(window.innerHeight)

        useLayoutEffect(() => {
            const updateSize = () => setSize(window.innerHeight)
            const observer = new ResizeObserver(updateSize)

            observer.observe(document.body)

            return () => observer.disconnect()
        }, [])

        return size
    }

    async function renderViewFormModal(formID, patientID) {
        let body = {
            patient_id: patientID,
            form_id: formID
        }
        let contentOfModal;
        try {
            if (!formID) {
                console.log('form ID does not exist or Invalid Form ID!');
                return;
            }

            contentOfModal = await endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENTS_MODAL, body);
            createModal(
                <FormViewModal
                    formResponseJson={contentOfModal}
                />
            );
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    };

    const queryProps = {
        queryKey: "forms",
        endpoint: LINKS.DATA.PROFILING.GET_PATIENTS_FORM_DIRECTORY,
        body: { patient_id: UID }
    }

    return (
        <Box>
            <SubpageTabs UID={UID} patient={patient} currentTab={'Forms'}/>

            <Box id="formContentContainer" sx={{ padding: "0px 32px" }}>
                <SubHeader patient={patient} />
                <Box sx={{ marginTop: "10px" }}>
                    <MUITable columns={formColumns} isCheckboxSelection={false} {...queryProps} style={{ padding: '0px' }} isRowSelectable={() => false} />
                </Box>
            </Box>
        </Box>
    )
};

export default UploadedForm;
