import styled from 'styled-components';
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";
import { useEffect } from 'react';
import { TableComponent, RowsContainer, Pagination } from '../../../../Components/TableComponent/TableComponent';
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { LINKS, isDemo, isDevelopment } from '../../../../Constants/BackendLinks';
import { IntrusiveCheckboxButton, CheckboxButton } from '../../../../Components/CheckboxButton/CheckboxButton';
import { useState } from 'react';
import PrimaryCTAButton, { buttonColor } from '../../../../Components/PrimaryCTAButton/PrimaryCTAButton';
import { useModalProvider } from '../../../../Providers/ModalProvider';
import { humanReadableSize, ordinal_suffix_of } from '../../../../Computation/utilFunctions';



const UnloadFilesModal = ({ fileToPreload, inventory, startPrefetch }) => {
    const endpointProvider = useEndpointProvider()

    const [selectedFiles, setSelectedFiles] = useState([])
    const [inventoryData, setInventoryData] = useState([])
    const [inventoryColumns, setInventoryColumns] = useState([])
    const [combinedInventory, setCombinedInventory] = useState(inventory)
    const [unloading, setUnloading] = useState()


    const { close } = useModalProvider()
    combinedInventory.current_size = combinedInventory.directories.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.size), 0)

    useEffect(() => {
        if (combinedInventory.directories?.length === 0) {
            setInventoryData([])
            return
        }


        const fifteenMinutesInMilliseconds = (isDemo || isDevelopment) ? 15 * 1000 : 15 * 60 * 1000
        const inactiveDirsInventory = combinedInventory.directories.filter((file) => (new Date() - new Date(file.last_used * 1000)) > fifteenMinutesInMilliseconds)
        const inactiveDirs = inactiveDirsInventory.filter((file) => (file.in_queue_order !== undefined))

        let columnsLabels = {
            preselected: {
                label: (inactiveDirs.length > 0) && <IntrusiveCheckboxButton
                    state={(inactiveDirs?.length === selectedFiles.length) && (inactiveDirs?.length !== 0)}
                    action={(nextState) => {
                        if (nextState) {
                            setSelectedFiles(inactiveDirs?.map(file => file.name))
                        } else {
                            setSelectedFiles([])
                        }
                    }} />,
                type: "checkbox",
                sortable: false,
                width: "55px",
                maxWidth: "55px",
                center: true,
            },
            site: { label: "Site", type: "string", center: false, width: "240px", sortable: true },
            patient_id: { label: "Patient", type: "string", center: false, width: "100px", sortable: true },
            name: { label: "File name", type: "string", center: false, width: "300px", sortable: true },
            size: { label: "File size", type: "string", center: false, width: "110px", sortable: true },
            status: { label: "Status", type: "string", center: false, width: "160px", sortable: true }
        }

        let columnData = []

        for (let elem of Object.keys(columnsLabels)) {
            columnData.push({
                name: columnsLabels[elem]['label'],
                selector: row => row[elem],
                sortable: columnsLabels[elem]['sortable'],
                center: columnsLabels[elem]['center'],
                width: columnsLabels[elem]['width'] ? columnsLabels[elem]['width'] : true,
                maxWidth: columnsLabels[elem]['maxWidth'] ? columnsLabels[elem]['maxWidth'] : "10000px",
                attr: elem
            })
        }

        setInventoryColumns(columnData)

        let tableData = combinedInventory.directories.map(row => {
            let entry = {}

            const timestampDate = new Date(row.last_used * 1000);
            const currentDate = new Date();
            const timeDifference = currentDate - timestampDate
            let isDisabled = (timeDifference <= fifteenMinutesInMilliseconds) || unloading
            if (row.in_queue_order !== undefined)
                isDisabled = true

            entry['disabled'] = isDisabled

            for (let column of Object.keys(columnsLabels)) {
                if (columnsLabels[column]["type"] === "checkbox") {
                    entry['preselected'] =
                        <CheckboxButton
                            state={selectedFiles.includes(row.name)}
                            action={(nextState) => isDisabled ? {} : onCheckFile(row.name, nextState)}
                            disabled={isDisabled}
                            opacity={isDisabled ? 0.3 : 1}
                        />
                } else {
                    if (column === 'name') {
                        if (row[column] === null) {
                            entry[column] = ""
                        } else {
                            entry["uneditedName"] = row[column]
                            const displayName = row[column].split("/")
                            entry[column] = displayName[displayName.length - 1]
                        }
                    }
                    else if (column === 'size') {
                        if (row[column] === null) {
                            entry[column] = ""
                        }
                        else {
                            entry["uneditedSize"] = row.size
                            entry[column] = humanReadableSize(row.size)
                        }
                    }
                    else if (column === 'status') {
                        let status = "Not in use"
                        if (row.in_queue_order !== undefined) {
                            if (row.in_queue_order === 0) {
                                status = "Loading " + row.percent_loaded.toFixed(2) + "%"
                            }
                            else {
                                status = ordinal_suffix_of(row.in_queue_order) + " in queue"
                            }
                        } else if (isDisabled) {
                            status = "In use"
                        }
                        entry[column] = status
                    }
                    else {
                        entry[column] = row[column]
                        entry["id"] = row.data_object_id
                    }
                }
            }
            return entry
        })
        setInventoryData(tableData)

    }, [selectedFiles, inventory, combinedInventory, unloading])



    async function unload(file_object_ids) {
        let body = {
            'data_object_ids': file_object_ids
        }
        return endpointProvider.post(LINKS.ADMIN.PREFETCH.UNLOAD, body)
    }

    function onCheckFile(file, nextState) {
        if (nextState) {
            setSelectedFiles(prevState => [...prevState, file])
        } else {
            setSelectedFiles(prevState => prevState.filter(selectedFile => selectedFile !== file))
        }
    }

    async function getPrefetchInventory() {
        let body = {}
        return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY, body)
    }

    async function getPrefetchQueue() {
        let body = {}
        return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_QUEUE, body)
    }

    function unloadAndRefresh(data_object_ids) {
        const unloadAndFetch = async () => {
            let newInventory = await unload(data_object_ids)
            const queueData = await getPrefetchQueue()
            if ((newInventory.directories.length > 0) && queueData.directories.length > 0)
                newInventory.directories = newInventory.directories.concat(queueData.directories)
            let queue_size = queueData.directories.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.size), 0)
            newInventory.current_size += queue_size
            setCombinedInventory(newInventory)
            setUnloading(false)
        }
        unloadAndFetch()
    }

    function handleProceedPreload() {
        startPrefetch()
        close()
    }

    function handleUnload() {
        setUnloading(true)
        let filesToUnload = combinedInventory.directories.filter((file) => selectedFiles.includes(file.name))
        let data_object_ids = filesToUnload.map(file => file.data_object_id)
        unloadAndRefresh(data_object_ids)

        setSelectedFiles([])
    }
    
    const maxTotalSize = humanReadableSize(parseFloat(combinedInventory.max_total_size))
    const currentSize = humanReadableSize(parseFloat(combinedInventory.current_size))
    const requestedSize = humanReadableSize(parseFloat(fileToPreload.filesize))
    const needToUnloadSize = humanReadableSize(parseFloat(fileToPreload.filesize) + parseFloat(combinedInventory.current_size) - parseFloat(combinedInventory.max_total_size))


    return (
        <div style={{ background: "#FFFFFF", width: "1050px", height: "776px", display: "block", paddingTop: "20px", paddingBottom: "188px", overflowX: "hidden" }}>
            <ModalHeader headerText='Unload Files' />

            <div style={{ width: "1040px", height: "auto", marginTop: "20px", paddingLeft: "50px", paddingRight: "50px", display: "inline-grid" }}>
                {(fileToPreload.filesize + combinedInventory.current_size > combinedInventory.max_total_size) &&
                    <p><strong>Info! </strong> Only <strong>{maxTotalSize}</strong> of data can be loaded at once.
                        The following files take up  <strong>{currentSize} </strong>
                        of space, and you are requesting an additional <strong>{requestedSize}</strong>.
                        Please unload at least  <strong>{needToUnloadSize} </strong>
                        of space from inactive sessions to continue. If there are too many active sessions,
                        please coordinate with users in your workspace or contact Moberg Analytics Support for more space.
                    </p>
                }

                {(fileToPreload.filesize + combinedInventory.current_size < combinedInventory.max_total_size) && <p><strong>Info!  </strong>
                    There is now enough space to proceed with preload!
                </p>}

            </div>


            <div style={{ width: "1040px", height: "auto", marginTop: "20px", paddingLeft: "480px", paddingRight: "50px", display: "inline-grid" }}>
                {unloading &&
                    <p><strong>Unloading files.. </strong>
                    </p>
                }
            </div>


            <div style={{ width: "970px", height: "auto", marginTop: "20px", paddingLeft: "250px", paddingRight: "250px", display: "inline-grid" }}>

                <div style={{ width: "570px", height: "auto", textAlign: "center", padding: "0px", marginBottom: "25px" }}>
                    <SubheaderText style={{ position: "relative" }}>Loaded  Files</SubheaderText>
                </div>

                <div style={{ width: "970px", height: "auto", marginLeft: "-200px" }}>
                    <TableComponent
                        data={inventoryData}
                        uniRef="name"
                    >
                        <RowsContainer
                            columns={inventoryColumns}
                            rowsPerPage={5}
                        />
                        <Pagination />
                    </TableComponent>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PrimaryCTAButton
                        buttonText="Unload"
                        color={buttonColor.redFill}
                        buttonStyle={{ padding: "6px 12px", marginLeft: "180px", marginTop: "25px" }}
                        disabled={(selectedFiles.length === 0) || unloading}
                        onClickFxn={() => { handleUnload() }}
                    />
                    <PrimaryCTAButton
                        buttonText="Proceed with preload"
                        buttonStyle={{ padding: "6px 12px", marginTop: "25px", marginRight: "350px" }}
                        color={buttonColor.blueFill}
                        disabled={((parseFloat(fileToPreload.filesize) + parseFloat(combinedInventory.current_size) > parseFloat(combinedInventory.max_total_size)) || unloading)}
                        onClickFxn={() => { handleProceedPreload() }}
                    />
                </div>
            </div>
        </div>
    )
}

const SubheaderText = styled.text`
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    color: #293241;
    line-height: 150%;
    position: absolute;
`;

export default UnloadFilesModal;