import { useSetRecoilState } from "recoil"
import { hotkeysEnabledAtom } from "../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys"
import { ChangeEvent, KeyboardEvent, forwardRef, useState } from "react"
import { MobergInputLabel } from "./MobergInputHeader"
import React from "react"
import { MobergColumn } from "../MobergLayout/MobergColumn"

type MobergShortTextInputProps = {
	limit: number
	label: string
	placeholder?: string
	defaultValue?: string
	onChange?: (value: string) => void
    onSubmit?: () => void
}

export const MobergShortTextInput = forwardRef<HTMLInputElement, MobergShortTextInputProps>(({ limit, label, placeholder, defaultValue = "", onChange, onSubmit }, ref) => {
	const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)
	const [value, setValue] = useState<string>(defaultValue)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (value.length > limit) {
			return
		}

		setValue(event.target.value)

		if (onChange) {
			onChange(event.target.value)
		}
	}

    const checkForSubmit = (event: KeyboardEvent<HTMLInputElement>) => { 
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

	return (
		<MobergColumn gap="8px">
			<MobergInputLabel text={label} />

			<input
                ref={ref}
				value={value}
				placeholder={placeholder}
				onFocus={() => setHotkeysEnabled(false)}
				onBlur={() => setHotkeysEnabled(true)}
				onChange={handleChange}
                onKeyDown={checkForSubmit}
				type={"text"}
				style={{ padding: "8px 16px", borderRadius: "6px", border: "1px solid #cdcdcd" }}
			/>
		</MobergColumn>
	)
})
