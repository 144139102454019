import React, { useContext, useRef, useState } from "react"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { D3EEGMontage } from "../D3/D3EEGMontage"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { eegMontageConfigAtom } from "../../../../Atoms/EEGMontage"
import { annotationsAtom, selectedAnnotationAtom } from "../../../../Atoms/Annotations"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { EEGWindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/EEGWindowSettings"
import { EEGMontageConfig, EEGMontageConfigJSON } from "../../../../Types/EEGMontageConfig"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { useD3LiveRecordingEndDate } from "../../../../Hooks/useLiveRecordingTimes"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { ParsedJSONOptions } from "../../../../Types/ParsedJSON"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"

type EEGMontageProps = {
	json: EEGMontageConfigJSON
	area: string
}

export function EEGMontage(props: EEGMontageProps) {

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ flex: 0 }}>
				<EEGWindowSettings visualizationArea={props.area} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<EEGMontageD3Wrapper {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

function EEGMontageD3Wrapper(props: EEGMontageProps) {
    const { createModal } = useModalProvider()
    const dimensions = useContext(DimensionsContext)
	const { inProgressAnnotation } = useInProgressAnnotation(props.area)

    const domNode = useRef(null)
    const { fileStartDate, fileEndDate, patientId, dataObjectId, timeZone } = useRecoilValue(currentPatientFileInfoAtom)

	const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)

    const annotations = useRecoilValue<Annotation[]>(annotationsAtom)
	const layoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = {layoutId: layoutId as string, windowId: props.area}
    const [d3Montage, setD3Montage] = useState<D3EEGMontage | undefined>()
    const [config, setRootConfig] = useRecoilState(eegMontageConfigAtom(visualizationId))
	const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })
	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, fileStartDate, fileEndDate)

	const getParsedJSON = (options?: ParsedJSONOptions): EEGMontageConfig => {
		const base = {...config}

		if (options?.initialize) {
			Object.assign(base, props.json)
		}

        return {
			...base,
			id: props.area,
			viewScale,
			fileScale,
			dataObjectId,
			patientId,
			dimensions,
            annotations,
            inProgressAnnotation,
			timelineController,
			timeZone
		}
    }

	// Update the D3 component when the React component re-renders
	if (d3Montage) {
		d3Montage.updateConfig(getParsedJSON())
	}

	useD3CheckReloadData({
		d3Controller: d3Montage,
		clearDataIfChanges: {
			viewDuration,
			LFF: config.LFF,
			HFF: config.HFF,
			montage: config.montage,
			notch: config.notch
		},
		clearRenderCacheIfChanges: {
			sensitivityMicroVolts: config.sensitivityMicroVolts,
		},
		fileScale
	})

	useD3KeyboardShortcuts({
		d3Controller: d3Montage,
		windowId: props.area,
		shortcuts: [
			hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
		]
	})

	useD3UpdateVisibleTraces({
		d3Controller: d3Montage,
		windowId: props.area
	})

    useOnMount(() => {
		const node = domNode.current
		const reactCallbacks = { createModal, setRootConfig, setSelectedAnnotation, setLinkedWindowsTimelineController }

		if (node === null) {
			throw new Error("time series group did not initialize as expected.")
		}

		const parsedJSON = getParsedJSON({ initialize: true })
		const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.EEG_MONTAGE, new EEGTimeSeriesPageManager())
		const montage = new D3EEGMontage(node, parsedJSON, pageManager, reactCallbacks)

		setD3Montage(montage)
		setRootConfig(parsedJSON)

		return () => {
			montage.unmount()
		}
	})

	useD3LiveRecordingEndDate({ 
		d3Controller: d3Montage,
		liveModeEnabled: config.liveModeEnabled,
		fileScale
	})
	
    return <div ref={domNode} style={{height: "100%"}} />
}

