import React from "react"

import { AuthProvider } from "./AuthProvider";
import { EndpointProvider } from "./EndpointProvider";
import { SitesProvider } from "./SitesProvider";
import { ModalitiesProvider } from "./ModalitiesProvider";
import { UsersProvider } from "./UsersProvider";
import { RolesProvider } from "./RolesProvider";
import { BucketsProvider } from "./BucketsProvider";
import { PatientsProvider } from "./PatientsProvider"; 
import { PatientGroupsProvider } from "./PatientGroupsProvider";
import { PermissionsProvider } from "./PermissionsProvider";
import { AnalysesProvider } from "./AnalysesProvider";
import { UploadIntoQueueProvider } from "./UploadIntoQueueProvider";
import { UploadQueueProvider } from "./UploadQueueProvider";
import { AnnotationsProvider } from "./AnnotationsProvider";
import { UploadProcessProvider } from "./UploadProcessProvider";
import { ThemeProvider} from "@mui/material/styles";
import CustomTheme from "./CustomThemeProvider";
import { MobergThemeProvider } from "./MobergThemeProvider";

import {RecoilRoot} from 'recoil';
import { HotkeysProvider } from "./HotkeysProvider";
import { WorkspacesProvider } from "./WorkspacesProvider";

const Providers = ({providers, children}) => {
    const renderProvider = (providers, children) => {
      const [provider, ...restProviders] = providers;
      
      if (provider) {
        return React.cloneElement(
          provider,
          null,
          renderProvider(restProviders, children)
        )
      }
  
      return children;
    }
  
    return renderProvider(providers, children)
  }

export const AppProviders = ({ children }) => (
    <>
    <RecoilRoot>
        <Providers providers={[
            // Providers get wrapped from top to bottom. i.e. AuthProvider is the first, outer provider.
            
            // Intentionally ordered providers
            <AuthProvider />,
            <WorkspacesProvider />,
            <EndpointProvider />,

            // Arbitrarily ordered data providers
            <UploadQueueProvider />,
            <UploadProcessProvider />,
            <AnalysesProvider />,
            <BucketsProvider />,
            <ModalitiesProvider />,
            <PatientGroupsProvider />,
            <PatientsProvider />,
            <PermissionsProvider />,
            <UploadIntoQueueProvider />,
            <RolesProvider />,
            <SitesProvider />,
            <HotkeysProvider/>,
            <UsersProvider />,
            <AnalysesProvider />,
            <AnnotationsProvider />,

            // UI and Styling
            <MobergThemeProvider />,
            <ThemeProvider theme={CustomTheme} /> // Material UI table styling
            ]}>
                { children }
        </Providers>
    </RecoilRoot>
</>
)
