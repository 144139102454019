import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import GridContainer from "../../../../Components/TextField/GridContainer"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import BasicTextField from "../../../../Components/TextField/BasicTextField"
import { LINKS } from "../../../../Constants/BackendLinks"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useTableContext } from "../../../../Providers/TableProvider"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import PermissionGroup from "../../../../Components/PermissionGroup/PermissionGroup"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useState } from "react"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergTheme } from "../../../../Moberg"
import { useEffect } from "react"
import { Input, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

export default function CreateRoleModal() {
	const endpointProvider = useEndpointProvider()
	const queryClient = useQueryClient()
	const { setToastProps } = useTableContext()
	const { close } = useModalProvider()
	const schema = yup
		.object({
			name: yup.string().required("Name is required."),
			description: yup.string().required("Description is required."),
		})
		.required()
	const { control, handleSubmit } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: "", description: "" },
	})

	const { data, isLoading } = useQuery({
		queryKey: ['permissions'],
		queryFn: () => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.GET_PERMISSIONS),
	})

	const createRole = async body => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.CREATE_ROLE, body)
	const createRoleMutation = useMutation({
		mutationFn: body => createRole(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "Role created successfully." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("roles")
		},
	})

	const [groups, setGroups] = useState(data ? data : []);
	useEffect(() => {
		setGroups(data)
	}, [data])
	const onSubmit = async data => {
		const selectedRowIds = groups.map(group => group.permissions.filter(permission => permission.enabled).map(permission => permission.id)).flat()
		createRoleMutation.mutate({
			...data,
			permission_ids: selectedRowIds
		})
		close()
	}

	const handleToggle = (groupName, permissionName) => {
		const updatedGroups = groups.map(group => {
			if (group.group === groupName) {
				return {
					...group,
					permissions: group.permissions.map(permission => {
						if (permission.name === permissionName) return { ...permission, enabled: !permission.enabled }
						return permission;
					})
				};
			}
			return group;
		});
	
		setGroups(updatedGroups);
	};

	const handleSelectAllToggle = (groupName, selectAll) => {
		const updatedGroups = groups.map(group => {
			if (group.group === groupName) {
				return {
				...group,
				permissions: group.permissions.map(permission => ({
					...permission,
					enabled: selectAll
				}))
				};
			}
			return group;
		});
	
		setGroups(updatedGroups);
	};

	const [isSearch, setIsSearch] = useState(false);

	const handleSearch = (event) => {
		const searchQuery = event.target.value.trim().toLowerCase();
		if (!searchQuery) { 
			setIsSearch(false);
			setGroups(data || []); 
			return 
		} else {
			setIsSearch(true);
		}
		
		const updatedGroups = groups.map(group => {
			const updatedPermissions = group.permissions.filter(permission => {
				return (
					permission.name.toLowerCase().includes(searchQuery) ||
					permission.description.toLowerCase().includes(searchQuery) ||
					permission.group.toLowerCase().includes(searchQuery)
				);
			});
	
			return updatedPermissions.length > 0 ? { ...group, permissions: updatedPermissions } : null;
		}).filter(group => group !== null);
	
		setGroups(updatedGroups);
	}

	return (
		<>
			<ModalHeader headerText="Create Role" />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>Role Details</h2>
					<GridContainer>
						<BasicTextField
							inputId="create-role-title-id"
							label="Title"
							tooltip="The title of the role."
							placeholder="Admin"
							autoFocus={true}
							control={control}
							name="name"
							numberColumn={6}
						/>
						<BasicTextField
							inputId="create-role-description-id"
							label="Description"
							tooltip="The description of the role."
							placeholder="Enter description"
							control={control}
							name="description"
							numberColumn={6}
						/>
					</GridContainer>
					
					<h2>Permissions</h2>
					<div style={{marginBottom: "8px"}}>
						<Input
							id='MUI-Search-Bar'
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
							placeholder="Search"
							onChange={handleSearch}
							disableUnderline={true}
						/>
					</div>
					{isLoading ? <div>Loading...</div> : 
					groups?.map(group => (
						<PermissionGroup
							key={group.group} 
							group={group} 
							onToggle={(groupName, permissionName) => handleToggle(groupName, permissionName)} 
							onSelectAllToggle={handleSelectAllToggle}
							isSearch={isSearch}
						/>
					))}
				</div>
			</ModalScrollbar>
			<ModalFooter style={{ justifyContent: "right"}}>
				<MobergButton theme={MobergTheme.BLUE} variant={MobergButtonVariant.FILLED} shape={MobergButtonShape.WIDE} onClick={handleSubmit(onSubmit)}>
					Submit
				</MobergButton>
			</ModalFooter>
		</>
	)
}