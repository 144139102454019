import { Selection, EnterElement } from "d3"
import { D3OneToManyRenderable } from "../../../D3/D3OneToManyRenderable"
import { TraceConfig, TraceNameAndColor } from "../../../../Types/Trace"
import { ReactCallbacks } from "../../../../Types/ReactCallbacks"

type D3LegendLabelsConfig = {
	traces: TraceNameAndColor[]
}

export class D3LegendLabels extends D3OneToManyRenderable<HTMLDivElement, D3LegendLabelsConfig, TraceNameAndColor> {

	constructor(root: HTMLDivElement, config: D3LegendLabelsConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-legend-labels", reactCallbacks)
		this.mount()
	}

	protected datumIdentifier(datum: TraceConfig): string | number {
		return datum.name
	}

	protected getConfigs(): TraceNameAndColor[] {
		return this.config.traces
	}

	protected enter(newElements: Selection<EnterElement, TraceNameAndColor, Element, any>): Selection<HTMLDivElement, TraceNameAndColor, Element, any> {
		const elements = newElements
			.append("div")
            .attr("class", this.className)
			.style("color", trace => trace.color)
			.text(trace => this.formatTraceName(trace.name))

		return elements
	}

	protected update(updatedElements: Selection<HTMLDivElement, TraceNameAndColor, Element, any>): Selection<HTMLDivElement, TraceNameAndColor, Element, any> {
		return updatedElements
            .style("color", trace => trace.color)
            .text(trace => this.formatTraceName(trace.name))
	}

	private formatTraceName = (traceName: string) => {
		return "[" + traceName + "]"
	}
}
