import React from "react";
import { Selection, select, ScaleTime, D3DragEvent, drag, EnterElement } from "d3";
import { D3GraphsOverlay } from "../Visualizations/TimeSeriesGraphGroup/D3/D3GraphsOverlay";
import { MarginedBoundingBox } from "../../Types/MarginedBoundingBox";
import { EditAnnotation } from "../../../../../../Managers/VisualizationManager/ToolBar/Components/EditAnnotation";
import { Annotation } from "../../../../../../Managers/VisualizationManager/Variables/Annotations";
import { AnnotationGeometry } from "../Visualizations/TimeSeriesGraphGroup/AnnotationGeometry/AnnotationGeometry";
import { AnnotationGeometryGenerator } from "../Visualizations/TimeSeriesGraphGroup/AnnotationGeometry/AnnotationGeometryGenerator";
import { GraphConfig } from "../../Types/Graph";
import { AnnotationReactCallbacks } from "../../Types/ReactCallbacks";
import { D3OneToManyRenderable } from "./D3OneToManyRenderable";

export type D3AnnotationsConfig = {
	viewScale: ScaleTime<any, any, any>
	annotations: Annotation[]
	boundingBox: MarginedBoundingBox
	graphs: GraphConfig[]
	overlay: D3GraphsOverlay
	clipPathId: string
	onWheel? (event: WheelEvent): void
}

export class D3AnnotationsWrapper extends D3OneToManyRenderable<SVGRectElement, D3AnnotationsConfig, AnnotationGeometry, AnnotationReactCallbacks<any>> {
	private geometryGenerator: AnnotationGeometryGenerator
	private geometry: AnnotationGeometry[] = []

	constructor(root: SVGGElement, config: D3AnnotationsConfig, reactCallbacks: AnnotationReactCallbacks<any>) {
		super(root, config, "d3-annotations-wrapper", reactCallbacks)
		this.geometryGenerator = new AnnotationGeometryGenerator()
		this.mount()
	}

	protected enter(newElements: Selection<EnterElement, AnnotationGeometry, SVGGElement, any>): Selection<SVGRectElement, AnnotationGeometry, SVGGElement, any> {
		const annotations = newElements
            .append("rect")
			.attr("clip-path", `url(#${this.config.clipPathId})`)
            .attr("class", this.className)
			.attr("x", geometry => this.config.viewScale(geometry.annotation.start_time))
			.attr("y", geometry => geometry.y)
			.attr("width", geometry => this.calculateAnnotationWidth(geometry))
			.attr("height", geometry => geometry.height)
			.attr("fill", geometry => geometry.color)
			.attr("opacity", geometry => geometry.opacity)
			.call(drag<SVGRectElement, any, any>().on("drag", this.onDrag))
			
		annotations
			.on("mouseenter", event => this.onHover(event))
			.on("mousemove", event => this.onHover(event))
			.on("mouseleave", event => this.onLeave(event))
			.on("wheel", event => this.config.onWheel && this.config.onWheel(event)) // prevent annotations from capturing wheel events
			.on("click", event => this.onClick(event))

		return annotations
	}

	protected update = (updatedAnnotations: Selection<any, AnnotationGeometry, any, any>): Selection<any, any, any, any> => {
		return updatedAnnotations
			.attr("x", geometry => this.config.viewScale(geometry.annotation.start_time))
			.attr("y", geometry => geometry.y)
			.attr("width", geometry => this.calculateAnnotationWidth(geometry))
			.attr("height", geometry => geometry.height)
			.attr("fill", geometry => geometry.color)
			.attr("opacity", geometry => geometry.opacity)
	}

	protected datumIdentifier(datum: AnnotationGeometry): string | number {
		return `${datum.annotation.id}-${datum.y}`
	}

	protected getConfigs(): AnnotationGeometry[] {
		const [startDate, endDate] = this.config.viewScale.domain()
		const startTime = startDate.getTime()
		const endTime = endDate.getTime()

		return this.geometry.filter(geometry => 
			(geometry.annotation.start_time >= startTime && geometry.annotation.end_time <= endTime)
			|| (geometry.annotation.start_time <= startTime && geometry.annotation.end_time >= startTime) 
			|| (geometry.annotation.start_time <= endTime && geometry.annotation.end_time >= endTime))
	}

	protected updateDerivedState = () => {
		this.geometry = this.config.annotations.flatMap(annotation => 
			this.geometryGenerator.generate(annotation, this.config.boundingBox, this.config.viewScale, this.config.graphs))
	}

	private onHover = (event: any) => {
		select<SVGRectElement, Annotation>(event.target)
			.attr("opacity", annotation => Math.min(1, annotation.opacity + 0.15))
			.attr("cursor", "pointer")

		this.config.overlay.onHover(event)
	}

	private onLeave = (event: any) => {
		select<SVGRectElement, Annotation>(event.target)
			.attr("opacity", annotation => annotation.opacity)

		// prevent annotations from leaving the hoverline visible
		this.config.overlay.onMouseLeave()
	}

	private onDrag = (event: D3DragEvent<SVGRectElement, any, any>) => {
		this.config.overlay.onDrag(event)
	}

	private onClick = (event: any) => {
		const geometry = select<SVGRectElement, AnnotationGeometry>(event.target).datum()
		this.reactCallbacks.setSelectedAnnotation(geometry.annotation)
		this.reactCallbacks.createModal(<EditAnnotation />)
	}

	private calculateAnnotationWidth = (geometry: AnnotationGeometry) => {
		return Math.max(this.config.viewScale(geometry.annotation.end_time) - this.config.viewScale(geometry.annotation.start_time), 5)
	}
}
