import React, { useContext, useRef, useState } from "react"
import { SdDetectionConfig, SdDetectionJSON } from "../../../../Types/SdDetection"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { SdDetectionConfigAtom } from "../../../../Atoms/SdDetection"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { D3SdDetection } from "../D3/D3SdDetection"
import { annotationsAtom, selectedAnnotationAtom } from "../../../../Atoms/Annotations"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { SDDetectionWindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/SDDetectionWindowSettings"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { EEGTimeSeriesPageManager } from "../../../../Data/EEGTimeSeriesPageManager"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { useD3LiveRecordingEndDate } from "../../../../Hooks/useLiveRecordingTimes"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"

type SdDetectionProps = {
    windowId: string,
    json: SdDetectionJSON
}

export const SdDetection = (props: SdDetectionProps) => {
    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
				<SDDetectionWindowSettings windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <SdDetectionVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}


const SdDetectionVisualization = (props: SdDetectionProps) => {
    const dimensions = useContext(DimensionsContext)
    const { inProgressAnnotation } = useInProgressAnnotation(props.windowId)

    const domNode = useRef<HTMLDivElement>(null)
	const layoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualizationId = { layoutId: layoutId as string, windowId: props.windowId}
    const [config, setRootConfig] = useRecoilState(SdDetectionConfigAtom(visualizationId))
    const [d3SdDetection, setD3SdDetection] = useState<D3SdDetection>()
    const { fileStartDate, fileEndDate, dataObjectId, patientId } = useRecoilValue(currentPatientFileInfoAtom)
    const { createModal } = useModalProvider()

    const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)

    const annotations = useRecoilValue<Annotation[]>(annotationsAtom)

    const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)

	const [viewStart, viewEnd] = config.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

    const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_TRENDS, fileStartDate, fileEndDate)

    if (d3SdDetection && dimensions.height && dimensions.width && Object.keys(config).length > 0) {
		d3SdDetection.updateConfig({ ...config, fileScale, annotations, inProgressAnnotation, dimensions, timelineController })
	}

    useD3CheckReloadData({
		d3Controller: d3SdDetection,
		clearDataIfChanges: {
			viewDuration,
			eegConfig: {
                LFF: config.eegConfig.LFF,
                HFF: config.eegConfig.HFF,
                notch: config.eegConfig.notch
            },
            overlayEEGConfig: {
                LFF: config.overlayEEGConfig.LFF,
                HFF: config.overlayEEGConfig.HFF,
                notch: config.overlayEEGConfig.notch
            },
			montage: config.montage,
		},
		clearRenderCacheIfChanges: {
			eegSensitivity: config.eegConfig.sensitivityMicroVolts,
			overlaySensitivity: config.overlayEEGConfig.sensitivityMicroVolts,
		},
        fileScale
	})

    useOnMount(() => {
        if (!domNode.current) {
            return
        }

        const reactCallbacks = { createModal, setRootConfig, setSelectedAnnotation, setLinkedWindowsTimelineController }

        const parsedJSON: SdDetectionConfig = {
            ...config,
            ...props.json,
            id: props.windowId,
            viewScale: viewScaleRegistry.get(visualizationId, VisualizationComponent.SD_DETECTION, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration }),
            fileScale,
            dataObjectId,
            patientId,
            dimensions: { height: 0, width: 0 },
            timelineController
        }

        const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.SD_DETECTION, new EEGTimeSeriesPageManager())
        const sdDetection = new D3SdDetection(domNode.current, parsedJSON, pageManager, reactCallbacks)
        
        setD3SdDetection(sdDetection)
        setRootConfig(parsedJSON)

        return () => {
            sdDetection.unmount()
        }
    })

    useD3UpdateVisibleTraces({
        d3Controller: d3SdDetection,
        windowId: props.windowId
    })

	useD3KeyboardShortcuts({
		d3Controller: d3SdDetection,
		windowId: props.windowId,
		shortcuts: [
			hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
		]
	})

    useD3LiveRecordingEndDate({
        d3Controller: d3SdDetection,
        liveModeEnabled: config.liveModeEnabled,
        fileScale
    })

    return <div ref={domNode} style={{height: "100%"}} />
}