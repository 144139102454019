import { useState, useEffect, useCallback } from "react";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { DEFAULTS } from "../../../Constants/Formatting.js";
import { LoginInput } from "../../../Components/Input/Input";
import styled from "styled-components";
import "@fontsource/montserrat";
import { LINKS } from "../../../Constants/BackendLinks.js"
import { useAuthProvider } from "../../../Providers/AuthProvider.js";
import { useEndpointProvider } from "../../../Providers/EndpointProvider.js";
import { FRONTEND_LINKS } from "../../../Constants/FrontendLinks.js";
import loadingSvg from  '../../../Constants/Graphics/smartneuro.svg'
import { postWithDependencyInjection } from "../../../Providers/EndpointProvider.js";
import * as MdIcons from 'react-icons/md';
import { InfoMessage } from "../../../Constants/StyledComponents";
import { useWorkspacesProvider } from "../../../Providers/WorkspacesProvider.js";

const ChangePassword = (props) => {
    const endpointProvider = useEndpointProvider()
    const authProvider = useAuthProvider()
	const workspaceProvider = useWorkspacesProvider()

	const [email, setEmail] = useState(undefined);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
	const [disableChangePasswordButton, setDisableChangePasswordButton] = useState(true)
	const [showEmailInfoMessage, setShowEmailInfoMessage] = useState(false)

	let navigate = useNavigate();

    // TODO: getCurrentUser is called twice?
    // TODO: endpoint should return an error if the pasword is not different from the previous password.
    // TODO: check that token is valid for change a password...

	const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        props.setNavPage(false)

        // if (!authProvider.permissions || !Object.keys(authProvider.permissions)) return
        let token = query.get('token')
        postWithDependencyInjection(token, workspaceProvider.selectedWorkspace, LINKS.ACCOUNT.CHECK_TOKEN).then((data) => {
            authProvider.setToken(token)
            authProvider.setPermissions(data['permissions'])
            setEmail(data['email'])
        }).catch((e) => {
            alert(e)
            navigate(FRONTEND_LINKS.MAIN)
        })
    }, [])

    const changePassword = useCallback((email, confirmPassword) => {
        let body = {
            password: confirmPassword
        }

        return endpointProvider.post(LINKS.ACCOUNT.MODIFY_USER, body)
    }, [endpointProvider])

    function handleSendEmail() {
        setDisableChangePasswordButton(true)
        setShowEmailInfoMessage(true)
    }

    function resetPage() {
        setDisableChangePasswordButton(false)
        setShowEmailInfoMessage(false)
    }

    useEffect(() => {
        if (password !== confirmPassword || !password || !confirmPassword) {
            setDisableChangePasswordButton(true)
        } else {
            setDisableChangePasswordButton(false)
        }
    }, [password, confirmPassword])

    function handleChangePassword(e) {
		const specialChars = /[`!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e) {
			e.preventDefault()
		}

        if (password !== confirmPassword) {
            alert('Passwords do not match.')
            return
        }

        if (password.includes('#')) {
            alert('Password may not contain a hastag.')
            return
        }

		if (!specialChars.test(password)) {
			alert('Password must contain at least one special character.')
			return
		}
		if (!(/[0-9]/.test(password))) {
			alert('Password must contain at least one number.')
			return
		}
		if (!(String(password).length >= 8)) {
			alert('Password must be at least 8 characters long')
			return
		}

        handleSendEmail()
        changePassword(email, confirmPassword).then((text) => {
            alert("Your password was changed. Please check your email for confirmation.")
            authProvider.logout()
            navigate(FRONTEND_LINKS.MAIN)
        }).catch(e => {
			alert(e)
            resetPage()
		})
    }

    function handleBackToLogin() {
		navigate(FRONTEND_LINKS.MAIN);
	}

	return !email ? (
        <div style={{color: 'black', display: "block", textAlign: "center", marginLeft: "-300px", marginTop: `${window.innerHeight / 4}px`}}>
            <img src={loadingSvg} alt="loading" style={{width: "300px"}}/>
            <h2 style={{fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px"}}>Loading...</h2>
        </div>
    ) : (
		<Container1>
            <div id="backButtonDiv" style={{display: "block", padding: "20px", marginTop: "-15px", cursor: "pointer"}} onClick={() => {handleBackToLogin()}}>
				<MdIcons.MdOutlineArrowRightAlt size={32} style={{cursor: "pointer", marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)"}}/>
				<NormalText>Back</NormalText>
			</div>
			<Wrapper>
				<Container2
					top={`${(window.innerHeight - 39) / 3 - 39}px`}
					left={`${(window.innerWidth -303 )/ 2}px`}
				>
					<Form>
						<h1>Change Password</h1>

						<div style={{display: "inline-flex", height: "16px"}}>
							<h2>NEW PASSWORD</h2><p style={{color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px"}}>*</p>
						</div>
						<LoginInput
							type={showPassword ? "text" : "password"}
							placeholder="New Password"
							value={password || ""}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<div style={{display: "inline-flex", height: "16px"}}>
							<h2>CONFIRM NEW PASSWORD</h2><p style={{color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px"}}>*</p>
						</div>
						<LoginInput
							type={showPassword ? "text" : "password"}
							placeholder="Confirm New Password"
							value={confirmPassword || ""}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<div style={{ marginBottom: "5px"}}><input id="visibleConfirmPassword" type="checkbox"
							 onChange={() => {setShowPassword(!showPassword)}} 
							 ></input>
							<label for="visibleConfirmPassword" style={{marginLeft: "4px"}}>Show Password</label>
                        </div>
						<InfoMessage id="passwordRequirementsInfoMessage" style={{height: "auto", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "10px", marginTop: "8px"}}>
							<p><strong>Info!</strong> Password must be at least 8 characters and contain at least one number and one special character.</p> 
						</InfoMessage>

                        <button style={{background: '#ee6c4e', color: '#FFFFFF', pointerEvents: disableChangePasswordButton ? "none" : "all", opacity: disableChangePasswordButton ? "0.3" : "1", cursor: disableChangePasswordButton ? "default" : "pointer"}} onClick={(e) => {handleChangePassword(e)}}>Change Password</button>
                        <InfoMessage id="sendingEmailInfoMessage" style={{ width: "874px", display: showEmailInfoMessage ? "block" : "none", marginLeft: "auto", marginRight: "auto" , marginTop: "8px"}}><p><strong>Info!</strong> Email is sending...</p></InfoMessage>
					</Form>
				</Container2>
			</Wrapper>
		</Container1>
	);
};

const Container1 = styled.div`
	background: #eefcff;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

const Wrapper = styled.div`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: flex;
`;

const Form = styled.form`
	display: flex;
	width: 100%;
	width: 304px;
	height: 500px;
	top: 284px;
	flex-direction: column;
	overflow: hidden;
	h1 {
		width: 302px;
		margin-bottom: 15px;
		margin-left: 3.5px;
		color: #293241;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
	}
	h2 {
		font-family: "Montserrat";
		font-weight: 550;

		// width: 304px;
		width: fit-content;
		font-size: 14px;
		color: #293241;
		padding: 0;
		margin: 0;

		margin-left: 6px;
	}
	button {
		width: 100%;
		max-width: 350px;
		min-width: 250px;
		height: 40px;
		border: none;
		margin: 1rem 0;
		margin-top: 0;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		// background-color: #ee6c4e;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
`;

const Container2 = styled.div`
	width: 100%;
	position: absolute;
	width: 284px;
	height: 39px;
	left: ${({left}) => left};
	top: ${({top}) => top};
	padding: ${DEFAULTS.CELL_PADDING};
	align-items: center;
	align-content: center;
`;

const NormalText = styled.text`
    position: absolute;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400px;
    font-size: 16px;
    color: #293241;
    line-height: 150%;
`;

export default ChangePassword;

