import { atom } from "recoil";
import { VisualizationComponent } from "../VisualizationComponentFactory";
import { DEFAULT_EEG_JSON } from "./EEGMontage";
import { DEFAULT_TIME_SERIES_GROUP_JSON } from "./ModalityGraphGroup";
import { dispatch } from "d3";
import { LinkedWindowUpdateEvent } from "../Types/LinkedWindowInfo";
import { DEFAULT_SD_DETECTION_JSON } from "./SdDetection";
import { DEFAULT_HISTOGRAM_JSON } from "../Types/Histogram";
import { DEFAULT_CPPOPT_PLOT_JSON } from "../Types/CPPOptPlot";

export const currentlyActiveVisualizationAreaAtom = atom<string>({
    key: "currentlyActiveVisualizationArea",
    default: "",
})

export function getDefaultVisualizationProps(componentId: string): object {
    switch(componentId) {
        case VisualizationComponent.EEG_MONTAGE:
            return DEFAULT_EEG_JSON
        case VisualizationComponent.TIME_SERIES_GROUP:
            return DEFAULT_TIME_SERIES_GROUP_JSON
        case VisualizationComponent.SD_DETECTION:
            return DEFAULT_SD_DETECTION_JSON
        case VisualizationComponent.HISTOGRAM:
            return DEFAULT_HISTOGRAM_JSON
        case VisualizationComponent.CPPOPT_PLOT:
            return DEFAULT_CPPOPT_PLOT_JSON
        default:
            return {}
    }
}

export const linkedWindowsDispatch = dispatch<LinkedWindowUpdateEvent>("update")
export const allWindowsDispatch = dispatch<LinkedWindowUpdateEvent>("jump")

export const jumpAllWindows = (startDate: Date) => {
    const event: LinkedWindowUpdateEvent = { startDate, controller: "global", options: { autoScale: true } }
    allWindowsDispatch.call("jump", undefined, event)
}
