import React, { useContext, useRef, useState } from "react"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { useRecoilState, useRecoilValue } from "recoil"
import { histogramConfigAtom } from "../../../../Atoms/Histogram"
import { useOnMount } from "../../../../../../../../Hooks/useOnMount"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider"
import { D3Histogram, HistogramConfig } from "../D3/D3Histogram"
import { HistogramJSON } from "../../../../Types/Histogram"
import { annotationsAtom } from "../../../../Atoms/Annotations"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { HistogramWindowSettings } from "./HistogramWindowSettings"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { linkedWindowsTimelineControllerAtom } from "../../../../Atoms/Timeline"
import { VisualizationComponent } from "../../../../VisualizationComponentFactory"
import { useD3LiveRecordingEndDate } from "../../../../Hooks/useLiveRecordingTimes"
import { ParsedJSONOptions } from "../../../../Types/ParsedJSON"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"

type HistogramProps = {
	windowId: string
	json: HistogramJSON
}

export const Histogram = (props: HistogramProps) => {
	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ flex: 0 }}>
				<HistogramWindowSettings windowId={props.windowId} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<HistogramVisualization {...props} />
				</DimensionsProvider>
			</div>
		</div>
	)
}

export const HistogramVisualization = (props: HistogramProps) => {
	const domNode = useRef<HTMLDivElement>(null)
	const dimensions = useContext(DimensionsContext)
	const { fileStartDate, fileEndDate, dataObjectId, patientId, timeZone } = useRecoilValue(currentPatientFileInfoAtom)
	const { createModal } = useModalProvider()

	const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }
	const [config, setRootConfig] = useRecoilState(histogramConfigAtom(visualizationId))
	const [d3Histogram, setD3Histogram] = useState<D3Histogram>()
	const [timelineController, setLinkedWindowsTimelineController] = useRecoilState(linkedWindowsTimelineControllerAtom)

	const annotations = useRecoilValue(annotationsAtom)
	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, { viewDuration: props.json.viewDuration ?? 60 * 1000, fileStartDate, fileEndDate })
    const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	const fileScale = fileScaleRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, fileStartDate, fileEndDate)

	const getParsedJSON = (options?: ParsedJSONOptions): HistogramConfig => {
		const base = { ...config }

		if (options?.initialize) {
			Object.assign(base, props.json)
		}

        return {
			...base,
			id: props.windowId,
			viewScale,
			fileScale,
			dataObjectId,
			patientId,
			dimensions,
            annotations,
			timelineController,
			timeZone,
		}
    }

	if (d3Histogram && dimensions.height) {
		d3Histogram.updateConfig(getParsedJSON())
	}

	useOnMount(() => {
		if (!domNode.current) {
			return
		}

		const reactCallbacks = { createModal, setRootConfig, setLinkedWindowsTimelineController }
		const parsedJSON = getParsedJSON({ initialize: true })
		const pageManager = pageManagerRegistry.get(visualizationId, VisualizationComponent.HISTOGRAM, new TimeSeriesPageManager())
		const histogram = new D3Histogram(domNode.current, parsedJSON, pageManager, reactCallbacks)
		
		setD3Histogram(histogram)
		setRootConfig(parsedJSON)

		return () => {
			histogram.unmount()
		}
	})

	useD3KeyboardShortcuts({
		d3Controller: d3Histogram,
		windowId: props.windowId,
		shortcuts: [hotkeyActions.NEXT_PAGE, hotkeyActions.PREVIOUS_PAGE, hotkeyActions.PLAY_PAUSE],
	})

    useD3CheckReloadData({
        d3Controller: d3Histogram,
        clearDataIfChanges: {
            viewDuration,
            modalities: [...new Set(config.modalityConfigs)].sort()
        },
		fileScale
    })

	useD3LiveRecordingEndDate({ 
		d3Controller: d3Histogram,
		liveModeEnabled: config.liveModeEnabled,
		fileScale
	})

	return <div ref={domNode} style={{ height: "100%" }}></div>
}
