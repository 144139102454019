import { UploadFilesModal } from "../../../../Constants/StyledComponents"
import FormConstructor from "../../../../Components/Form/FormConstructor"
import Accordion from "../../../../Components/Accordion/Accordion"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { LINKS } from '../../../../Constants/BackendLinks';
import { useLocation, useNavigate } from "react-router-dom";
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks';
import { Scrollbar } from "../../../../Constants/StyledComponents"


const FormViewModal = ({formResponseJson}) => {
    const locationURL  = useLocation();
    const query = new URLSearchParams(locationURL.search);
    const UID = query.get("patient_id")

    const [patientInfo, setPatientInfo] = useState(0)
    const getPatient = async (patientID) => {
        let body = { patient_primary_key: patientID }
        return endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
    }
    const endpointProvider = useEndpointProvider();
    const navigate = useNavigate();

    useEffect(() => {
        const validateURLParams = async () => {
            try {
                let patient = await getPatient(UID);
                setPatientInfo(patient);
            } catch(error) {
                let errorMessage = UID ? `Patient id=${UID} does not exist.` : 'No patient has been selected.';
                alert(errorMessage);
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }
        }
        validateURLParams();
	}, [UID]);

    let admissionQuestions = {
        Question1: {
            groupLabel: "Admission",
            prompt: "Admission Date",
            type: "datepicker",
            required: false, 
            default: null,
            questionType :"admissionDate",
            identifier: {
                NINDS: "C0019062"
            }
        }
    }

    let dischargeQuestions = {
        Question1: {
            groupLabel: "Discharge",
            prompt: "Discharge Date",
            type: "datepicker",
            required: false, 
            default: null,
            questionType :"dischargeDate",
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question2: {
            groupLabel: "Discharge",
            prompt: "Discharge disposition",
            type : "text",
            required: true,
            default: "",
            questionType: "dischargeDisposition",
            identifier: {
                NINDS: "C0019062"
            }
        }
    }

    let GCSQuestions = {
        Question1: {
            groupLabel: "GCS on admission",
            prompt: "GCS - Verbal Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "Unknown"],
            questionType :"GCSVerbalResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question2: {
            groupLabel: "GCS on admission",
            prompt: "GCS - Eye Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "Unknown"],
            questionType :"GCSEyeResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question3: {
            groupLabel: "GCS on admission",
            prompt: "GCS - Motor Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "6", "Unknown"],
            questionType :"GCSMotorResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
    }

    let bestGCSQuestions = {
        Question1: {
            groupLabel: "Best GCS during admission",
            prompt: "GCS - Verbal Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "Unknown"],
            questionType :"bestGCSVerbalResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question2: {
            groupLabel: "Best GCS during admission",
            prompt: "GCS - Eye Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "Unknown"],
            questionType :"bestGCSEyeResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question3: {
            groupLabel: "Best GCS during admission",
            prompt: "GCS - Motor Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "6", "Unknown"],
            questionType :"bestGCSMotorResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
    }

    let worstGCSQuestions = {
        Question1: {
            groupLabel: "Worst GCS during admission",
            prompt: "GCS - Verbal Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "Unknown"],
            questionType :"worstGCSVerbalResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question2: {
            groupLabel: "Worst GCS during admission",
            prompt: "GCS - Eye Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "Unknown"],
            questionType :"worstGCSEyeResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
        Question3: {
            groupLabel: "Worst GCS during admission",
            prompt: "GCS - Motor Response",
            type: "checkbox",
            required: false,
            default: "",
            options: [ "1", "2", "3", "4", "5", "6", "Unknown"],
            questionType :"worstGCSMotorResponse",
            multiple: false,
            identifier: {
                NINDS: "C0019062"
            }
        },
    }

    let formJson = {
        formName : "Curing Coma - Patient Information",
        Questions : {
            admissionQuestions,
            GCSQuestions,
            bestGCSQuestions,
            worstGCSQuestions,
            dischargeQuestions,
        }
    }

    const generateValues = (formResponseJson) => {
        const defaultValues = {};
      
        for (const key in formResponseJson) {
              defaultValues[key] = formResponseJson[key].value;
        }
        return defaultValues;
      };

	const { control } = useForm({
        values: generateValues(formResponseJson),
	})

    const accordionData = {
    		'Admission' : {
    			'label' : "Admission",
    			'innerContent': <FormConstructor control={control} questions={admissionQuestions} disabledAll={false} defaultValues={formResponseJson} changeDisabledTheme={true}/>,
    		},
    		'GCS on admission' : {
    			'label' : "GCS on admission",
    			'innerContent' : <FormConstructor control={control} questions={GCSQuestions} disabledAll={true} defaultValues={formResponseJson} changeDisabledTheme={true}/>
    		},
            'Best GCS during admission' : {
    			'label' : "Best GCS during admission",
    			'innerContent' : <FormConstructor control={control} questions={bestGCSQuestions} disabledAll={true} defaultValues={formResponseJson} changeDisabledTheme={true}/>
    		},
            'Worst GCS during admission' : {
    			'label' : "Worst GCS during admission",
    			'innerContent' : <FormConstructor control={control} questions={worstGCSQuestions} disabledAll={true} defaultValues={formResponseJson} changeDisabledTheme={true}/>
    		},
    		'Discharge' : {
    			'label' : 'Discharge',
    			'innerContent' : <FormConstructor control={control} questions={dischargeQuestions} disabledAll={true} defaultValues={formResponseJson} changeDisabledTheme={true}/>
    		}
    	}

    let uploadHeaderText = ` Patient ${patientInfo?.patient_id} at ${patientInfo?.site_name}`
    
    let handleOverflowUploadHeaderText = uploadHeaderText.length > 75 ? `Patient ${patientInfo?.patient_id.length > 15 ? patientInfo?.patient_id.slice(0, 15) + "..." : patientInfo?.patient_id} at ${patientInfo?.site?.name.length > 40 ? patientInfo?.site_name.slice(0, 40) + "..." : patientInfo?.site_name}` : uploadHeaderText

    return(
        <div>
            <UploadFilesModal>
                <ModalHeader headerText={handleOverflowUploadHeaderText}/>
                <Scrollbar style={{ paddingTop: "50px", paddingLeft: "50px", paddingRight: "50px", paddingBottom: "50px", height: "700px", maxHeight: "700px", overflowY: "auto", overflowX:"hidden"}}>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ marginBottom: "10px", color: "#207DEA"}}>Demographics</h1>
                    </div>

                    <Accordion data={accordionData}/>
				</Scrollbar>
            </UploadFilesModal>
        </div>
    )
}

export default FormViewModal;