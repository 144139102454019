const isCloud = window._env_.REACT_APP_DEPLOYMENT === `CLOUD`
export const isDevelopment = window._env_.REACT_APP_PARENT_STUDY_ID === `DEV`
export const isDemo = window._env_.REACT_APP_PARENT_STUDY_ID === `DEMO`
const isLocal = window._env_.REACT_APP_PARENT_STUDY_ID === `LOCAL`
const isCHOP = window._env_.REACT_APP_PARENT_STUDY_ID === `CHOP`

// Set isCloud to true if you want to access services on the DEMO deployment on IBM
// let isCloud = true

let PORTS = {
	QUERY: isCloud ? "query" : 5001,
	QUERY_PREFETCH: isCloud ? "query-prefetch" : 5002,
	IAM: isCloud ? "iam" : 5010,
	MAPAI: isCloud ? "mapai" : 5030,
	MESS: isCloud ? "mess" : 5090,
	UPLOAD: isCloud ? "upload" : 5080,
	FILE_MANAGER: 5011,
}

export let LINKS = {}
let protocol = "http"
let subdomain = "localhost"

if (isCloud) {
	protocol = "https"
	subdomain = function (module) {
		return `${window._env_.REACT_APP_PARENT_STUDY_ID.toLocaleLowerCase()}.${module}.bedsideinfo.org`
	}
}

let SUBDOMAINS = {
	QUERY: isCloud ? subdomain("QUERY") : `${subdomain}:${PORTS.QUERY}`,
	QUERY_PREFETCH: isCloud ? subdomain("QUERY-PREFETCH") : `${subdomain}:${PORTS.QUERY_PREFETCH}`,
	IAM: isCloud ? "iam.bedsideinfo.org" : `${subdomain}:${PORTS.IAM}`,
	MAPAI: isCloud ? subdomain("MAPAI") : `${subdomain}:${PORTS.MAPAI}`,
	MESS: isCloud ? "mess.bedsideinfo.org" : `${subdomain}:${PORTS.MESS}`,
	UPLOAD: isCloud ? subdomain("UPLOAD") : `${subdomain}:${PORTS.UPLOAD}`,
	FILE_MANAGER: `${subdomain}:${PORTS.FILE_MANAGER}`,
}

if (window._env_.REACT_APP_PARENT_STUDY_ID.toLocaleLowerCase() === 'eboost' && isCloud) {
    SUBDOMAINS.QUERY_PREFETCH = 'eboost.queryprefetch.bedsideinfo.org'
}

if (window._env_.REACT_APP_PARENT_STUDY_ID.toLocaleLowerCase() === 'eboost' && isCloud) {
    SUBDOMAINS.QUERY_PREFETCH = 'eboost.queryprefetch.bedsideinfo.org'
}

let isIPAdress = hostName => {
	if (
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			hostName
		)
	) {
		return true
	}
	return false
}
// When the hostName is an ip address, it means the client is a selenium container inside dev-server
if (isIPAdress(window.location.hostname)) {
	SUBDOMAINS = {
		// for the selenium container, the host for different services are different
		QUERY: "query:5001",
		QUERY_PREFETCH: "query-prefetch:5002",
		IAM: "iam:5010",
		MAPAI: "mapai:5030",
		MESS: "mess:5090",
		UPLOAD: "upload:5080",
		FILE_MANAGER: "file-manager:5011",
	}
}

if (isCloud) {
	if (isDevelopment) {
		SUBDOMAINS.IAM = "dev.iam.bedsideinfo.org"
		SUBDOMAINS.MESS = "dev.mess.bedsideinfo.org"
	} else if (isDemo) {
		SUBDOMAINS.IAM = "demo.iam.bedsideinfo.org"
		SUBDOMAINS.MESS = "demo.mess.bedsideinfo.org"
	} else if (isLocal) {
		SUBDOMAINS.IAM = "local.iam.bedsideinfo.org"
		SUBDOMAINS.MESS = "local.mess.bedsideinfo.org"
	} else if (isCHOP) {
		SUBDOMAINS.IAM = "chop.iam.bedsideinfo.org"
		SUBDOMAINS.MESS = "chop.mess.bedsideinfo.org"
	}
}

LINKS = {
    QUERY: `${protocol}://${SUBDOMAINS.QUERY}`,
    QUERY_PREFETCH: `${protocol}://${SUBDOMAINS.QUERY_PREFETCH}`,
    LOGIN: {
        GET_TOKEN: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_token`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: false
        },
        RESET_PASSWORD: {
            LINK: `${protocol}://${SUBDOMAINS.MESS}/account/reset_password`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: false
        },
        CREATE_USER: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/create_user`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
        DELETE_USER: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/delete_user`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
        CHANGE_PASSWORD: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/change_password`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
        CHECK_ENV: {
            LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/env/check_env`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        }
    },
    DATA: {
        TRIALS: {
            GET_STUDIES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/get_studies`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MODALITIES_UNION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_modalities_union`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MODALITIES_UNION_V2: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_modalities_union`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MEDICATION_UNION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_medication_union`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MODALITIES_INTERSECTION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_modalities_intersection`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MEDICATION_INTERSECTION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_medication_intersection`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            }
        }, 
        PROFILING: {
            GET_PATIENT_FILE_TYPE:{
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_type`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_FILE_START_END_TIMESTAMPS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_start_end_timestamps`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_FILE_DURATION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_duration`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_ANNOTATION_GROUPS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/get_annotation_groups`, 
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DOWNLOAD_HDF5_FILE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/hdf5/download_hdf5_file`, 
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            CHECK_HDF5_FILE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/hdf5/check_hdf5_file`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENTS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_patients`, 
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENTS_VISUALIZE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patients_visualize`, 
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            LOAD_EMAIL_FROM_TOKEN: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/user/load_email_from_token`,  
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_GROUPS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/group/get_patient_groups`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_PATIENT_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/group/create_patient_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_PATIENT_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/group/delete_patient_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            ADD_PATIENT_TO_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/group/add_patient_to_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            REMOVE_PATIENT_FROM_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/group/remove_patient_from_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            CREATE_ANNOTATION_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/create_annotation_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            RENAME_ANNOTATION_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/rename_annotation_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_ANNOTATION_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/annotation/delete_annotation_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_ANNOTATION_GROUPS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/delete_annotation_groups`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            CLONE_ANNOTATION_GROUP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/annotation/clone_annotation_group`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            CLONE_ANNOTATION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/annotation/clone_annotation`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            EXPORT_ANNOTATIONS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/annotation/export_annotations`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            GET_ANNOTATIONS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/get_annotations`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_ANNOTATIONS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/delete_annotations`, 
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            ADD_ANNOTATION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/add_annotation`, 
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            MODIFY_ANNOTATION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/annotation/modify_annotation`, 
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_LAYOUTS: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/layout/get_layouts`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_LAYOUT: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/layout/create_layout`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_LAYOUT: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/layout/delete_layout`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_LAYOUT: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/layout/modify_layout`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            RENAME_LAYOUT: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/layout/modify_layout`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_patient`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            PERSYST: {
                TREND_PANELS: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/persyst/persyst_api_panels`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                EEG_MONTAGES: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/persyst/all_montage_definitions`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                }
            },
            GET_PATIENTS_FORM_DIRECTORY : {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/form/get_patient_forms`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENTS_MODAL: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/form/get_patient_modal`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            }
        }, 
        UPLOAD: {
			GET_PROJECT_USER_SITES: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/get_project_user_sites`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
            GET_USER_PROJECTS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/get_user_projects`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
			GET_PROJECT_PATIENTS_BY_SITE: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_project_patients_by_site`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
            EXPORT_UPLOADS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/export_uploads`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            START_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/start_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            FINISH_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/finish_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/upload/create_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_FILE_MULTIPART_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/create_file_multipart_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/upload/modify_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CANCEL_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/cancel_upload`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_CANCELED_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/delete_canceled_upload`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
			UPLOAD_FORM: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/upload_form`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            UPDATE_MD5_FORM: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/update_md5_form`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_UPLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/delete_upload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_DIRECTORY_STRUCTURE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_directory_structure`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_PATIENT_DIRS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/create_patient_dirs`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_EXISTING_PATIENTS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_existing_patients`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_EXISTING_DATA_FOR_DATATYPE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_existing_data_for_datatype`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_FILE_MODALITY_TIMES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_modality_times`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
			GET_MODALITY_TIME_BY_PATIENT: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_modality_time_by_patient`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
            GET_PATIENT_FILE_MODALITY_PERCENTAGES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_modality_percentages`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_FILE_MODALITY_START_END_TIMES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_modality_start_end_times`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_FILE_TIMES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_file_times`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_MODALITIES_UNION: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_modalities_union`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_PATIENT: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/modify_patient`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_DIR_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/get_patient_dir_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            COPY_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/copy_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/delete_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MOVE_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/move_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_TEMP_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/delete_temp_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            COPY_TEMP_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/copy_temp_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MOVE_TEMP_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/patient/move_temp_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CHECK_STUDIES_HAVE_BUCKET: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/check_studies_have_bucket`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_USER_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/create_user_mapping`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            SET_USER_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/set_user_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_USER_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/delete_user_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_USER_MAPPINGS: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/get_user_mappings`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_USER_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/modify_user_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            CREATE_STUDY_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/create_study__mapping`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            SET_STUDY_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/set_study_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_STUDY_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/delete_study_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_STUDY_MAPPINGS: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/get_study_mappings`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            MODIFY_STUDY_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/mapping/modify_study_mapping`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            CHECK_PATIENT_ID: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/check_patient_id`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CHECK_PATIENT_IDENTIFIER: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/check_patient_identifier`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_UPLOAD_MESSAGES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/get_upload_messages`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_UPLOAD_PROGRESS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/get_upload_progress`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PRESIGNED_URLS: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/generate_presigned_urls`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            PUSH_TO_CLOUD: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/push_to_cloud`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PATIENT_UPLOADS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/upload/get_patient_uploads`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CHECK_MD5_HASH: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/check_md5_hash`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CHECK_CANCEL_STATUS: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/v1/upload/check_cancel_status`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            UPLOAD_MAPPING: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/upload_mapping`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_LOG_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/get_log_files`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            EXPORT_LOG: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/download_log_file`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DOWNLOAD_WEBINAR_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/download_webinar_files`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            CHECK_CNS_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/check_cns_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            UPLOAD_PATIENT_INFO: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/upload_patient_info`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            UPDATE_MD5_IV: {
                LINK: `${protocol}://${SUBDOMAINS.UPLOAD}/upload/update_md5_iv`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            UPLOAD_FAILED_NOTIFICATION: {
                LINK: `${protocol}://${SUBDOMAINS.MESS}/upload/upload_failed_notification`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_FILE_CONTENT: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_file_content`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DOWNLOAD_FILE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/download_file`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            GET_CSV_COLUMNS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/patient/get_csv_columns`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
        }, 
    },
    PIPELINES: {
        CREATE: {
            SAVE_PIPELINE: {
                LINK: ``,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_ANALYSES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/get_analyses`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_ANALYSIS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/create_analysis`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_ANALYSIS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/delete_analysis`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_ANALYSIS_STEPS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/get_analysis_steps`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_ANALYSIS_STEP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/create_analysis_step`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            DELETE_ANALYSIS_STEP: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/delete_analysis_step`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_METRICS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/get_metrics`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            CREATE_METRICS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/analysis/create_metrics`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
        },
        SHARE: {
            DOWNLOAD_PIPELINE: {
                LINK: ``,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            }
        },
        EXECUTE: { // For running a pipeline and getting the results back as JSON
            LINK: `${protocol}://${SUBDOMAINS.MAPAI}/map-ai/execute`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        },
        EXECUTE_AND_WRITE_H5: { // For running a pipeline and writing the results to an h5 for later retrieval, necessary for time-series data.
            LINK: `${protocol}://${SUBDOMAINS.MAPAI}/map-ai/execute-and-write-h5`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
    }, 
    REPORTING: {
        MULTIMODAL_REPORTS: {
            REPORT_TEMPLATES: {
                GET_REPORT_TEMPLATES: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/get_report_templates`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                EXPORT_REPORT_TEMPLATES: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/export_report_templatess`,
                    OUTPUT_TYPE: 'file',
                    REQUIRES_TOKEN: true
                },
                DELETE_REPORT_TEMPLATE: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/delete_report_template`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                CREATE_REPORT_TEMPLATE: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/create_report_template`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_REPORT_TEMPLATE: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/modify_report_templates`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                GET_REPORT_TEMPLATE_JSON: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/get_report_template_json`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                ADD_COMPONENT_TO_REPORT_TEMPLATE: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/add_component_to_report_template`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                GET_REPORTS: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/get_reports`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                EXPORT_REPORTS: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/export_reports`,
                    OUTPUT_TYPE: 'file',
                    REQUIRES_TOKEN: true
                },
            },
            COMPONENTS: {
                GET_COMPONENTS: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/get_components`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_COMPONENTS_BY_REPORT_TEMPLATE_ID: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/get_components_by_report_template_id`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                CREATE_COMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/create_component`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_COMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/modify_component`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_COMPONENTS: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/modify_components`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                DELETE_COMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/component/delete_component`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                }
            },
            SUBCOMPONENTS: {
                CREATE_SUBCOMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/subcomponent/create_subcomponent`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_SUBCOMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/subcomponent/modify_subcomponent`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                DELETE_SUBCOMPONENT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/subcomponent/delete_subcomponent`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_REPORT: {
                    LINK: `${protocol}://${SUBDOMAINS.QUERY}/report/modify_report`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                }
            }
        },
        QUALITY_REPORTS: {

        }
    }, 
    ADMIN: {    
        USER_MANAGEMENT: {
            USERS: {
                GET_USERS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/get_users`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_USERS_BY_PROJECT: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/get_users_by_project`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_USERS_BY_SITE: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/get_users_by_site`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                DELETE_USERS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/delete_users`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                GET_USERS_FILTER: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_users_filter`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                EXPORT_USERS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/export_users`,
                    OUTPUT_TYPE: 'file',
                    REQUIRES_TOKEN: true
                },
                INVITE_USER: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/study/invite_user`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
				INVITE_USERS: {
					LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/invite_users`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
				MODIFY_USER: {
					LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/modify_user`,
					OUTPUT_TYPE: "text",
					REQUIRES_TOKEN: true,
				},
                REMOVE_USER: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/study/remove_user_study`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                REMOVE_USERS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/study/remove_users_from_study`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                SET_USER_SITES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/set_user_sites`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                SET_USER_STUDIES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/set_user_studies`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                SET_USER_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/set_user_roles`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                GET_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/get_roles`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ROLES_BY_USER: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/get_roles_by_user`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_USER_BY_ID: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_user_by_id`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_USER_BY_EMAIL: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_user_by_email`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ACTION_BY_EMAIL: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_action_by_email`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ALL_ACTIONS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/user/get_all_users_actions`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ASSIGNED_USER_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_assigned_user_roles`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ASSIGNED_USER_SITES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_assigned_user_sites`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ASSIGNED_USER_PROJECTS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_assigned_user_projects`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_TOKENS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_tokens`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_DOMAINS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/domain/get_domains`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                ADD_DOMAIN: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/domain/add_domain`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                REMOVE_DOMAINS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/domain/remove_domains`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                GET_RESTRICT_EMAIL_DOMAINS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/study/get_restrict_email_domains`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                SET_RESTRICT_EMAIL_DOMAINS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/workspace/set_restrict_email_domains`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_DOMAIN_RESTRICTION: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/workspace/get_domain_restriction`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
            },
            ROLES_AND_PERMISSIONS: {
                GET_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/get_roles`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_PERMISSIONS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/get_permissions`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                GET_ROLE_PERMISSIONS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/get_role_permissions`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                CREATE_ROLE: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/create_role`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                DELETE_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/delete_roles`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                MODIFY_ROLE: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/role/modify_role`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                CLONE_ROLE: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/clone_role`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                },
                SET_ROLE_PERMISSIONS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/set_role_permissions`,
                    OUTPUT_TYPE: 'text',
                    REQUIRES_TOKEN: true
                },
                EXPORT_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/export_roles`,
                    OUTPUT_TYPE: 'file',
                    REQUIRES_TOKEN: true
                },
                EXPORT_PERMISSIONS: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/user/export_permissions`,
                    OUTPUT_TYPE: 'file',
                    REQUIRES_TOKEN: true
                },
                GET_USER_ROLES: {
                    LINK: `${protocol}://${SUBDOMAINS.IAM}/role/get_user_roles`,
                    OUTPUT_TYPE: 'json',
                    REQUIRES_TOKEN: true
                }   
            }
        },
        STUDIES: {
            GET_STUDIES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/get_studies`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            EXPORT_STUDIES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/export_studies`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            CREATE_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/create_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/delete_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_STUDIES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/delete_studies`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            MODIFY_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/modify_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            ADD_USER: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/study/add_user_site`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            INVITE_USER: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/study/invite_user`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            REMOVE_USER: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/study/remove_user_site`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            ADD_ANCILLARY_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/add_ancillary_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            REMOVE_ANCILLARY_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/study/remove_ancillary_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            GET_LOGIN_ATTEMPTS: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/workspace/get_login_attempts`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_USER_LOG_RETENSION: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/study/get_user_log_retension`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            SET_USER_LOG_RETENSION: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/study/set_user_log_retension`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
        },
        WORKSPACES: {
            GET_WORKSPACES_BY_USER: {
                LINK: `${protocol}://${SUBDOMAINS.IAM}/v1/workspace/get_workspaces_by_user`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            }
        },
		PROJECTS: {
			GET_PROJECTS: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/get_projects`,
				OUTPUT_TYPE: "json",
				REQUIRES_TOKEN: true,
			},
            GET_PROJECTS_BY_USER: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/get_projects_by_user`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
			CREATE_PROJECT: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/create_project`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
                ERROR_RESPONSE_TYPE: "json"
			},
			DELETE_PROJECTS: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/delete_projects`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
			MODIFY_PROJECT: {
				LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/study/modify_project`,
				OUTPUT_TYPE: "text",
				REQUIRES_TOKEN: true,
			},
		},
        UPLOADS: {
            GET_UPLOADS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/get_uploads`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            EXPORT_UPLOADS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/upload/export_uploads`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            }
        },
        PREFETCH: {
            GET_PREFETCH_INVENTORY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/prefetch/get_prefetch_inventory`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_PREFETCH_QUEUE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/prefetch/get_prefetch_queue`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            UNLOAD: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/prefetch/unload`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            }
        },
        SITES: {
            GET_SITES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/get_workspace_sites`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_SITES_BY_USER: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/get_workspace_sites_by_user`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            EXPORT_SITES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/site/export_sites`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            CREATE_SITE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/create_workspace_site`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true,
                ERROR_RESPONSE_TYPE: "json"
            },
            DELETE_SITE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/site/delete_site`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_SITES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/delete_sites`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            MODIFY_SITE: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/site/modify_workspace_site`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
        },
        BUCKETS: {
            GET_BUCKETS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/get_buckets`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_BUCKETS_FILTERS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/get_buckets_filters`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            GET_BUCKET: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/bucket/get_bucket`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true
            },
            EXPORT_BUCKETS: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/export_buckets`,
                OUTPUT_TYPE: 'file',
                REQUIRES_TOKEN: true
            },
            CREATE_BUCKET: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/create_bucket`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            DELETE_BUCKET: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/delete_bucket`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            MODIFY_BUCKET: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/modify_bucket`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            ADD_BUCKET_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/add_bucket_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            },
            REMOVE_BUCKET_STUDY: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/bucket/remove_bucket_study`,
                OUTPUT_TYPE: 'text',
                REQUIRES_TOKEN: true
            }
        },
    },
    ACCOUNT: {
        CHANGE_PASSWORD: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/change_password`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
        CHNAGE_EMAIL: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/change_email`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        },
        CHECK_TOKEN: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/check_token`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        },
        GET_CURRENT_USER: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/get_current_user`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        },
        UPDATE_TOKEN_EXPIRE_TIME: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/update_token_expire_time`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        },
        MODIFY_USER: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/user/modify_user`,
            OUTPUT_TYPE: 'text',
            REQUIRES_TOKEN: true
        }, 
        GET_KEYBOARD_SHORTCUTS: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/keyboard_shortcut/get_keyboard_shortcuts`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        }, 
        UPDATE_KEYBOARD_SHORTCUTS: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/keyboard_shortcut/update_keyboard_shortcuts`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        }, 
        RESET_KEYBOARD_SHORTCUTS: {
            LINK: `${protocol}://${SUBDOMAINS.IAM}/keyboard_shortcut/reset_keyboard_shortcuts`,
            OUTPUT_TYPE: 'json',
            REQUIRES_TOKEN: true
        }, 
    },
    FILE_MANAGER: {
        GET_FILES: {
            LINK: `${protocol}://${SUBDOMAINS.FILE_MANAGER}/file/get_files`,
            OUTPUT_TYPE: 'text',
            EQUIRES_TOKEN: true
        }
    },
    GUIDE: {
        GET_TABLE_NAMES: {
            LINK: `${protocol}://${SUBDOMAINS.QUERY}/knowledge_base/get_table_names`,
            OUTPUT_TYPE: 'json',
            EQUIRES_TOKEN: true
        },
        GET_TABLE_METADATA: {
            LINK: `${protocol}://${SUBDOMAINS.QUERY}/knowledge_base/get_table_metadata`,
            OUTPUT_TYPE: 'json',
            EQUIRES_TOKEN: true
        },
        VIEW_TABLE_DATA: {
            LINK: `${protocol}://${SUBDOMAINS.QUERY}/knowledge_base/view_table_data`,
            OUTPUT_TYPE: 'json',
            EQUIRES_TOKEN: true
        },
        VIEW_RECORD_METADATA: {
            LINK: `${protocol}://${SUBDOMAINS.QUERY}/knowledge_base/view_record_metadata`,
            OUTPUT_TYPE: 'json',
            EQUIRES_TOKEN: true
        },
        GET_ATTRIBUTE: {
            LINK: `${protocol}://${SUBDOMAINS.QUERY}/knowledge_base/get_attribute`,
            OUTPUT_TYPE: 'json',
            EQUIRES_TOKEN: true
        }
    },
    RESOURCES: {
        WEBINARS: {
            GET_WEBINAR_FILES: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/resources/webinars/get_webinar_files`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true  
            },
            GET_WEBINAR: {
                LINK: `${protocol}://${SUBDOMAINS.QUERY}/v1/resources/webinars/get_webinar`,
                OUTPUT_TYPE: 'json',
                REQUIRES_TOKEN: true  
            }
        },
    }
}

